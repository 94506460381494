body.placedbooking,
	{
	section {
		@include center-vertical;

		p {
			text-align: center;
		}
	}

	svg#svg_skip {
		width: 420px;
		height: 200px;
		margin: 0 auto;
		display: block;
		overflow: visible;
		position: relative;
		z-index: 1;

		@include maxXS {
			width: 80%;
			height: auto;
		}

		.skip,
		.logo,
		.shadow {
			opacity: 0;
		}
	}
}