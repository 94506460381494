.alert {
	@include border-radius;
	@include fadeInUp ( $duration: 1s );

	background-color: #ccc;
	border: 1px solid #aaa;
	font-weight: $font-weight-medium;
	list-style-type: none;
	margin: 1rem auto;
	max-width: 500px;
	padding: .5rem .8rem .6rem .8rem;
	
	> li:last-child {
		margin-bottom: 0;
	}

	> li:before {
		margin-right: 0.5rem;
		content: "\2731";
	}
}

#errors {
	border-color: $colour-red;
	color: #fff;
	background-color: $colour-red;
	
	> li:before {
		content: "\f071";
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
	}
}

#success {
	background-color: $colour-success;
	border-color: $colour-success;
	color: #fff;
	padding: 1rem 1rem 1rem 2.5rem;
	
	> li:before {
		background-image: url(../img/ui-tick.svg);
		background-repeat: no-repeat;
		content: '';
		display: block;
		height: 40px;
		position: absolute;
		width: 30px;
	    background-position: -40px -66px;
	    background-size: 100px 100px;
	    left: 12px;
	    top: 11px;
	}
}