// Global layout structure styles used on every page

body,
html {
	height: 100%;
	// overflow-x: hidden;
	letter-spacing: .3px;
}

#container {
	@include flex;
	background: #fff;
	flex-direction: column;
	min-height: 100%;
	position: relative;
}

main {
	@include flex;
	flex: 1;
	flex-direction: column;
	padding: 2rem;

	@include maxXS {
		padding: 2rem 1rem;
	}

}



:before,
:after {
	font-family: $body-font-family;
	font-weight: $font-weight-black;
	text-transform: uppercase;
	font-size: 1rem;
}

// Font Awesome

.fas:before,
.fa:before,
.far:before {
	font-family: 'Font Awesome 5 Free';
}

.far:before {
	font-weight: 500;
}

.clearfix {
	clear: both;
}

// Visibility

.hide-xs {
	@include maxXS {
		display: none;
	}
}