// Home page styles

body.index main {
	@include flex;
	background-color: #000;
	flex-direction: row;
	text-align: center;
	width: 100%;
	position: relative;
	overflow: hidden;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-image: url(../img/wastesure-bg-home.jpg);
		background-size: cover;
		background-position: center center;
		opacity: .7;
		z-index: 0;
		transform: scale(1.15);
		animation-name: ken-burns;
  		animation-duration: 4s;
  		animation-delay: 2s;
  		animation-fill-mode: forwards;
  		animation-timing-function: ease-in-out;
	}

	@keyframes ken-burns {
		0% { transform: scale(1.15); }
		100% { transform: scale(1); }
	}

	#content {
		@include flex-align-self(middle);
		width: 100%;
		position: relative;
	}

	h1 {
		margin: 0 auto 1rem;
		max-width: 980px;
		color: #fff;
		font-weight: $font-weight-bold;
		@include maxXS {
			max-width: 300px;
		}
	}

	p.home-subheading {
		font-size: 1.3rem;
		margin: 0 auto 1.5rem;
		max-width: 590px;
		opacity: 1;
		color: #fff;
		@include maxS {
			margin: 3rem auto 3.5rem;
		}
		@include maxS {
			font-size: .9rem;
			margin: 1rem auto 1rem;
		}
	}
}

.video-trigger {
	@include transition;

	background: #fff;
	border-radius: 100%;
	box-shadow: 0 0 0 0 #fff;
	cursor: pointer;
	height: 9rem;
	margin: 0 auto 4rem;
	position: relative;
	width: 9rem;
	transform-origin: center center;

	@include maxXS {
		transform: scale(0.5);
		margin-bottom: 0.3rem;
		margin-top: -1rem;
	}

	&:hover, &:focus {
		box-shadow: 0 0 0 0.5rem #fff;
		svg {
			fill: $colour-text-medium;
		}
	}

	svg {
		@include transition;
		fill: transparent;
		height: 4rem;
		left: 50%;
		margin: -2rem 0 0 -1.5rem;
		position: absolute;
		stroke: $colour-text-medium;
		top: 50%;
		transform-origin: center center;
		width: 4rem;
	}
}

.video-trigger.open {
	transition: 1s all cubic-bezier(.85,-0.5,.03,.99);
	box-shadow: 0 0 0 5rem rgba(255,255,255,0);
	&:hover, &:focus {
		box-shadow: 0 0 0 5rem rgba(255,255,255,0);
	}
	@include maxM {
		transform: scale(2);
	}
	@include maxXS {
		transform: scale(1);
	}
	svg {
		opacity: 0;
		transition: 1s all cubic-bezier(.85,-0.5,.03,.99);
	}
	.video-container {
		transform: scale(1);
		opacity: 1;
	}
}

.video-container {
	position: absolute;
	top: 10%;
	left: 15%;
	bottom: 10%;
	right: 15%;
	transform: scale(0) translateY(-60%);
	transition: 1s 0.05s all cubic-bezier(.85,-0.5,.03,.99);
	background: #fff;
	opacity: 0;
	border-radius: 100%;
	overflow: hidden;
	z-index: 1;
	box-shadow: 0 0 0px 5px rgba(255,255,255,0.9), 0 0 35px 10px rgba(0,0,0,0.1);

	@include maxM {
		left: 5%;
		right: 5%;
		transform: scale(0) translateY(-100%);
	}

	@include maxS {
		bottom: 30%;
		left: 10%;
		right: 10%;
		top: 30%;
		transform: scale(0) translateY(-120%);
	}

	@include maxXS {
		bottom: 36.4%;
		left: 4%;
		right: 4%;
		top: 36.5%;
		transform: scale(0) translateY(-180%);
	}

	@media (max-width: 375px) { max-height: 194px; }

	.close {
		@include transition;
		background: #fff;
		border-radius: 100%;
		border: 10px solid #fff;
		cursor: pointer;
		height: 40px;
		left: 8px;
		position: absolute;
		top: 8px;
		width: 40px;
		z-index: 2;
		&:before, &:after {
			background: $colour-background-medium;
			content: '';
			display: block;
			height: 100%;
			left: 50%;
			margin-left: -1px;
			position: absolute;
			top: 0;
			transform: rotate(45deg);
			width: 2px;
		}
		&:after {
			transform: rotate(-45deg);
		}
		&:hover {
			border-width: 12px;
		}
	}

	iframe {
		display: none;
	}
}

.video-container.open {
	transform: scale(1) translateY(0%);
	opacity: 1;
	border-radius: 15px;
	iframe {
		display: block;
	}
}

.video-container.close {
	transform: scale(0.7) translateY(-10%);
	opacity: 0;
	@include transition;
	iframe {
		opacity: 0;
	}
}

