/* 
	Typography
*/

// Paragraphs

p {
	color: $colour-dark-text;
	font-weight: $font-weight-medium;
}

b,
strong {
	font-weight: $font-weight-black;

	&.red {
		color: $colour-red;
	}

	&.green {
		color: $colour-green;
	}
}

.lowercase {
	text-transform: none;
}

// Headers

h1,
h2,
h3,
h4,
h5,
h6 {
	text-align: center;
}

h1,
h2 {
	@media (min-width: 40em) {
		font-size: 3rem;
	}

	@include maxM {
		font-size: 2.3rem;
	}

	@include maxXS {
		font-size: 1.4rem;
	}

}
