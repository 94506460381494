// INDEX OF FADING ENTRANCES

@import "_fadeIn",
"_fadeInDown",
"_fadeInDownBig",
"_fadeInLeft",
"_fadeInLeftBig",
"_fadeInRight",
"_fadeInRightBig",
"_fadeInUp",
"_fadeInUpBig",
"_slideInRightBig";



// OTHER

@keyframes fromLeft {
  from {
    transform: translateX(-101%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes fromRight {
  from {
    transform: translateX(101%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes fromBottom {
  from {
    transform: translateY(101%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes fromTop {
  from {
    transform: translateY(-101%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes arrowBob {
  0% {
    transform: translateY(-3px);
  }

  33% {
    transform: translateY(8px);
  }

  100% {
    transform: translateY(-3px);
  }
}