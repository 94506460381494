/*************************************************************************** 
	SCREENS
*/
$screen-tiny: 360px;
$screen-xxs: 400px;
$screen-xxs-max: 399px;
$screen-xs: 640px;
$screen-xs-max: 639px;
$screen-s: 768px;
$screen-s-max: 767px;
$screen-m: 1024px;
$screen-m-max: 1023px;
$screen-l: 1200px;
$screen-l-max: 1199px;
$screen-xl: 1600px;
$screen-xl-max: 1599px;


// Foundation breakpoints
$breakpoints: (
	small: 0,
	medium: 640px,
	large: 1200px,
	xlarge: 1200px,
	xxlarge: 1440px,
);

@mixin tinyPhoneOnly {
	@media only screen and (max-width: $screen-tiny) {
		@content;
	}
}

@mixin minXXS {
	@media only screen and (min-width: $screen-xxs) {
		@content;
	}
}

@mixin maxXXS {
	@media only screen and (max-width: $screen-xxs-max) {
		@content;
	}
}

@mixin minXS {
	@media only screen and (min-width: $screen-xs) {
		@content;
	}
}

@mixin maxXS {
	@media only screen and (max-width: $screen-xs-max) {
		@content;
	}
}

@mixin minS {
	@media only screen and (min-width: $screen-s) {
		@content;
	}
}

@mixin maxS {
	@media only screen and (max-width: $screen-s-max) {
		@content;
	}
}

@mixin minM {
	@media only screen and (min-width: $screen-m) {
		@content;
	}
}

@mixin maxM {
	@media only screen and (max-width: $screen-m-max) {
		@content;
	}
}

@mixin minL {
	@media only screen and (min-width: $screen-l) {
		@content;
	}
}

@mixin maxL {
	@media only screen and (max-width: $screen-l-max) {
		@content;
	}
}

@mixin minXL {
	@media only screen and (min-width: $screen-xl) {
		@content;
	}
}

@mixin maxXL {
	@media only screen and (max-width: $screen-xl-max) {
		@content;
	}
}

@mixin min($size) {
    @media only screen and (min-width:$size) { @content }
}

@mixin max($size) {
    @media only screen and (max-width:$size) { @content }
}

/*************************************************************************** 
	TYPEOGRAPHY
*/
$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-black: 800;
$body-font-family: 'Raleway', sans-serif;
$header-font-family: $body-font-family;
$header-font-weight: $font-weight-light;

/*************************************************************************** 
	COLOUR
*/
$colour-brand: #006949;
$colour-form-background: #0d8458;
$colour-brand-highlight: #f7a400;
$colour-brand-highlight-darker: #d9920a;
$colour-red: #db424b;
$colour-green: #77A542;
$colour-disabled: #c0c0c0;
$colour-background-medium-light: #A3D2FB;
$colour-background-dark: #333;
$colour-dark-text: #383838;
$colour-background: #f1f2f2;
$colour-form-background-light: #fdfefe;
$colour-light-grey: #f6f7f8;
$colour-dark-grey: #ededed;


$colour-success: $colour-brand;
$colour-background-medium: $colour-form-background;
$colour-header-primary: $colour-brand;
$colour-header-secondary: $colour-brand-highlight;
$colour-header-highlight: $colour-brand-highlight;
$colour-background-light: $colour-background;
$colour-brand-highlight: $colour-header-highlight;
$colour-brand-dark: $colour-background-dark;
$colour-text-medium: $colour-brand;
$colour-text-dark: $colour-background-dark;
$header-color: $colour-brand;

/*************************************************************************** 
	MIXINS
*/

@mixin tile-grid {
	display: grid;
	column-gap: 0.8rem;
	row-gap: 0.8rem;
}

@mixin hoverCard {
	background: #fff;
	border: 2px solid $colour-light-grey;
	padding: 1rem;
	margin-bottom: 1rem;
	@include transition;
	box-shadow: 0 0 30px -30px rgba(0, 0, 0, 0);

	&:hover {
		border-color: $colour-dark-grey;
		box-shadow: 0 20px 30px -30px rgba(0, 0, 0, 0.5);
	}
}

@mixin table-styles {
	background: $colour-form-background-light;
	border: 3px solid $colour-light-grey;
}

.clearfix {
	overflow: auto;

	&:after {
		
		content: "";
		clear: both;
		display: table;
	}
}

.hidden {
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	width: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
}

/********************
	FORMS
*/
$button-margin: $global-margin;
$input-height: 42px;
$input-height-small: 30px;
$input-font-small: 0.8rem;

@mixin form-container {
	margin: 0 auto;
	padding: 0;
	width: 100%;
	max-width: 100rem;
}

@mixin form-title {
	color: #fff;
	font-size: 2rem;
	font-weight: 200;
	line-height: 2.5rem;
	margin-bottom: 1rem;
	padding: 0.5rem 1.6rem;
	text-transform: none;
	font-weight: $font-weight-black;
	@include maxXS {
		font-size: 1.3rem;
		line-height: 1.4rem;
	}
}

@mixin date-fields {
	@include border-radius;
	@include transition;

	&:hover,
	&:focus,
	&:focus-within {
		background: lighten( $colour-form-background, 5% );
		label:nth-child(2):before,
		label:nth-child(2):after {
			opacity: 0;
		}
	}
	
	background: $colour-form-background;
	margin: 0 0 1rem;
	padding: 0.5rem 1.6rem 0.5rem 0.5rem;

	@include maxM { padding: 0.2rem 1.6rem 0.9rem; }
	@include maxXS { padding: 0.5rem 1rem 1.2rem }

	legend {
		box-shadow: none;
		float: left;
		margin: 0;
		padding: 0;
		transform: scale(1);
		width: 40%;
		text-align: center;
		background: none;

		@include maxM { width: 100% }
		@include maxXS { font-size: $input-font-small; }
	}

	span {
		float: right;
		width: 60%;

		@include maxM {
			float: none;
			width: 100%;
		}
	}

	label {
		clear: none;
		float: left;
		margin: 0;
		padding: 0;
		width: 33.33333%;
		position: relative;
		&:focus-within,
		&:hover {
			box-shadow: 0 0.2em 2.5em rgba(0,0,0,0);
	 		transform: scale(1);
		}

		&:nth-child(1) input {
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
		}
		&:nth-child(2) input {
			border-radius: 0;
			margin: 0;
		}
		&:nth-child(3) input {
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
			margin: 0;
		}
		// Decorative slashes
		&:nth-child(2):before,
		&:nth-child(2):after {
			background: $colour-background-medium;
			bottom: 2px;
			content: '';
			display: block;
			height: 33px;
			left: 0;
			margin: 0 0;
			position: absolute;
			top: 5px;
			transform: rotate(15deg);
			transition: 0.1s all ease-out;
			width: 2px;

			@include maxXS {
				height: 20px;
				top: 5px;
			}
		}
		&:nth-child(2):after {
			left: inherit;
			right: 0;
			z-index: 1;
		}
	}
}

@mixin label-block {
	@include border-radius;
	color: #fff;
	font-weight: $font-weight-black;
	font-size: 1rem;
	line-height: 2.5rem;
	text-transform: uppercase;
	background: $colour-background-medium;
	padding: 0.5rem 1.6rem;
	margin-bottom: 1rem;
	text-align: center;
}

@mixin time-fields {
	legend {
		float: left;
		width: 60%;
		@media (max-width: 1330px) {
			width: 100%;
			margin: 0;
		}
	}
	span {
		float: right;
		width: 40%;
		background-color: #fff;
		@include border-radius;
		position: relative;
		@media (max-width: 1330px) {
			width: 100%;
			margin-bottom: 1rem;
		}

	}
	label {
		background: none;
		width: 50%;
		float: left;
		margin-bottom: 0;
		input {
			width: 2.5rem;
			padding: 0;
			margin-bottom: 0;

		}
		&:first-of-type {
			input {
				float: right;
			}
		}
	}
}

@mixin full-width {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
}

/*************************************************************************** 
	FOOTER HELPERS
*/
$footer-height: 150px;

/*************************************************************************** 
	GENERAL MIXINS
*/
@mixin gradient (
$bgColor,       // background-color
$angle,         // gradient angle
$gradientColor, // gradient color
$opacityStart,  // opacity at start > 1 - 0
$opacityEnd     // opacity at end > 1 - 0
) {
	background-color: $bgColor;
	background-image: linear-gradient(
	($angle * 1deg),
	rgba($gradientColor, $opacityStart),
	rgba($gradientColor, $opacityEnd)
	);
	background-size: 100% 100%;
	background-position: 0 0;
	background-repeat: no-repeat;
}
 
//declare property like this
//@include gradient($bg-dark, 135, $bg-light, 1, 0);


@mixin border-radius {
	border-radius: 5px;
	@include minXL {
		border-radius: 8px;
	}
}

@mixin transition {
	transition: 0.2s all ease-out;
}

@mixin clearfix {
	content: '';
	display: table;
	clear: both;
}

@mixin focus {
	transition: all 0.1s ease-in-out;
	&:focus-within,
	&:hover {
		box-shadow: 0 0.2em 2.5em rgba(0,0,0,0.1);
 		transform: scale(1.025);
	}
	@media screen and (prefers-reduced-motion: reduce) {
		box-shadow: none;
		transform: none; 
	}
}

@mixin hover {
	transition: all 0.1s ease-in-out;
	&:focus-within {
		box-shadow: none;
		transform: none;
	}
	&:hover {
		box-shadow: 0 0.2em 2.5em rgba(0,0,0,0.1);
 		transform: scale(1.025);
	}
	@media screen and (prefers-reduced-motion: reduce) {
		box-shadow: none;
		transform: none; 
	}
}

@mixin center-vertical {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: center;
}

@mixin text-overflow-hidden {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin compact-table {
	@media (min-width: 1025px) and (max-width: 1500px) {
		@content;
	}
}

@mixin buttonStyle {
	text-transform: uppercase;
	font-weight: $font-weight-black;
	font-size: 1rem;
	color: #fff;
	background: $colour-brand;
	padding: 13px 15px;
	line-height: 1;
	margin: 0;
	border: none;
	display: inline-block;
	cursor: pointer;

	@include border-radius;
	@include transition;

	@include minL {
		padding: 15px;
	}

	@include maxL {
		font-size: 0.75rem;
	}

	@include minXL {
		padding: 1rem;
	}

	&:focus,
	&:hover {
		background: $colour-brand-highlight;
	}

	&.button-large {
		padding: 1.5rem 2rem;

		@include maxL {
			font-size: 1rem;
			padding: 1.2rem 1.3rem;
		}
	}

	&.button-highlight {
		background: $colour-brand-highlight;

		&:focus,
		&:hover {
			background: $colour-brand;
		}
	}

	&.button-red {
		background: $colour-red;
		color: #fff;

		&:hover,
		&:focus {
			background: $colour-red;
		}
	}

	&.button-green {
		background: $colour-green;
		color: #fff;

		&:hover,
		&:focus {
			background: $colour-green;
		}
	}
}