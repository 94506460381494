body.formstepwasteselect {
	section {
		@include center-vertical;
	}

	form {
		@include form-container;
		@include fadeIn ($duration: 1s);

		fieldset {
			margin-bottom: 2rem;
		}

		span {
			@include xy-grid;
			@include xy-grid-layout(1, 'legend');
			@include xy-grid-layout(6, 'label', true, 2.45rem);

			@include maxM {
				@include xy-grid-layout(3, 'label');
			}

			@include maxXS {
				@include xy-grid-layout(2, 'label');
			}
		}

		label {
			@include transition;
			cursor: pointer;
			text-align: left;
			padding: 0;
			font-size: 1rem;
			line-height: 1.2rem;
			background: none;
			border-radius: 0;
			color: $colour-brand;
			text-align: center;
			position: relative;

			@include maxM {
				margin-bottom: 1.8rem;
			}

			@include maxXS {
				font-size: .8rem;
			}

			&:hover,
			&:focus-within {
				color: $colour-brand-highlight;

				img,
				svg {
					transform: scale(1.05);
				}
			}

			img {
				background-color: $colour-background;
				overflow: hidden;
				@include transition;
				margin-bottom: 1.5rem;
			}

			svg {
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
				@include transition;
			}

			

			&.on {
				svg {
					opacity: 1;
					transform: scale(1);
					background: $colour-brand-highlight;
				}
			}
		}

		input[type="checkbox"] {
			position: absolute;

			+span {
				display: none;
			}
		}

		legend {
			background: none;
			color: $colour-text-medium;
			margin-bottom: 2rem;
			line-height: 1.5;
		}
	}
}