body.login {
	h2 { @include fadeInUp ( $duration: 1s, $delay: 0, $function: ease ); }
	form {
		@include fadeInUp ( $duration: 1s, $delay: 0, $function: ease, $fill: none );
		@include focus;

		background: $colour-background-medium;
		margin-top: 2rem;
		padding: 2rem;

		@include maxXS {
			padding: 2rem 1rem;
		}
		

		label {
			position: absolute; 
			overflow: hidden; 
			clip: rect(0 0 0 0); 
			height: 1px; width: 1px; 
			margin: -1px; padding: 0; border: 0; 
		}

		button[type] {
			padding: 1rem 1.5rem;
			margin-top: 1rem;

			&:hover, &:focus {
				background: #fff;
				color: $colour-text-dark;
			}
		}
	}
}