body.formstepdetails.domesticcustomer {
	section {
		@include form-container;
		@include xy-grid;
		@include xy-grid-layout(1, 'h2', true, 2.45rem);
		@include xy-grid-layout(2, 'form, .left', true, 2.45rem);

		@include maxL {
			@include xy-grid-layout(2, 'form, .left', true, 1rem);
		}

		@media (max-width: 940px) {
			@include xy-grid-layout(1, 'form, .left');
		}

		h2 {
			margin-bottom: 6rem;

			@include maxM {
				margin-bottom: 3rem;
			}

			@media (max-width: 880px) {
				font-size: 1.8rem;
			}

			@media (max-width: 550px) {
				font-size: 1.3rem;
			}

			span {
				display: block;
				margin: 0 auto;

				@media (max-width: 1445px) {
					max-width: 1000px;
				}

				@media (max-width: 1120px) {
					max-width: 750px;
				}

				@include maxM {
					max-width: 100%;
				}

				@media (max-width: 880px) {
					max-width: 600px;
				}

				@media (max-width: 550px) {
					max-width: 250px;
				}
			}
		}

		form {
			padding: 0;

			>fieldset {
				background: $colour-background-medium;
				@include border-radius;

				span {
					display: block;
				}
			}
		}

		.left,
		fieldset.section {
			@include fadeIn ($duration: 1s);
		}

		fieldset.section {
			margin-bottom: 2.45rem;
			padding: 2rem 4rem 2.5rem;

			@media (max-width: 1200px) {
				margin-bottom: 1rem;
			}

			@media (max-width: $screen-m) and (min-width: 941px) {
				padding-left: 2rem;
				padding-right: 2rem;
			}

			@include maxXS {
				padding: 1rem;
			}

			legend {
				padding-left: 0;
				padding-right: 0;
			}

			legend.title {
				@include form-title;
				padding-left: 0;
				padding-right: 0;
			}
		}

		.date {
			@include date-fields;
			padding: 0;

			&:hover,
			&:focus,
			&:focus-within {
				background: transparent;
			}

			@include maxM {
				padding: 0;
			}

			legend {
				font-size: 1rem;
				text-transform: uppercase;
				font-weight: $font-weight-black;
				width: 50%;
				padding: 0 0.5rem;
				line-height: 2.65rem;

				@media (max-width: 1330px) {
					width: 100%;
				}
			}

			span {
				width: 50%;

				@media (max-width: 1330px) {
					width: 100%;
				}

				label {
					background: none;
				}

				input {
					margin: 0;
				}
			}

			input[type] {
				padding: 0 1rem 0 3rem;
			}
		}

		.time {
			>legend {
				font-size: 1rem;
				text-transform: uppercase;
				font-weight: $font-weight-black;
				padding: 0 0.5rem;
				line-height: 2.65rem;
			}
		}

		textarea {
			@include focus;
		}

		@media (min-width: 941px) and (max-width: 1350px) {
			.split-left {
				width: 30%;
			}

			.split-right {
				width: 70%;
			}
		}

		legend {
			float: left;
			font-size: 2.2rem;
			font-weight: $font-weight-thin;
			text-transform: none;
			width: 100%;
		}

		label {
			padding: 0;
		}

		.split {
			@media (max-width: 420px) {
				width: 100%;
			}
		}

		button {
			display: block;
			width: 100%;
			padding: 1.5rem;
			max-width: 480px;
			margin: 3rem auto 0;
		}
	}
}

/*************************************************************************** 
	PRETEND FORM
*/


.pretend-form {
	@include border-radius;

	background: $colour-form-background;
	margin-bottom: 2.45rem;
	padding: 2rem 4rem 2.5rem;
	text-align: center;

	@media (max-width: 1200px) {
		margin-bottom: 1rem;
	}

	@media (max-width: $screen-m) and (min-width: 941px) {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	@include maxXS {
		padding: 1rem;
	}

	span.title {
		@include form-title;
		display: block;
		padding-left: 0;
		padding-right: 0;
	}

	ul {
		list-style: none;
		margin: 0;

		li {
			@include border-radius;

			background-color: #fff;
			color: $colour-text-medium;
			font-weight: $font-weight-black;
			margin-top: 1rem;
			padding: 0.5rem;
			text-transform: uppercase;
		}
	}
}

.pretend-form.disabled {
	background: $colour-brand;
}


.split {
	width: 50%;
	float: left;
	margin: 0;

	button[type] {
		width: 100%;
		height: $input-height;
		padding: 0;

		@include maxXS {
			height: $input-height-small;
			font-size: $input-font-small;
		}
	}
}

.split.split-left {
	padding-right: 0.5rem;

	@include maxXS {
		padding-right: 0.2rem;
	}

	input {
		margin-bottom: 0
	}
}

.split.split-right {
	padding-left: 0.5rem;

	@include maxXS {
		padding-left: 0.2rem;
	}
}

.split.split-right+* {
	clear: both;
}

body.formstepdetails.commercialcustomer {

	.container {
		@include minS {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 2rem;
		}
	}

	label {
		text-align: left;

		@include minL {
			display: grid;
			grid-template-columns: 150px 1fr;
			grid-gap: 1rem;
			margin: 0;
			padding: 1rem 1.5rem;

			input, select {
				margin: 0;
			}

			span {
				line-height: 1.1;
				display: block;
				margin: auto 0;
			}
		}
	}

	input {
		text-align: left;
	}

	h3 {
		color: #fff;
		font-weight: $font-weight-black;

		@include minL {
			text-align: right;
			padding-right: 1.5rem;
		}
	}

	fieldset {
		background: $colour-form-background;
		@include border-radius;

		@include minL {
			padding-bottom: 1rem;
		}

		legend {
			width: 100%;
			text-align: left;
			background: transparent;
			transform: translateY(36px);


		}

		&.references {
			margin-bottom: auto;
		}
	}

	p {
		margin-top: 2rem;
	}

	button {
		margin-bottom: 2rem;
		margin-top: 1rem;
	}

	#contact_create {
		@include fadeIn ($duration: .5s, $delay: 0s);
		@include minS {
			background: $colour-brand;
			@include border-radius;
			margin-left: 1rem;
			margin-right: 1rem;
			padding-top: 1rem;

			label {
				background: transparent;
			}
		}

		@include minL {
			margin-top: 1rem;
		}
	}
}