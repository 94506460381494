/*************************************************************************** 
	WASTE AMOUNT
*/

body.formstepwasteamountselect {
	.container {
		@include form-container;
		@include center-vertical;
	}

	section {
		@include xy-grid;
		@include xy-grid-layout(4, '.left, .right', true, 2.45rem);

		@include maxL {
			@include xy-grid-layout(1, '.left, .right', true, 1rem);
		}

		@include maxM {
			@include xy-grid-layout(1, '.left, .right', true, 1rem);
		}

		margin-top: 3rem;

		.left {
			@include fadeInLeft ($duration: 1s);

			@include maxL {
				order: 2;
				margin-top: 1rem;
				width: 19rem;
			}

			@include maxS {
				display: none;
			}

			.product-description {
				padding: 0.8rem;
			}

			.product-price {
				font-weight: $font-weight-black;
				color: $colour-brand;
				padding: 0.5rem;
				background: #fff;
				@include border-radius;

			}

			.button {
				display: block;
				margin: 1rem 0 0;
				background: $colour-brand;

				&:hover {
					opacity: 0.85;
				}

				@include maxL {
					width: 19rem;
				}

				@include maxS {
					padding: 1rem;
				}

				@include maxXS {
					width: 100%;
				}
			}
		}

		.right {
			@include border-radius;
			@include fadeInUp ($duration: 1s);
			flex-grow: 1;
			padding: 3rem 3rem 4rem;
			background: $colour-background-medium;
			text-align: center;
			

			@include maxL {
				padding: 1.5rem 2.5rem 2.5rem;
			}

			@include maxXS {
				padding: 1.5rem;
			}

			.form-container {
				display: flex;

				@include maxM {
					flex-flow: wrap;
					justify-content: space-between;
				}

				@media (max-width: 400px) {
					justify-content: flex-start;
				}
			}

			h2 {
				background: transparent;
				border-radius: 0;
				color: #fff;
				float: left;
				font-size: 1.7rem;
				line-height: 1.2;
				margin-top: 0.5rem;
				text-transform: none;
				width: 100%;
				font-weight: $font-weight-black;

				@include maxXS {
					margin-bottom: 2rem;
				}
			}

			form {
				padding: 0;
				width: 100%;
				margin: auto 0;
			}

			p {
				clear: both;
				color: #fff;
				margin-bottom: 2rem;

				@include maxXS {
					font-size: .9rem;
					line-height: 1.25;
				}
			}

			label {
				padding: 0;
				margin: 0;
				text-align: left;
				text-transform: capitalize;
				font-weight: $font-weight-medium;
				background: transparent;

				.material-name {
					font-size: 14px;
					line-height: 30px;
					vertical-align: top;
					padding-left: 10px;
					display: inline-block;

					@include maxXS {
						font-size: .9rem;
						padding-left: 5px;
						line-height: 21px;
						font-size: .7rem;
					}

					@media (max-width: 400px) {
						padding-left: 0;
					}
				}

				.material-colour {
					width: 22px;
					height: 22px;
					background: #fff;
					display: inline-block;
					border: 1px solid #fff;

					@media (max-width: 400px) {
						display: none;
					}
				}

				&:nth-child(1n) {
					.material-colour {
						background: #4e827c;
					}
				}

				&:nth-child(2n) {
					.material-colour {
						background: #89bbb5;
					}
				}

				&:nth-child(3n) {
					.material-colour {
						background: #b32c21;
					}
				}

				&:nth-child(4n) {
					.material-colour {
						background: #ffffff;
					}
				}

				&:nth-child(5n) {
					.material-colour {
						background: #a3d2fb;
					}
				}

				&:nth-child(6n) {
					.material-colour {
						background: #dec595;
					}
				}

				&:nth-child(7n) {
					.material-colour {
						background: #231f20;
					}
				}

				.slider {
					float: right;
					width: calc(100% - 180px);
					margin: .9rem 0;

					@include maxXS {
						width: calc(100% - 40%);
						margin: .6rem 0;
					}

					@media (max-width: 500px) {
						width: calc(100% - 50%);
						margin: .6rem 0;
					}
				}
			}

		}

		/* Fancy Skip */

		#skip-container {
			position: relative;
			width: 100%;
			margin: auto 3rem;
			overflow: hidden;

			@media (max-width: 1450px) {
				width: 70%;
			}

			@include maxM {
				margin-left: 0;
				margin-top: 2rem;
				margin-right: 0;
				width: 60%;
			}

			@media (max-width: 400px) {
				display: none;
			}

			img.front {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 2;
			}

			#skipContents {
				bottom: 0;
				top: 0;
				left: 20%;
				right: 10%;
				left: 25px;
				height: 100%;
				position: absolute;
				transform-origin: top;
				transform: scaleY(.98);
				width: 90%;
				z-index: 1;

				@include maxXS {
					left: 15px;
				}
			}

			.waste {
				background: #4e827c;
				bottom: 0;
				left: 0;
				position: absolute;
				transition: 0.5s height ease-in-out;
				width: 100%;
				z-index: 0;
				max-height: 100%;

				&:nth-child(2n) {
					background: #89bbb5;
				}

				&:nth-child(3n) {
					background: #b32c21;
				}

				&:nth-child(4n) {
					background: #ffffff;
				}

				&:nth-child(5n) {
					background: #a3d2fb;
				}

				&:nth-child(6n) {
					background: #dec595;
				}

				&:nth-child(7n) {
					background: #231f20;
				}
			}
		}

		/* Skip amount chart */

		#chart {
			color: #fff;
			position: relative;
			text-align: left;
			height: 125px;
			margin-top: auto;
			margin-bottom: auto;

			@include maxM {
				margin-top: 2rem;
			}

			@media (max-width: 400px) {
				margin-bottom: 1rem;
				margin-right: 0;
			}

			#bar {
				transition: 0.5s height ease-in-out;
				background: #fff;
				bottom: 0;
				left: 0;
				position: absolute;
				width: 20px;
				max-height: 100%;
			}

			ul {
				margin: 0 0 0 30px;
				border-left: 2px solid #fff;
				list-style: none;

				li {
					position: relative;
					padding-left: 20px;

					span {
						display: block;
					}

					&:before {
						background: #fff;
						bottom: 52%;
						content: '';
						display: block;
						height: 2px;
						left: 0;
						position: absolute;
						width: 10px;
					}

					&:nth-child(1) {
						transform: translateY(-10px);
					}

					&:nth-child(2) {
						transform: translateY(-4px);
					}

					&:nth-child(3) {
						transform: translateY(2px);
					}

					&:nth-child(4) {
						transform: translateY(8px);
					}

					&:nth-child(5) {
						transform: translateY(13px);
					}
				}
			}
		}

		#waste_amount_footer {
			@include xy-cell-offset(25%, 2.45rem);

			@include maxL {
				@include xy-cell-offset(2, 1rem);
				padding-right: 0.5rem;
			}

			@include maxM {
				@include xy-cell-offset(4, 1rem);
			}

			@include maxS {
				@include xy-cell(12, 1rem);
			}

			flex-grow: 1;
			text-align: right;
			padding: 1.225rem 1.225rem 0 0;
			margin-top: 1rem;
			
			p {
				color: $colour-brand;
				text-transform: uppercase;
				font-weight: $font-weight-black;
				line-height: 1.2;
				margin-bottom: 2rem;

				@include maxS {
					margin: 1rem 0 3rem;
				}

				@include maxXS {
					text-align: center;
				}
			}

			button {
				margin-bottom: 1.5rem;
				@include maxXS {
					width: 100%;
				}
			}
		}

	}

	.product-sm {
		padding: 2rem 1rem;
		background: $colour-brand;

		&:hover {
			transform: scale(1);
			box-shadow: none;
		}

		a {
			margin: 1.5rem auto 0.5rem;
		}
	}
}
