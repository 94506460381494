@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*************************************************************************** 
	SCREENS
*/
/*************************************************************************** 
	TYPEOGRAPHY
*/
/*************************************************************************** 
	COLOUR
*/
/*************************************************************************** 
	MIXINS
*/
.clearfix {
  overflow: auto;
}
.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

.hidden {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

/********************
	FORMS
*/
/*************************************************************************** 
	FOOTER HELPERS
*/
/*************************************************************************** 
	GENERAL MIXINS
*/
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-o-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-ms-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-ms-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50%);
    -moz-transform: translateX(50%);
    -ms-transform: translateX(50%);
    -o-transform: translateX(50%);
    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50%);
    -moz-transform: translateX(50%);
    -ms-transform: translateX(50%);
    -o-transform: translateX(50%);
    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-ms-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50%);
    -moz-transform: translateX(50%);
    -ms-transform: translateX(50%);
    -o-transform: translateX(50%);
    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50%);
    -moz-transform: translateX(50%);
    -ms-transform: translateX(50%);
    -o-transform: translateX(50%);
    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50%);
    -moz-transform: translateX(50%);
    -ms-transform: translateX(50%);
    -o-transform: translateX(50%);
    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-ms-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -ms-transform: translateY(50%);
    -o-transform: translateY(50%);
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -ms-transform: translateY(50%);
    -o-transform: translateY(50%);
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -ms-transform: translateY(50%);
    -o-transform: translateY(50%);
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -ms-transform: translateY(50%);
    -o-transform: translateY(50%);
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -ms-transform: translateY(50%);
    -o-transform: translateY(50%);
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-o-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes slideInRightBig {
  0% {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-moz-keyframes slideInRightBig {
  0% {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-ms-keyframes slideInRightBig {
  0% {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-o-keyframes slideInRightBig {
  0% {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@keyframes slideInRightBig {
  0% {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@keyframes fromLeft {
  from {
    transform: translateX(-101%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes fromRight {
  from {
    transform: translateX(101%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes fromBottom {
  from {
    transform: translateY(101%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes fromTop {
  from {
    transform: translateY(-101%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes arrowBob {
  0% {
    transform: translateY(-3px);
  }
  33% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(-3px);
  }
}
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
   ========================================================================== */
/**
 * 1. Change the default font family in all browsers (opinionated).
 * 2. Correct the line height in all browsers.
 * 3. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body, body.passwordreset {
  margin: 0;
}

/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
figcaption,
figure {
  display: block;
}

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * Add the correct display in IE.
 */
main {
  display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
a:active,
a:hover {
  outline-width: 0;
}

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ff0;
  color: #000;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
 * Show the overflow in IE.
 */
button {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type=button],
[type=reset],
[type=submit] {
  /**
   * Remove the inner border and padding in Firefox.
   */
  /**
   * Restore the focus styles unset by the previous rule.
   */
}
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Show the overflow in Edge.
 */
input {
  overflow: visible;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
   * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
   */
}
[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/*
 * Add the correct display in IE 9-.
 */
menu {
  display: block;
}

/* Scripting
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Hidden
   ========================================================================== */
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

.foundation-mq {
  font-family: "small=0em&medium=40em&large=75em&xlarge=75em&xxlarge=90em";
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body, body.passwordreset {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Raleway", sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0;
}

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0;
}

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important;
}

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto;
}
[data-whatinput=mouse] button {
  outline: 0;
}

pre {
  overflow: auto;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

[type=text], [type=password], [type=date], [type=datetime], [type=datetime-local], [type=month], [type=week], [type=email], [type=number], [type=search], [type=tel], [type=time], [type=url], [type=color],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none;
}
[type=text]:focus, [type=password]:focus, [type=date]:focus, [type=datetime]:focus, [type=datetime-local]:focus, [type=month]:focus, [type=week]:focus, [type=email]:focus, [type=number]:focus, [type=search]:focus, [type=tel]:focus, [type=time]:focus, [type=url]:focus, [type=color]:focus,
textarea:focus {
  outline: none;
  border: 1px solid #8a8a8a;
  background-color: #fefefe;
  box-shadow: 0 0 5px #cacaca;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

textarea {
  max-width: 100%;
}
textarea[rows] {
  height: auto;
}

input::placeholder,
textarea::placeholder {
  color: #cacaca;
}
input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed;
}

[type=submit],
[type=button] {
  appearance: none;
  border-radius: 0;
}

input[type=search] {
  box-sizing: border-box;
}

[type=file],
[type=checkbox],
[type=radio] {
  margin: 0 0 1rem;
}

[type=checkbox] + label,
[type=radio] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
}
[type=checkbox] + label[for],
[type=radio] + label[for] {
  cursor: pointer;
}

label > [type=checkbox],
label > [type=radio] {
  margin-right: 0.5rem;
}

[type=file] {
  width: 100%;
}

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a;
}
label.middle {
  margin: 0 0 1rem;
  padding: 0.5625rem 0;
}

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a;
}

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch;
}
.input-group > :first-child {
  border-radius: 0 0 0 0;
}
.input-group > :last-child > * {
  border-radius: 0 0 0 0;
}

.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label, .input-group-button, .input-group-field, .input-group-label {
  margin: 0;
  white-space: nowrap;
}

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}
.input-group-label:first-child {
  border-right: 0;
}
.input-group-label:last-child {
  border-left: 0;
}

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0;
}

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto;
}
.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  height: auto;
  align-self: stretch;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  max-width: 100%;
  margin-bottom: 0.5rem;
}

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca;
}
.fieldset legend {
  margin: 0;
  margin-left: -0.1875rem;
  padding: 0 0.1875rem;
}

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}
@media screen and (min-width: 0\0 ) {
  select {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg==");
  }
}
select:focus {
  outline: none;
  border: 1px solid #8a8a8a;
  background-color: #fefefe;
  box-shadow: 0 0 5px #cacaca;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}
select:disabled {
  background-color: #e6e6e6;
  cursor: not-allowed;
}
select::-ms-expand {
  display: none;
}
select[multiple] {
  height: auto;
  background-image: none;
}

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #f9ecea;
}
.is-invalid-input:not(:focus)::placeholder {
  color: #cc4b37;
}

.is-invalid-label {
  color: #cc4b37;
}

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37;
}
.form-error.is-visible {
  display: block;
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
}

em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: bold;
  line-height: inherit;
}

small {
  font-size: 80%;
  line-height: inherit;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 300;
  color: #006949;
  text-rendering: optimizeLegibility;
}
h1 small, .h1 small,
h2 small, .h2 small,
h3 small, .h3 small,
h4 small, .h4 small,
h5 small, .h5 small,
h6 small, .h6 small {
  line-height: 0;
  color: #cacaca;
}

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3rem;
  }

  h2, .h2 {
    font-size: 2.5rem;
  }

  h3, .h3 {
    font-size: 1.9375rem;
  }

  h4, .h4 {
    font-size: 1.5625rem;
  }

  h5, .h5 {
    font-size: 1.25rem;
  }

  h6, .h6 {
    font-size: 1rem;
  }
}
a {
  line-height: inherit;
  color: #1779ba;
  text-decoration: none;
  cursor: pointer;
}
a:hover, a:focus {
  color: #1468a0;
}
a img {
  border: 0;
}

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0;
}

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6;
}

li {
  font-size: inherit;
}

ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

ol {
  margin-left: 1.25rem;
}

ul ul, ul ol, ol ul, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

dl {
  margin-bottom: 1rem;
}
dl dt {
  margin-bottom: 0.3rem;
  font-weight: bold;
}

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca;
}
blockquote, blockquote p {
  line-height: 1.6;
  color: #8a8a8a;
}

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a;
}
cite:before {
  content: "— ";
}

abbr, abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none;
}

figure {
  margin: 0;
}

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a;
}

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a;
}

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a;
}

.lead {
  font-size: 125%;
  line-height: 1.6;
}

.stat {
  font-size: 2.5rem;
  line-height: 1;
}
p + .stat {
  margin-top: -1rem;
}

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left;
  }

  .medium-text-right {
    text-align: right;
  }

  .medium-text-center {
    text-align: center;
  }

  .medium-text-justify {
    text-align: justify;
  }
}
@media print, screen and (min-width: 75em) {
  .large-text-left {
    text-align: left;
  }

  .large-text-right {
    text-align: right;
  }

  .large-text-center {
    text-align: center;
  }

  .large-text-justify {
    text-align: justify;
  }
}
.show-for-print {
  display: none !important;
}

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important;
  }

  .show-for-print {
    display: block !important;
  }

  .hide-for-print {
    display: none !important;
  }

  table.show-for-print {
    display: table !important;
  }

  thead.show-for-print {
    display: table-header-group !important;
  }

  tbody.show-for-print {
    display: table-row-group !important;
  }

  tr.show-for-print {
    display: table-row !important;
  }

  td.show-for-print {
    display: table-cell !important;
  }

  th.show-for-print {
    display: table-cell !important;
  }

  a,
a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  .ir a:after,
a[href^="javascript:"]:after,
a[href^="#"]:after {
    content: "";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre,
blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }
  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  .print-break-inside {
    page-break-inside: auto;
  }
}
.button {
  display: inline-block;
  vertical-align: middle;
  margin: 1rem;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #1779ba;
  color: #fefefe;
}
[data-whatinput=mouse] .button {
  outline: 0;
}
.button:hover, .button:focus {
  background-color: #14679e;
  color: #fefefe;
}
.button.tiny {
  font-size: 0.6rem;
}
.button.small {
  font-size: 0.75rem;
}
.button.large {
  font-size: 1.25rem;
}
.button.expanded {
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}
.button.primary {
  background-color: #1779ba;
  color: #fefefe;
}
.button.primary:hover, .button.primary:focus {
  background-color: #126195;
  color: #fefefe;
}
.button.secondary {
  background-color: #767676;
  color: #fefefe;
}
.button.secondary:hover, .button.secondary:focus {
  background-color: #5e5e5e;
  color: #fefefe;
}
.button.success {
  background-color: #3adb76;
  color: #0a0a0a;
}
.button.success:hover, .button.success:focus {
  background-color: #22bb5b;
  color: #0a0a0a;
}
.button.warning {
  background-color: #ffae00;
  color: #0a0a0a;
}
.button.warning:hover, .button.warning:focus {
  background-color: #cc8b00;
  color: #0a0a0a;
}
.button.alert {
  background-color: #cc4b37;
  color: #fefefe;
}
.button.alert:hover, .button.alert:focus {
  background-color: #a53b2a;
  color: #fefefe;
}
.button.disabled, .button[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
  background-color: #1779ba;
  color: #fefefe;
}
.button.disabled.primary, .button[disabled].primary {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
  background-color: #1779ba;
  color: #fefefe;
}
.button.disabled.secondary, .button[disabled].secondary {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
  background-color: #767676;
  color: #fefefe;
}
.button.disabled.success, .button[disabled].success {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
  background-color: #3adb76;
  color: #0a0a0a;
}
.button.disabled.warning, .button[disabled].warning {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
  background-color: #ffae00;
  color: #0a0a0a;
}
.button.disabled.alert, .button[disabled].alert {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
  background-color: #cc4b37;
  color: #fefefe;
}
.button.hollow {
  border: 1px solid #1779ba;
  color: #1779ba;
}
.button.hollow, .button.hollow:hover, .button.hollow:focus {
  background-color: transparent;
}
.button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
  background-color: transparent;
}
.button.hollow:hover, .button.hollow:focus {
  border-color: #0c3d5d;
  color: #0c3d5d;
}
.button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
  border: 1px solid #1779ba;
  color: #1779ba;
}
.button.hollow.primary {
  border: 1px solid #1779ba;
  color: #1779ba;
}
.button.hollow.primary:hover, .button.hollow.primary:focus {
  border-color: #0c3d5d;
  color: #0c3d5d;
}
.button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
  border: 1px solid #1779ba;
  color: #1779ba;
}
.button.hollow.secondary {
  border: 1px solid #767676;
  color: #767676;
}
.button.hollow.secondary:hover, .button.hollow.secondary:focus {
  border-color: #3b3b3b;
  color: #3b3b3b;
}
.button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
  border: 1px solid #767676;
  color: #767676;
}
.button.hollow.success {
  border: 1px solid #3adb76;
  color: #3adb76;
}
.button.hollow.success:hover, .button.hollow.success:focus {
  border-color: #157539;
  color: #157539;
}
.button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
  border: 1px solid #3adb76;
  color: #3adb76;
}
.button.hollow.warning {
  border: 1px solid #ffae00;
  color: #ffae00;
}
.button.hollow.warning:hover, .button.hollow.warning:focus {
  border-color: #805700;
  color: #805700;
}
.button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
  border: 1px solid #ffae00;
  color: #ffae00;
}
.button.hollow.alert {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}
.button.hollow.alert:hover, .button.hollow.alert:focus {
  border-color: #67251a;
  color: #67251a;
}
.button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}
.button.clear {
  border: 1px solid #1779ba;
  color: #1779ba;
}
.button.clear, .button.clear:hover, .button.clear:focus {
  background-color: transparent;
}
.button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
  background-color: transparent;
}
.button.clear:hover, .button.clear:focus {
  border-color: #0c3d5d;
  color: #0c3d5d;
}
.button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
  border: 1px solid #1779ba;
  color: #1779ba;
}
.button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
  border-color: transparent;
}
.button.clear.primary {
  border: 1px solid #1779ba;
  color: #1779ba;
}
.button.clear.primary:hover, .button.clear.primary:focus {
  border-color: #0c3d5d;
  color: #0c3d5d;
}
.button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
  border: 1px solid #1779ba;
  color: #1779ba;
}
.button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
  border-color: transparent;
}
.button.clear.secondary {
  border: 1px solid #767676;
  color: #767676;
}
.button.clear.secondary:hover, .button.clear.secondary:focus {
  border-color: #3b3b3b;
  color: #3b3b3b;
}
.button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
  border: 1px solid #767676;
  color: #767676;
}
.button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
  border-color: transparent;
}
.button.clear.success {
  border: 1px solid #3adb76;
  color: #3adb76;
}
.button.clear.success:hover, .button.clear.success:focus {
  border-color: #157539;
  color: #157539;
}
.button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
  border: 1px solid #3adb76;
  color: #3adb76;
}
.button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
  border-color: transparent;
}
.button.clear.warning {
  border: 1px solid #ffae00;
  color: #ffae00;
}
.button.clear.warning:hover, .button.clear.warning:focus {
  border-color: #805700;
  color: #805700;
}
.button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
  border: 1px solid #ffae00;
  color: #ffae00;
}
.button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
  border-color: transparent;
}
.button.clear.alert {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}
.button.clear.alert:hover, .button.clear.alert:focus {
  border-color: #67251a;
  color: #67251a;
}
.button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}
.button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
  border-color: transparent;
}
.button.dropdown::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4em;
  content: "";
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #fefefe transparent transparent;
  position: relative;
  top: 0.4em;
  display: inline-block;
  float: right;
  margin-left: 1em;
}
.button.dropdown.hollow::after {
  border-top-color: #1779ba;
}
.button.dropdown.hollow.primary::after {
  border-top-color: #1779ba;
}
.button.dropdown.hollow.secondary::after {
  border-top-color: #767676;
}
.button.dropdown.hollow.success::after {
  border-top-color: #3adb76;
}
.button.dropdown.hollow.warning::after {
  border-top-color: #ffae00;
}
.button.dropdown.hollow.alert::after {
  border-top-color: #cc4b37;
}
.button.arrow-only::after {
  top: -0.1em;
  float: none;
  margin-left: 0;
}

a.button:hover, a.button:focus {
  text-decoration: none;
}

.pagination {
  content: "";
  display: table;
  clear: both;
  margin-left: 0;
  margin-bottom: 1rem;
}
.pagination li {
  margin-right: 0.0625rem;
  border-radius: 0;
  font-size: 0.875rem;
  display: none;
}
.pagination li:last-child, .pagination li:first-child {
  display: inline-block;
}
@media print, screen and (min-width: 40em) {
  .pagination li {
    display: inline-block;
  }
}
.pagination a,
.pagination button {
  display: block;
  padding: 0.1875rem 0.625rem;
  border-radius: 0;
  color: #0a0a0a;
}
.pagination a:hover,
.pagination button:hover {
  background: #e6e6e6;
}
.pagination .current {
  padding: 0.1875rem 0.625rem;
  background: #1779ba;
  color: #fefefe;
  cursor: default;
}
.pagination .disabled {
  padding: 0.1875rem 0.625rem;
  color: #cacaca;
  cursor: not-allowed;
}
.pagination .disabled:hover {
  background: transparent;
}
.pagination .ellipsis::after {
  padding: 0.1875rem 0.625rem;
  content: "…";
  color: #0a0a0a;
}

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: "«";
}

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: "»";
}

.is-off-canvas-open {
  overflow: hidden;
}

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(254, 254, 254, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}
.js-off-canvas-overlay.is-visible {
  opacity: 1;
  visibility: visible;
}
.js-off-canvas-overlay.is-closable {
  cursor: pointer;
}
.js-off-canvas-overlay.is-overlay-absolute {
  position: absolute;
}
.js-off-canvas-overlay.is-overlay-fixed {
  position: fixed;
}

.off-canvas-wrapper {
  position: relative;
  overflow: hidden;
}

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6;
}
[data-whatinput=mouse] .off-canvas {
  outline: 0;
}
.off-canvas.is-transition-push {
  z-index: 12;
}
.off-canvas.is-closed {
  visibility: hidden;
}
.off-canvas.is-transition-overlap {
  z-index: 13;
}
.off-canvas.is-transition-overlap.is-open {
  box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
}
.off-canvas.is-open {
  transform: translate(0, 0);
}
.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6;
}
[data-whatinput=mouse] .off-canvas-absolute {
  outline: 0;
}
.off-canvas-absolute.is-transition-push {
  z-index: 12;
}
.off-canvas-absolute.is-closed {
  visibility: hidden;
}
.off-canvas-absolute.is-transition-overlap {
  z-index: 13;
}
.off-canvas-absolute.is-transition-overlap.is-open {
  box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
}
.off-canvas-absolute.is-open {
  transform: translate(0, 0);
}

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(-250px);
}
.off-canvas-content .off-canvas.position-left {
  transform: translateX(-250px);
}
.off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

.off-canvas-content.is-open-left.has-transition-push {
  transform: translateX(250px);
}

.position-left.is-transition-push {
  box-shadow: inset -13px 0 20px -13px rgba(10, 10, 10, 0.25);
}

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(250px);
}
.off-canvas-content .off-canvas.position-right {
  transform: translateX(250px);
}
.off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

.off-canvas-content.is-open-right.has-transition-push {
  transform: translateX(-250px);
}

.position-right.is-transition-push {
  box-shadow: inset 13px 0 20px -13px rgba(10, 10, 10, 0.25);
}

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(-250px);
}
.off-canvas-content .off-canvas.position-top {
  transform: translateY(-250px);
}
.off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

.off-canvas-content.is-open-top.has-transition-push {
  transform: translateY(250px);
}

.position-top.is-transition-push {
  box-shadow: inset 0 -13px 20px -13px rgba(10, 10, 10, 0.25);
}

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(250px);
}
.off-canvas-content .off-canvas.position-bottom {
  transform: translateY(250px);
}
.off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

.off-canvas-content.is-open-bottom.has-transition-push {
  transform: translateY(-250px);
}

.position-bottom.is-transition-push {
  box-shadow: inset 0 13px 20px -13px rgba(10, 10, 10, 0.25);
}

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
}
.off-canvas-content.has-transition-push {
  transform: translate(0, 0);
}
.off-canvas-content .off-canvas.is-open {
  transform: translate(0, 0);
}

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-left.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-left.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-left {
    margin-left: 250px;
  }

  .position-left.reveal-for-medium ~ .off-canvas-content {
    margin-left: 250px;
  }

  .position-right.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-right.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-right.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-right {
    margin-right: 250px;
  }

  .position-right.reveal-for-medium ~ .off-canvas-content {
    margin-right: 250px;
  }

  .position-top.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-top.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-top.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-top {
    margin-top: 250px;
  }

  .position-top.reveal-for-medium ~ .off-canvas-content {
    margin-top: 250px;
  }

  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-bottom.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-bottom.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-bottom {
    margin-bottom: 250px;
  }

  .position-bottom.reveal-for-medium ~ .off-canvas-content {
    margin-bottom: 250px;
  }
}
@media print, screen and (min-width: 75em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-left.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-left.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-left {
    margin-left: 250px;
  }

  .position-left.reveal-for-large ~ .off-canvas-content {
    margin-left: 250px;
  }

  .position-right.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-right.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-right.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-right {
    margin-right: 250px;
  }

  .position-right.reveal-for-large ~ .off-canvas-content {
    margin-right: 250px;
  }

  .position-top.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-top.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-top.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-top {
    margin-top: 250px;
  }

  .position-top.reveal-for-large ~ .off-canvas-content {
    margin-top: 250px;
  }

  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-bottom.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-bottom.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-bottom {
    margin-bottom: 250px;
  }

  .position-bottom.reveal-for-large ~ .off-canvas-content {
    margin-bottom: 250px;
  }
}
@media print, screen and (min-width: 40em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit;
  }
  .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
    box-shadow: none;
    transform: none;
  }
  .off-canvas.in-canvas-for-medium .close-button {
    display: none;
  }
}
@media print, screen and (min-width: 75em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit;
  }
  .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
    box-shadow: none;
    transform: none;
  }
  .off-canvas.in-canvas-for-large .close-button {
    display: none;
  }
}
.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none;
}

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out;
}
.slider-fill.is-dragging {
  transition: all 0s linear;
}

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #1779ba;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation;
}
[data-whatinput=mouse] .slider-handle {
  outline: 0;
}
.slider-handle:hover {
  background-color: #14679e;
}
.slider-handle.is-dragging {
  transition: all 0s linear;
}

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1);
}
.slider.vertical .slider-fill {
  top: 0;
  width: 0.5rem;
  max-height: 100%;
}
.slider.vertical .slider-handle {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1.4rem;
  height: 1.4rem;
  transform: translateX(-50%);
}

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
[data-whatinput=mouse] .menu li {
  outline: 0;
}
.menu a,
.menu .button {
  line-height: 1;
  text-decoration: none;
  display: block;
  padding: 0.7rem 1rem;
}
.menu input,
.menu select,
.menu a,
.menu button {
  margin-bottom: 0;
}
.menu input {
  display: inline-block;
}
.menu, .menu.horizontal {
  flex-wrap: wrap;
  flex-direction: row;
}
.menu.vertical {
  flex-wrap: nowrap;
  flex-direction: column;
}
.menu.expanded li {
  flex: 1 1 0px;
}
.menu.simple {
  align-items: center;
}
.menu.simple li + li {
  margin-left: 1rem;
}
.menu.simple a {
  padding: 0;
}
@media print, screen and (min-width: 40em) {
  .menu.medium-horizontal {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .menu.medium-vertical {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .menu.medium-expanded li {
    flex: 1 1 0px;
  }
  .menu.medium-simple li {
    flex: 1 1 0px;
  }
}
@media print, screen and (min-width: 75em) {
  .menu.large-horizontal {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .menu.large-vertical {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .menu.large-expanded li {
    flex: 1 1 0px;
  }
  .menu.large-simple li {
    flex: 1 1 0px;
  }
}
.menu.nested {
  margin-right: 0;
  margin-left: 1rem;
}
.menu.icons a {
  display: flex;
}
.menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
  display: flex;
}
.menu.icon-left li a {
  flex-flow: row nowrap;
}
.menu.icon-left li a img,
.menu.icon-left li a i,
.menu.icon-left li a svg {
  margin-right: 0.25rem;
}
.menu.icon-right li a {
  flex-flow: row nowrap;
}
.menu.icon-right li a img,
.menu.icon-right li a i,
.menu.icon-right li a svg {
  margin-left: 0.25rem;
}
.menu.icon-top li a {
  flex-flow: column nowrap;
}
.menu.icon-top li a img,
.menu.icon-top li a i,
.menu.icon-top li a svg {
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center;
}
.menu.icon-bottom li a {
  flex-flow: column nowrap;
}
.menu.icon-bottom li a img,
.menu.icon-bottom li a i,
.menu.icon-bottom li a svg {
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center;
}
.menu .is-active > a {
  background: #1779ba;
  color: #fefefe;
}
.menu .active > a {
  background: #1779ba;
  color: #fefefe;
}
.menu.align-left {
  justify-content: flex-start;
}
.menu.align-right li {
  display: flex;
  justify-content: flex-end;
}
.menu.align-right li .submenu li {
  justify-content: flex-start;
}
.menu.align-right.vertical li {
  display: block;
  text-align: right;
}
.menu.align-right.vertical li .submenu li {
  text-align: right;
}
.menu.align-right .nested {
  margin-right: 1rem;
  margin-left: 0;
}
.menu.align-center li {
  display: flex;
  justify-content: center;
}
.menu.align-center li .submenu li {
  justify-content: flex-start;
}
.menu .menu-text {
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit;
}

.menu-centered > .menu {
  justify-content: center;
}
.menu-centered > .menu li {
  display: flex;
  justify-content: center;
}
.menu-centered > .menu li .submenu li {
  justify-content: flex-start;
}

.no-js [data-responsive-menu] ul {
  display: none;
}

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto;
}
.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0;
}
.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem;
}
.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #1779ba transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px;
}
.dropdown.menu a {
  padding: 0.7rem 1rem;
}
[data-whatinput=mouse] .dropdown.menu a {
  outline: 0;
}
.dropdown.menu .is-active > a {
  background: transparent;
  color: #1779ba;
}
.no-js .dropdown.menu ul {
  display: none;
}
.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0;
}
.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0;
}
.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0;
}
.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%;
}
.dropdown.menu.vertical > li > a::after {
  right: 14px;
}
.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent;
}
.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba;
}
@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem;
  }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: "";
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px;
  }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0;
  }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px;
  }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: "";
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent;
  }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: "";
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba;
  }
}
@media print, screen and (min-width: 75em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem;
  }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: "";
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px;
  }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0;
  }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px;
  }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: "";
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent;
  }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: "";
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba;
  }
}
.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto;
}

.is-dropdown-menu.vertical {
  width: 100px;
}
.is-dropdown-menu.vertical.align-right {
  float: right;
}

.is-dropdown-submenu-parent {
  position: relative;
}
.is-dropdown-submenu-parent a::after {
  position: absolute;
  top: 50%;
  right: 5px;
  left: auto;
  margin-top: -6px;
}
.is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
  top: 100%;
  left: auto;
}
.is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
}
.is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%;
}

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fefefe;
}
.dropdown .is-dropdown-submenu a {
  padding: 0.7rem 1rem;
}
.is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
  right: 14px;
}
.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent;
}
.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba;
}
.is-dropdown-submenu .is-dropdown-submenu {
  margin-top: -1px;
}
.is-dropdown-submenu > li {
  width: 100%;
}
.is-dropdown-submenu.js-dropdown-active {
  display: block;
}

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap;
}
.top-bar,
.top-bar ul {
  background-color: #e6e6e6;
}
.top-bar input {
  max-width: 200px;
  margin-right: 1rem;
}
.top-bar .input-group-field {
  width: 100%;
  margin-right: 0;
}
.top-bar input.button {
  width: auto;
}
.top-bar .top-bar-left,
.top-bar .top-bar-right {
  flex: 0 0 100%;
  max-width: 100%;
}
@media print, screen and (min-width: 40em) {
  .top-bar {
    flex-wrap: nowrap;
  }
  .top-bar .top-bar-left {
    flex: 1 1 auto;
    margin-right: auto;
  }
  .top-bar .top-bar-right {
    flex: 0 1 auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 74.9375em) {
  .top-bar.stacked-for-medium {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-medium .top-bar-left,
.top-bar.stacked-for-medium .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 74.9375em) {
  .top-bar.stacked-for-large {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-large .top-bar-left,
.top-bar.stacked-for-large .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0;
}

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto;
}

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none;
}
.accordion[disabled] .accordion-title {
  cursor: not-allowed;
}

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0;
}
.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0;
}

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #1779ba;
}
:last-child:not(.is-active) > .accordion-title {
  border-bottom: 1px solid #e6e6e6;
  border-radius: 0 0 0 0;
}
.accordion-title:hover, .accordion-title:focus {
  background-color: #e6e6e6;
}
.accordion-title::before {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -0.5rem;
  content: "+";
}
.is-active > .accordion-title::before {
  content: "–";
}

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #0a0a0a;
}
:last-child > .accordion-content:last-child {
  border-bottom: 1px solid #e6e6e6;
}

header {
  display: flex;
  flex-direction: column;
}

#header_main {
  background-color: #006949;
  text-transform: uppercase;
  padding: 1.8rem 2.1rem;
  display: grid;
  grid-template-columns: 220px auto auto;
  grid-gap: 2rem;
}
@media only screen and (max-width: 767px) {
  #header_main {
    grid-template-columns: 150px auto auto;
  }
}
@media (max-width: 550px) {
  #header_main {
    grid-template-columns: 100%;
    grid-gap: 1rem;
    text-align: center;
    padding: 1rem;
  }
}
#header_main #brand {
  align-self: center;
}
@media only screen and (max-width: 639px) {
  #header_main #brand {
    text-align: center;
  }
}
#header_main #brand a {
  display: block;
  transform: translateY(8px);
  max-width: 220px;
  margin: auto;
}
@media (max-width: 550px) {
  #header_main #brand a {
    max-width: 130px;
  }
}
#header_main p#slogan {
  color: #fff;
  font-weight: 800;
  line-height: 1.3;
  margin-bottom: 0;
  align-self: center;
}
@media only screen and (max-width: 767px) {
  #header_main p#slogan {
    font-size: 0.84rem;
    line-height: 1.2;
  }
}
@media (max-width: 550px) {
  #header_main p#slogan {
    font-size: 2.9vw;
    line-height: 1.2;
    letter-spacing: 0.4px;
  }
}
#header_main nav {
  align-self: center;
}
@media (max-width: 550px) {
  #header_main nav {
    position: absolute;
    top: 12px;
    right: 5px;
  }
}
#header_main nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto;
  grid-gap: 0.5rem;
}
[data-whatinput=mouse] #header_main nav ul li {
  outline: 0;
}
#header_main nav ul a,
#header_main nav ul .button {
  line-height: 1;
  text-decoration: none;
  display: block;
  padding: 0.7rem 1rem;
}
#header_main nav ul input,
#header_main nav ul select,
#header_main nav ul a,
#header_main nav ul button {
  margin-bottom: 0;
}
#header_main nav ul input {
  display: inline-block;
}
@media only screen and (max-width: 639px) {
  #header_main nav ul {
    justify-content: center;
  }
}
@media only screen and (max-width: 639px) {
  #header_main nav ul li.header-button-primary {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  #header_main nav ul li.header-button-quote {
    display: none;
  }
}
@media only screen and (max-width: 639px) {
  #header_main nav ul li.header-button-login {
    display: none;
  }
}
#header_main nav ul li.hamburger {
  display: none;
}
@media only screen and (max-width: 639px) {
  #header_main nav ul li.hamburger {
    display: block;
  }
}
#header_main nav ul li a {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1rem;
  color: #fff;
  background: #006949;
  padding: 13px 15px;
  line-height: 1;
  margin: 0;
  border: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.2s all ease-out;
  color: #006949;
  background: #fff;
}
@media only screen and (min-width: 1600px) {
  #header_main nav ul li a {
    border-radius: 8px;
  }
}
@media only screen and (min-width: 1200px) {
  #header_main nav ul li a {
    padding: 15px;
  }
}
@media only screen and (max-width: 1199px) {
  #header_main nav ul li a {
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1600px) {
  #header_main nav ul li a {
    padding: 1rem;
  }
}
#header_main nav ul li a:focus, #header_main nav ul li a:hover {
  background: #f7a400;
}
#header_main nav ul li a.button-large {
  padding: 1.5rem 2rem;
}
@media only screen and (max-width: 1199px) {
  #header_main nav ul li a.button-large {
    font-size: 1rem;
    padding: 1.2rem 1.3rem;
  }
}
#header_main nav ul li a.button-highlight {
  background: #f7a400;
}
#header_main nav ul li a.button-highlight:focus, #header_main nav ul li a.button-highlight:hover {
  background: #006949;
}
#header_main nav ul li a.button-red {
  background: #db424b;
  color: #fff;
}
#header_main nav ul li a.button-red:hover, #header_main nav ul li a.button-red:focus {
  background: #db424b;
}
#header_main nav ul li a.button-green {
  background: #77A542;
  color: #fff;
}
#header_main nav ul li a.button-green:hover, #header_main nav ul li a.button-green:focus {
  background: #77A542;
}
#header_main nav ul li a:hover, #header_main nav ul li a:focus {
  filter: brightness(0.9);
  background: #fff;
}
#header_main nav ul .button-highlight {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1rem;
  color: #fff;
  background: #006949;
  padding: 13px 15px;
  line-height: 1;
  margin: 0;
  border: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.2s all ease-out;
  background: #f7a400;
}
@media only screen and (min-width: 1600px) {
  #header_main nav ul .button-highlight {
    border-radius: 8px;
  }
}
@media only screen and (min-width: 1200px) {
  #header_main nav ul .button-highlight {
    padding: 15px;
  }
}
@media only screen and (max-width: 1199px) {
  #header_main nav ul .button-highlight {
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1600px) {
  #header_main nav ul .button-highlight {
    padding: 1rem;
  }
}
#header_main nav ul .button-highlight:focus, #header_main nav ul .button-highlight:hover {
  background: #f7a400;
}
#header_main nav ul .button-highlight.button-large {
  padding: 1.5rem 2rem;
}
@media only screen and (max-width: 1199px) {
  #header_main nav ul .button-highlight.button-large {
    font-size: 1rem;
    padding: 1.2rem 1.3rem;
  }
}
#header_main nav ul .button-highlight.button-highlight {
  background: #f7a400;
}
#header_main nav ul .button-highlight.button-highlight:focus, #header_main nav ul .button-highlight.button-highlight:hover {
  background: #006949;
}
#header_main nav ul .button-highlight.button-red {
  background: #db424b;
  color: #fff;
}
#header_main nav ul .button-highlight.button-red:hover, #header_main nav ul .button-highlight.button-red:focus {
  background: #db424b;
}
#header_main nav ul .button-highlight.button-green {
  background: #77A542;
  color: #fff;
}
#header_main nav ul .button-highlight.button-green:hover, #header_main nav ul .button-highlight.button-green:focus {
  background: #77A542;
}
#header_main nav ul .button-highlight:hover, #header_main nav ul .button-highlight:focus {
  background: #f7a400 !important;
}
@media only screen and (max-width: 639px) {
  #header_main nav ul .button-highlight {
    display: none;
  }
}
#header_main nav ul #hamburger_button {
  padding: 0.7rem 0.6rem;
}
#header_main nav ul #hamburger_button span {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer;
}
#header_main nav ul #hamburger_button span::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #0a0a0a;
  box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
  content: "";
}
#header_main nav ul #hamburger_button span:hover::after {
  background: #8a8a8a;
  box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a;
}
@media only screen and (max-width: 639px) {
  #header_main nav ul #hamburger_button {
    background: transparent;
  }
  #header_main nav ul #hamburger_button span:after, #header_main nav ul #hamburger_button:hover span:after {
    background: #fff;
    box-shadow: 0 7px 0 #fff, 0 14px 0 #fff;
  }
}
#header_main #login_details {
  position: absolute;
  top: 0.3rem;
  right: 2.1rem;
  color: #fff;
  font-size: 0.6rem;
  text-transform: initial;
}

body.portal #header_main #hamburger_button {
  display: none;
}

@media (min-width: 768px) and (max-width: 1030px) {
  body.portal.admin #header_main nav ul li a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 115px;
  }
}

#header_bottom {
  display: grid;
  grid-template-columns: repeat(2, auto);
  background-color: #f7a400;
  font-size: 1rem;
  padding: 0.45rem 2.1rem;
}
@media only screen and (max-width: 1023px) {
  #header_bottom {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 639px) {
  #header_bottom {
    display: none;
  }
}
#header_bottom p {
  color: #383838;
  margin: 0;
  flex-grow: 1;
}
@media only screen and (max-width: 639px) {
  #header_bottom p {
    display: none;
  }
}
#header_bottom p a,
#header_bottom p span {
  font-weight: 800;
}
#header_bottom p a {
  color: inherit;
  text-decoration: none;
}
#header_bottom p span {
  color: #fff;
  text-transform: uppercase;
}

#review_summary {
  text-align: right;
}
#review_summary img {
  transform: translateY(-1px);
}
@media only screen and (max-width: 1023px) {
  #review_summary img {
    transform: translateY(-2px);
  }
}
@media only screen and (max-width: 767px) {
  #review_summary {
    display: none;
  }
}

.off-canvas {
  background-color: #f7a400;
}
.off-canvas nav ul {
  list-style: none;
  margin: 0;
}
.off-canvas nav ul li a {
  transition: 0.2s all ease-out;
  color: #fff;
  display: block;
  font-weight: 800;
  outline: none;
  text-align: center;
  text-transform: uppercase;
  padding: 0.8rem 1rem;
}
.off-canvas nav ul li a:hover {
  background: rgba(255, 255, 255, 0.1);
}

.js-off-canvas-overlay {
  background: rgba(0, 0, 0, 0.65);
}

footer {
  background-color: #006949;
  bottom: 0;
  font-size: 0.8rem;
  height: 150px;
  left: 0;
  padding: 1.8rem;
  right: 0;
  text-align: center;
  z-index: -1;
}
footer p,
footer a {
  border-radius: 5px;
  transition: 0.2s all ease-out;
  color: #fff;
  margin: 0 0.5rem;
}
@media only screen and (min-width: 1600px) {
  footer p,
footer a {
    border-radius: 8px;
  }
}
footer a:hover, footer a:focus {
  color: #fff;
  background: rgba(250, 250, 250, 0.15);
}
footer ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
[data-whatinput=mouse] footer ul li {
  outline: 0;
}
footer ul a,
footer ul .button {
  line-height: 1;
  text-decoration: none;
  display: block;
  padding: 0.7rem 1rem;
}
footer ul input,
footer ul select,
footer ul a,
footer ul button {
  margin-bottom: 0;
}
footer ul input {
  display: inline-block;
}
footer ul li:not(:first-child) {
  border-left: 1px solid rgba(250, 250, 250, 0.5);
}

#copyright,
#credit {
  font-size: 0.6rem;
  margin: 0 0 0.2rem;
}
#copyright a,
#credit a {
  margin: 0;
}

#copyright {
  margin-top: 1rem;
}

.powered-by {
  display: none;
}

.container {
  width: 100%;
}

body.domesticcustomer #header_main,
body.domesticcustomer footer nav {
  display: none;
}
body.domesticcustomer footer {
  height: auto;
}
body.domesticcustomer footer #copyright {
  margin-top: 0;
}
body.domesticcustomer #header_bottom {
  display: grid;
}
body.domesticcustomer #header_bottom p {
  display: block;
}

body.documentsign #header_main,
body.documentsign footer nav {
  display: none;
}
body.documentsign footer {
  height: auto;
}
body.documentsign footer #copyright {
  margin-top: 0;
}
body.documentsign #header_bottom {
  display: grid;
}
body.documentsign #header_bottom p {
  display: block;
}

body, body.passwordreset,
html {
  height: 100%;
  letter-spacing: 0.3px;
}

#container {
  display: flex;
  background: #fff;
  flex-direction: column;
  min-height: 100%;
  position: relative;
}

main {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2rem;
}
@media only screen and (max-width: 639px) {
  main {
    padding: 2rem 1rem;
  }
}

:before,
:after {
  font-family: "Raleway", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 1rem;
}

.fas:before,
.fa:before,
.far:before {
  font-family: "Font Awesome 5 Free";
}

.far:before {
  font-weight: 500;
}

.clearfix {
  clear: both;
}

@media only screen and (max-width: 639px) {
  .hide-xs {
    display: none;
  }
}

/* 
	Typography
*/
p {
  color: #383838;
  font-weight: 500;
}

b,
strong {
  font-weight: 800;
}
b.red,
strong.red {
  color: #db424b;
}
b.green,
strong.green {
  color: #77A542;
}

.lowercase {
  text-transform: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
}

@media (min-width: 40em) {
  h1,
h2 {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 1023px) {
  h1,
h2 {
    font-size: 2.3rem;
  }
}
@media only screen and (max-width: 639px) {
  h1,
h2 {
    font-size: 1.4rem;
  }
}

.alert {
  border-radius: 5px;
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #ccc;
  border: 1px solid #aaa;
  font-weight: 500;
  list-style-type: none;
  margin: 1rem auto;
  max-width: 500px;
  padding: 0.5rem 0.8rem 0.6rem 0.8rem;
}
@media only screen and (min-width: 1600px) {
  .alert {
    border-radius: 8px;
  }
}
.alert > li:last-child {
  margin-bottom: 0;
}
.alert > li:before {
  margin-right: 0.5rem;
  content: "✱";
}

#errors {
  border-color: #db424b;
  color: #fff;
  background-color: #db424b;
}
#errors > li:before {
  content: "\f071";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

#success {
  background-color: #006949;
  border-color: #006949;
  color: #fff;
  padding: 1rem 1rem 1rem 2.5rem;
}
#success > li:before {
  background-image: url(../img/ui-tick.svg);
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 40px;
  position: absolute;
  width: 30px;
  background-position: -40px -66px;
  background-size: 100px 100px;
  left: 12px;
  top: 11px;
}

/*************************************************************************** 
	RANGE SLIDER
*/
.slider,
.slider-fill {
  background: #fff;
  height: 2px;
}

.slider {
  display: block;
}
.slider:before, .slider:after {
  background-color: #fff;
  content: "";
  display: block;
  height: 6px;
  left: 0;
  position: absolute;
  top: -2px;
  width: 6px;
  border-radius: 100%;
}
.slider:after {
  left: inherit;
  right: 0;
}

span.slider-handle {
  background: #fff;
  border-radius: 100%;
  border: 2px solid #0d8458;
  outline: none;
  height: 20px;
  width: 20px;
  transition: 0.3s all ease-out;
}
span.slider-handle:hover, span.slider-handle:active {
  background-color: #fff;
  width: 25px;
  height: 25px;
  margin-left: -2.5px;
}

/*************************************************************************** 
	RADIO SWITCH
*/
.switch {
  border-radius: 5px;
  position: relative;
  margin: 0 auto 1rem;
  background: #006949;
  overflow: hidden;
}
@media only screen and (min-width: 1600px) {
  .switch {
    border-radius: 8px;
  }
}
.switch .switch-label {
  background: transparent;
  cursor: pointer;
  float: left;
  font-weight: 300;
  height: 42px;
  line-height: 2.6rem;
  margin: 0;
  position: relative;
  text-align: center;
  transition-property: color;
  transition: 0.15s ease-out;
  width: 50%;
  z-index: 2;
}
.switch .switch-label:hover {
  transform: scale(1);
}
@media only screen and (max-width: 1199px) {
  .switch .switch-label {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 639px) {
  .switch .switch-label {
    line-height: 2.6rem;
  }
  .switch .switch-label span {
    display: none;
  }
}
.switch [type=radio] + label {
  margin: 0;
}
.switch .switch-input {
  opacity: 0;
  position: absolute;
}
.switch .switch-input:checked + .switch-label {
  color: #006949;
  transition-property: color;
  transition: 0.15s ease-out;
}
.switch .switch-input:checked + .switch-label-on ~ .switch-selection {
  left: 50%;
}
.switch span.switch-selection {
  border-radius: 5px;
  background-color: #fff;
  display: block;
  height: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
  transition: left 0.15s ease-out;
  width: 50%;
  z-index: 1;
}
@media only screen and (min-width: 1600px) {
  .switch span.switch-selection {
    border-radius: 8px;
  }
}

span.switch-hint {
  font-size: 0.7rem;
  margin: 2rem 0 1rem;
  color: #fff;
  position: relative;
  display: none;
}
span.switch-hint i {
  position: absolute;
  top: -25px;
  left: 50%;
  margin-left: -12px;
}
@media only screen and (max-width: 639px) {
  span.switch-hint {
    display: block;
  }
}

/*************************************************************************** 
	Loading icon
*/
.loading {
  text-align: center;
}
.loading img {
  margin-bottom: 5px;
  width: 30px;
}

/*************************************************************************** 
	Make the entire date field trigger the datepicker on Chrome
*/
input[type=date] {
  position: relative;
}

/* create a new arrow, because we are going to mess up the native one
see "List of symbols" below if you want another, you could also try to add a font-awesome icon.. */
input[type=date]:after {
  content: "\f073";
  font-family: "Font Awesome 5 Free";
  color: #006949;
  padding: 0 5px;
  position: absolute;
  top: 8px;
  right: 5px;
}
@media only screen and (max-width: 639px) {
  input[type=date]:after {
    top: 2px;
  }
}

/* change color of symbol on hover */
input[type=date]:hover:after {
  color: #f7a400;
}

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type=date]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

input[type=date]::-webkit-clear-button {
  z-index: 1;
}

input[type=date]::-webkit-inner-spin-button {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px #fff inset;
  -webkit-text-fill-color: #006949;
}

input::-webkit-input-placeholder {
  color: rgba(0, 105, 73, 0.5);
}

input::-moz-placeholder {
  color: rgba(0, 105, 73, 0.5);
}

input::-ms-placeholder {
  color: rgba(0, 105, 73, 0.5);
}

input::placeholder {
  color: rgba(0, 105, 73, 0.5);
}

textarea::-webkit-input-placeholder {
  color: rgba(0, 105, 73, 0.5);
}

textarea::-moz-placeholder {
  color: rgba(0, 105, 73, 0.5);
}

textarea::-ms-placeholder {
  color: rgba(0, 105, 73, 0.5);
}

textarea::placeholder {
  color: rgba(0, 105, 73, 0.5);
}

.pagination {
  margin-top: 1rem;
}
.pagination li,
.pagination a {
  border-radius: 50px;
  font-weight: 800;
  color: #006949;
  height: 28px;
  line-height: 20px;
  transition: 0.2s all ease-out;
}
.pagination li.current {
  padding: 0;
  background: #f7a400;
}
.pagination li.current a {
  color: #006949;
}
.pagination li.current a:hover, .pagination li.current a:focus {
  background: #f7a400;
}
.pagination li.arrow.unavailable a {
  cursor: default;
}
.pagination li.arrow.unavailable a:hover {
  background-color: #fff;
}

.tabs {
  clear: both;
}
.tabs.dashboard {
  padding-top: 3rem;
}
.tabs ul {
  margin: 0;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto;
  grid-gap: 0.5rem;
}
@media only screen and (max-width: 767px) {
  .tabs ul {
    grid-auto-flow: unset;
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (max-width: 639px) {
  .tabs ul {
    grid-auto-flow: unset;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 360px) {
  .tabs ul {
    grid-auto-flow: unset;
    grid-template-columns: repeat(1, 1fr);
  }
}
.tabs ul li {
  list-style: none;
  display: flex;
}
@media only screen and (min-width: 1200px) {
  .tabs ul li a {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0);
  }
  .tabs ul li:hover a {
    transform: translateY(-4px);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  }
}
.tabs ul li .button {
  display: flex;
}
.tabs ul li .button span {
  margin: auto;
}
.tabs ul li.red a,
.tabs ul li.red a:hover,
.tabs ul li.red a:focus {
  background: #db424b;
}
.tabs ul li.is-active a,
.tabs ul li.is-active a:hover,
.tabs ul li.is-active a:focus {
  background: #006949;
}
.tabs ul li.disabled {
  pointer-events: none;
}
.tabs ul li.disabled a {
  background: #c0c0c0;
}
.tabs ul li a {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1rem;
  color: #fff;
  background: #006949;
  padding: 13px 15px;
  line-height: 1;
  margin: 0;
  border: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.2s all ease-out;
  margin: 0;
  display: flex;
  flex: 1;
  background: #f7a400;
  text-align: center;
}
@media only screen and (min-width: 1600px) {
  .tabs ul li a {
    border-radius: 8px;
  }
}
@media only screen and (min-width: 1200px) {
  .tabs ul li a {
    padding: 15px;
  }
}
@media only screen and (max-width: 1199px) {
  .tabs ul li a {
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1600px) {
  .tabs ul li a {
    padding: 1rem;
  }
}
.tabs ul li a:focus, .tabs ul li a:hover {
  background: #f7a400;
}
.tabs ul li a.button-large {
  padding: 1.5rem 2rem;
}
@media only screen and (max-width: 1199px) {
  .tabs ul li a.button-large {
    font-size: 1rem;
    padding: 1.2rem 1.3rem;
  }
}
.tabs ul li a.button-highlight {
  background: #f7a400;
}
.tabs ul li a.button-highlight:focus, .tabs ul li a.button-highlight:hover {
  background: #006949;
}
.tabs ul li a.button-red {
  background: #db424b;
  color: #fff;
}
.tabs ul li a.button-red:hover, .tabs ul li a.button-red:focus {
  background: #db424b;
}
.tabs ul li a.button-green {
  background: #77A542;
  color: #fff;
}
.tabs ul li a.button-green:hover, .tabs ul li a.button-green:focus {
  background: #77A542;
}
.tabs ul li a:hover, .tabs ul li a:focus {
  filter: brightness(1.06);
}
.tabs ul li a span {
  margin: auto;
}
.tabs#ordersOverviewTabs {
  margin-top: 2rem;
}
@media only screen and (max-width: 1023px) {
  .tabs#ordersOverviewTabs {
    margin-top: 1rem;
  }
}

#customerViewTabs {
  margin-top: 2.5rem;
}

.tabs-panel {
  display: none;
  margin: 2.5rem 0;
}
@media only screen and (min-width: 1600px) {
  .tabs-panel {
    margin: 3rem 0;
  }
}
.tabs-panel.is-active {
  display: block;
}
.tabs-panel .orders-grid {
  margin-bottom: 3rem;
}

#portal_content .tabs-panel h2 {
  font-weight: 800 !important;
  margin-bottom: 0.5rem !important;
  color: #f7a400;
}
@media only screen and (max-width: 1199px) {
  #portal_content .tabs-panel h2 {
    font-size: 1.5rem !important;
  }
}
@media only screen and (min-width: 1200px) {
  #portal_content .tabs-panel h2 {
    font-size: 2rem !important;
    line-height: 2.5rem !important;
  }
}
#portal_content .tabs-panel h2.green {
  color: #006949;
}
#portal_content .tabs-panel .documents {
  margin-bottom: 3rem;
}
#portal_content #tabCustomerDetail address,
#portal_content #tabProviderDetail address,
#portal_content #tabDetails address {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
#portal_content #tabCustomerDetail table,
#portal_content #tabProviderDetail table,
#portal_content #tabDetails table {
  margin-bottom: 2rem;
  margin-top: 1.5rem;
}
#portal_content #tabCustomerDetail table tr:first-child,
#portal_content #tabProviderDetail table tr:first-child,
#portal_content #tabDetails table tr:first-child {
  margin-top: 0;
}
#portal_content #tabCustomerDetail table:before,
#portal_content #tabCustomerDetail table tbody:before,
#portal_content #tabProviderDetail table:before,
#portal_content #tabProviderDetail table tbody:before,
#portal_content #tabDetails table:before,
#portal_content #tabDetails table tbody:before {
  display: none;
}
@media only screen and (min-width: 1600px) {
  #portal_content #tabCustomerDetail #sites-dashboard .tiles {
    grid-template-columns: repeat(3, auto);
  }
}
@media only screen and (max-width: 1023px) {
  #portal_content #tabOrders table thead,
#portal_content #tabSites table thead,
#portal_content #tabAccounts table thead,
#portal_content #tabZones table thead,
#portal_content #tabProducts table thead,
#portal_content #tabDelivery table thead,
#portal_content #tabPickup table thead,
#portal_content #tabHistory table thead,
#portal_content #tabItemisedOrder table thead {
    display: none;
  }
}
#portal_content #tabOrders table tr:first-child,
#portal_content #tabSites table tr:first-child,
#portal_content #tabAccounts table tr:first-child,
#portal_content #tabZones table tr:first-child,
#portal_content #tabProducts table tr:first-child,
#portal_content #tabDelivery table tr:first-child,
#portal_content #tabPickup table tr:first-child,
#portal_content #tabHistory table tr:first-child,
#portal_content #tabItemisedOrder table tr:first-child {
  margin-top: 0;
}
#portal_content #tabOrders table:before,
#portal_content #tabOrders table tbody:before,
#portal_content #tabSites table:before,
#portal_content #tabSites table tbody:before,
#portal_content #tabAccounts table:before,
#portal_content #tabAccounts table tbody:before,
#portal_content #tabZones table:before,
#portal_content #tabZones table tbody:before,
#portal_content #tabProducts table:before,
#portal_content #tabProducts table tbody:before,
#portal_content #tabDelivery table:before,
#portal_content #tabDelivery table tbody:before,
#portal_content #tabPickup table:before,
#portal_content #tabPickup table tbody:before,
#portal_content #tabHistory table:before,
#portal_content #tabHistory table tbody:before,
#portal_content #tabItemisedOrder table:before,
#portal_content #tabItemisedOrder table tbody:before {
  display: none;
}
#portal_content #tabDocuments h3 {
  margin: 0;
}

.steps {
  border-top: 1px solid #f1f2f2;
  margin: 5rem -2rem 3rem;
}
@media only screen and (max-width: 639px) {
  .steps {
    margin: 1rem -1rem 2rem;
  }
}
.steps #wrap {
  display: flex;
  flex-flow: row wrap;
  max-width: 1020px;
  margin: 0 auto;
  padding: 0 1rem;
}
.steps #wrap > * {
  width: calc(25% - 1.25rem);
  margin-right: 0.625rem;
  margin-left: 0.625rem;
}
.steps #wrap * {
  color: #e0e0e0;
  font-weight: 600;
  height: 45px;
  position: relative;
  text-align: center;
}
@media only screen and (max-width: 1023px) {
  .steps #wrap * {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 639px) {
  .steps #wrap * {
    font-size: 0.7rem;
    font-weight: 500;
    line-height: 1;
    width: calc(25%);
    margin: 0;
    padding: 0 0.3rem;
  }
}
.steps #wrap *:before {
  background: #f1f2f2;
  content: "";
  display: block;
  width: 11px;
  border-radius: 10px;
  height: 11px;
  margin: -6px auto 15px;
}
.steps #wrap *:after {
  background: url(../img/icon-tick-ring.svg);
  content: "";
  display: block;
  height: 36px;
  left: 50%;
  margin: 0 0 0 -18px;
  opacity: 0.5;
  position: absolute;
  top: -62px;
  width: 36px;
}
@media only screen and (max-width: 639px) {
  .steps #wrap *:after {
    display: none;
  }
}
.steps #wrap .active {
  color: #006949;
}
.steps #wrap .active:before {
  background: #0d8458;
}
.steps #wrap .active:after {
  opacity: 1;
}

.product-sm {
  border-radius: 5px;
  transition: 0.2s all ease-out;
  -webkit-backface-visibility: hidden;
  background: #0d8458;
  box-shadow: 0 1.1em 1.5em -1em rgba(0, 0, 0, 0);
  color: #fff;
  display: flex;
  filter: blur(0);
  flex-flow: column;
  overflow: hidden;
  padding: 2rem 1rem;
  position: relative;
  text-align: center;
}
@media only screen and (min-width: 1600px) {
  .product-sm {
    border-radius: 8px;
  }
}
.product-sm:hover {
  box-shadow: 0 1.1em 1.5em -1em rgba(0, 0, 0, 0.5);
}
.product-sm span {
  margin: auto;
  width: 100%;
}
.product-sm .product-size {
  display: block;
  font-size: 1.8rem;
  font-weight: 800;
  text-transform: none;
}
@media only screen and (max-width: 1023px) {
  .product-sm .product-size {
    font-size: 2rem;
  }
}
.product-sm .product-amount {
  display: block;
  font-size: 1.65rem;
  font-weight: 200;
  line-height: 2rem;
  margin: 0.5rem 0 1.5rem;
  text-transform: capitalize;
}
@media only screen and (max-width: 1023px) {
  .product-sm .product-amount {
    font-size: 1.3rem;
    line-height: 1.7rem;
  }
}
.product-sm .product-description {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.2rem;
  text-transform: none;
  display: block;
}
.product-sm .product-price {
  display: block;
  margin: 0.3rem 0 0.4rem;
}
.product-sm .accordion {
  background: none;
  margin: 0;
}
.product-sm .accordion .accordion-title {
  border: none;
  max-width: 180px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  text-decoration: none;
  padding: 16px;
}
@media only screen and (min-width: 1600px) {
  .product-sm .accordion .accordion-title {
    border-radius: 8px;
  }
}
.product-sm .accordion .accordion-title:hover, .product-sm .accordion .accordion-title:focus {
  background: rgba(0, 0, 0, 0.25);
}
.product-sm .accordion .accordion-content {
  background: none;
  border: none;
  color: #fff;
  padding-bottom: 0;
}
.product-sm .product-select {
  border-radius: 5px;
  transition: 0.2s all ease-out;
  background: #fff;
  color: #006949;
  display: block;
  font-weight: 800;
  padding: 0.2rem 1rem;
  text-transform: uppercase;
  box-shadow: 0 1.1em 1.5em -1em rgba(0, 0, 0, 0);
  font-size: 0.98rem;
  letter-spacing: 0.03rem;
  line-height: 2.3rem;
  margin: 0 auto;
  max-width: 10rem;
  cursor: pointer;
}
@media only screen and (min-width: 1600px) {
  .product-sm .product-select {
    border-radius: 8px;
  }
}
.product-sm a,
.product-sm a:hover,
.product-sm a:focus {
  color: #fff;
  display: block;
  font-weight: 800;
  margin-top: 1.5rem;
  margin: 1rem auto 0;
  max-width: 10rem;
  text-decoration: underline;
}
@media only screen and (max-width: 639px) {
  .product-sm a,
.product-sm a:hover,
.product-sm a:focus {
    font-size: 0.8rem;
  }
}
.product-sm input {
  position: absolute;
  bottom: 0;
  opacity: 0;
}

.product-sm.on {
  background: #009265;
  transform: translateY(-10px);
  box-shadow: 0 1.1em 1.5em -1em rgba(0, 0, 0, 0.5);
}
.product-sm.on .product-select:after {
  opacity: 1;
}

body.index main {
  display: flex;
  background-color: #000;
  flex-direction: row;
  text-align: center;
  width: 100%;
  position: relative;
  overflow: hidden;
}
body.index main:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(../img/wastesure-bg-home.jpg);
  background-size: cover;
  background-position: center center;
  opacity: 0.7;
  z-index: 0;
  transform: scale(1.15);
  animation-name: ken-burns;
  animation-duration: 4s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
@keyframes ken-burns {
  0% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
body.index main #content {
  align-self: center;
  width: 100%;
  position: relative;
}
body.index main h1 {
  margin: 0 auto 1rem;
  max-width: 980px;
  color: #fff;
  font-weight: 600;
}
@media only screen and (max-width: 639px) {
  body.index main h1 {
    max-width: 300px;
  }
}
body.index main p.home-subheading {
  font-size: 1.3rem;
  margin: 0 auto 1.5rem;
  max-width: 590px;
  opacity: 1;
  color: #fff;
}
@media only screen and (max-width: 767px) {
  body.index main p.home-subheading {
    margin: 3rem auto 3.5rem;
  }
}
@media only screen and (max-width: 767px) {
  body.index main p.home-subheading {
    font-size: 0.9rem;
    margin: 1rem auto 1rem;
  }
}

.video-trigger {
  transition: 0.2s all ease-out;
  background: #fff;
  border-radius: 100%;
  box-shadow: 0 0 0 0 #fff;
  cursor: pointer;
  height: 9rem;
  margin: 0 auto 4rem;
  position: relative;
  width: 9rem;
  transform-origin: center center;
}
@media only screen and (max-width: 639px) {
  .video-trigger {
    transform: scale(0.5);
    margin-bottom: 0.3rem;
    margin-top: -1rem;
  }
}
.video-trigger:hover, .video-trigger:focus {
  box-shadow: 0 0 0 0.5rem #fff;
}
.video-trigger:hover svg, .video-trigger:focus svg {
  fill: #006949;
}
.video-trigger svg {
  transition: 0.2s all ease-out;
  fill: transparent;
  height: 4rem;
  left: 50%;
  margin: -2rem 0 0 -1.5rem;
  position: absolute;
  stroke: #006949;
  top: 50%;
  transform-origin: center center;
  width: 4rem;
}

.video-trigger.open {
  transition: 1s all cubic-bezier(0.85, -0.5, 0.03, 0.99);
  box-shadow: 0 0 0 5rem rgba(255, 255, 255, 0);
}
.video-trigger.open:hover, .video-trigger.open:focus {
  box-shadow: 0 0 0 5rem rgba(255, 255, 255, 0);
}
@media only screen and (max-width: 1023px) {
  .video-trigger.open {
    transform: scale(2);
  }
}
@media only screen and (max-width: 639px) {
  .video-trigger.open {
    transform: scale(1);
  }
}
.video-trigger.open svg {
  opacity: 0;
  transition: 1s all cubic-bezier(0.85, -0.5, 0.03, 0.99);
}
.video-trigger.open .video-container {
  transform: scale(1);
  opacity: 1;
}

.video-container {
  position: absolute;
  top: 10%;
  left: 15%;
  bottom: 10%;
  right: 15%;
  transform: scale(0) translateY(-60%);
  transition: 1s 0.05s all cubic-bezier(0.85, -0.5, 0.03, 0.99);
  background: #fff;
  opacity: 0;
  border-radius: 100%;
  overflow: hidden;
  z-index: 1;
  box-shadow: 0 0 0px 5px rgba(255, 255, 255, 0.9), 0 0 35px 10px rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 1023px) {
  .video-container {
    left: 5%;
    right: 5%;
    transform: scale(0) translateY(-100%);
  }
}
@media only screen and (max-width: 767px) {
  .video-container {
    bottom: 30%;
    left: 10%;
    right: 10%;
    top: 30%;
    transform: scale(0) translateY(-120%);
  }
}
@media only screen and (max-width: 639px) {
  .video-container {
    bottom: 36.4%;
    left: 4%;
    right: 4%;
    top: 36.5%;
    transform: scale(0) translateY(-180%);
  }
}
@media (max-width: 375px) {
  .video-container {
    max-height: 194px;
  }
}
.video-container .close {
  transition: 0.2s all ease-out;
  background: #fff;
  border-radius: 100%;
  border: 10px solid #fff;
  cursor: pointer;
  height: 40px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 40px;
  z-index: 2;
}
.video-container .close:before, .video-container .close:after {
  background: #0d8458;
  content: "";
  display: block;
  height: 100%;
  left: 50%;
  margin-left: -1px;
  position: absolute;
  top: 0;
  transform: rotate(45deg);
  width: 2px;
}
.video-container .close:after {
  transform: rotate(-45deg);
}
.video-container .close:hover {
  border-width: 12px;
}
.video-container iframe {
  display: none;
}

.video-container.open {
  transform: scale(1) translateY(0%);
  opacity: 1;
  border-radius: 15px;
}
.video-container.open iframe {
  display: block;
}

.video-container.close {
  transform: scale(0.7) translateY(-10%);
  opacity: 0;
  transition: 0.2s all ease-out;
}
.video-container.close iframe {
  opacity: 0;
}

body.login main, body.passwordreset main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
body.login #content, body.passwordreset #content {
  align-self: center;
  width: 100%;
  max-width: 500px;
}
body.login #content .password-reset, body.passwordreset #content .password-reset {
  padding-top: 2rem;
}
body.login #content .password-reset a, body.passwordreset #content .password-reset a {
  color: #f6f7f8;
  text-transform: capitalize;
}

#splash {
  background-color: #006949;
  bottom: 0;
  display: flex;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
  overflow: hidden;
}
#splash svg {
  height: auto;
  margin: auto;
  width: 30%;
  overflow: visible;
}
@media (max-width: 1300px) {
  #splash svg {
    width: 40%;
  }
}
@media (max-width: 1000px) {
  #splash svg {
    width: 60%;
  }
}
@media only screen and (max-width: 639px) {
  #splash svg {
    width: 83%;
  }
}
#splash svg polygon#w,
#splash svg g#waste,
#splash svg g#sure,
#splash svg g#slogan {
  opacity: 0;
}

/*************************************************************************** 
	GLOBAL FORM STYLES
*/
form {
  border-radius: 5px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  padding: 2rem;
  text-align: center;
}
@media only screen and (min-width: 1600px) {
  form {
    border-radius: 8px;
  }
}
@media only screen and (max-width: 1023px) {
  form {
    padding: 2rem 0;
  }
}

.help {
  margin: 4rem auto 5.5rem auto;
  max-width: 470px;
}
.help p {
  color: #006949;
}
.help strong {
  font-weight: 800;
  text-transform: uppercase;
}

p#help_text {
  text-align: center;
  margin: 1.6rem 0 0;
  font-size: 0.75rem;
}

input[type],
textarea {
  border-radius: 0.2rem;
  border: 1px solid #fff;
  box-shadow: none;
  color: #006949;
  font-weight: 800;
  height: 42px;
  text-align: center;
  text-transform: uppercase;
  transition: none;
}
@media only screen and (max-width: 639px) {
  input[type],
textarea {
    height: 30px;
    font-size: 0.8rem;
  }
}

input[type]:focus {
  outline: none;
  box-shadow: none;
  border-color: transparent;
}

input[type=checkbox] {
  margin: 0;
  float: right;
  opacity: 0;
}
input[type=checkbox] + span:before, input[type=checkbox] + span:after {
  background: #fff;
  content: "";
  display: block;
  height: 1.4rem;
  width: 1.4rem;
  opacity: 1;
  position: absolute;
  top: 18px;
  right: 1.4rem;
  border-radius: 1rem;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  transition: 0.2s all ease-in-out;
}
input[type=checkbox] + span:before {
  transform: scale(1);
}
input[type=checkbox] + span:after {
  background: url(../img/icon-tick.svg);
  opacity: 0;
  transform: translateY(30px);
}

input[type=checkbox]:checked + span:before {
  transform: scale(1.2);
}
input[type=checkbox]:checked + span:after {
  transform: translateY(0);
  opacity: 1;
}

input[type=date] {
  margin: 0;
}
@media only screen and (max-width: 639px) {
  input[type=date] {
    padding: 0 1rem;
    line-height: 1.5rem;
  }
}

input[type=time] {
  margin: 0;
  padding-left: 2rem;
}

input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

label,
legend {
  border-radius: 5px;
  color: #fff;
  font-weight: 800;
  font-size: 1rem;
  line-height: 2.5rem;
  text-transform: uppercase;
  background: #0d8458;
  padding: 0.5rem 1.6rem;
  margin-bottom: 1rem;
  text-align: center;
}
@media only screen and (min-width: 1600px) {
  label,
legend {
    border-radius: 8px;
  }
}
label button[type=button],
legend button[type=button] {
  border-radius: 0.2rem;
}

label {
  position: relative;
}

label.on {
  background: #f7a400;
}

select {
  border-radius: 5px;
  border-color: #fff;
}
@media only screen and (min-width: 1600px) {
  select {
    border-radius: 8px;
  }
}
select:focus, select:hover {
  outline: none;
  box-shadow: none;
  border-color: transparent;
}

textarea {
  resize: vertical;
  min-height: 8rem;
}

.textfield {
  position: relative;
}
.textfield span {
  position: absolute;
  top: 0;
  z-index: -1;
}

input[readonly]:hover, input[readonly]:focus {
  transform: none;
}

/*************************************************************************** 
	BUTTONS
*/
button[type] {
  display: inline-block;
  vertical-align: middle;
  margin: 1rem;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #f7a400;
  color: #fff;
  border-radius: 5px;
  transition: 0.2s all ease-out;
  font-weight: 800;
  margin: 0;
  padding: 1.35rem 1.7rem;
  text-transform: uppercase;
  font-size: 1rem;
  border: none;
}
[data-whatinput=mouse] button[type] {
  outline: 0;
}
button[type]:hover, button[type]:focus {
  background-color: #ffb421;
  color: #fff;
}
@media only screen and (min-width: 1600px) {
  button[type] {
    border-radius: 8px;
  }
}
@media only screen and (max-width: 639px) {
  button[type] {
    font-size: 0.8rem;
    padding: 1rem;
    line-height: 1rem;
  }
}

.button {
  border-radius: 5px;
  transition: 0.2s all ease-out;
  font-weight: 800;
  padding: 1.35rem 1.7rem;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.2;
}
@media only screen and (min-width: 1600px) {
  .button {
    border-radius: 8px;
  }
}
.button:hover i.fa-chevron-left {
  transform: translateX(-5px);
}
.button i {
  transition: 0.2s all ease-out;
  vertical-align: middle;
  font-size: 1.5rem;
  line-height: 0.8rem;
  padding: 0 0.25rem 0 0;
  vertical-align: text-top;
}
@media only screen and (max-width: 639px) {
  .button {
    font-size: 0.8rem;
    padding: 1rem;
    line-height: 1rem;
  }
}

.button.primary {
  background: #0d8458;
}
.button.primary:hover, .button.primary:focus {
  background: #f7a400;
}

.button.secondary {
  background: #333;
}
.button.secondary:hover, .button.secondary:focus {
  background: #f7a400;
}

.button.highlight {
  background: #f7a400;
}
.button.highlight:hover, .button.highlight:focus {
  background: #006949;
}

/*************************************************************************** 
	POSTCODE FINDER
*/
.postcode-container {
  display: flex;
  padding: 0;
  background: transparent;
}
.postcode-container button,
.postcode-container label {
  width: calc(50% - 0.5rem);
}
.postcode-container label {
  margin-right: 0.5rem;
  margin-bottom: 0;
}
.postcode-container button {
  margin-left: 0.5rem;
  height: 42px;
  padding: 0.15rem 0 0;
  background: #006949;
  color: #fff;
}
@media only screen and (max-width: 639px) {
  .postcode-container button {
    height: 30px;
  }
}
.postcode-container button:hover, .postcode-container button:focus {
  background: #f7a400;
}

body.login h2, body.passwordreset h2 {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -ms-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
body.login form, body.passwordreset form {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -ms-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: none;
  -moz-animation-fill-mode: none;
  -ms-animation-fill-mode: none;
  -o-animation-fill-mode: none;
  animation-fill-mode: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.1s ease-in-out;
  background: #0d8458;
  margin-top: 2rem;
  padding: 2rem;
}
body.login form:focus-within, body.passwordreset form:focus-within, body.login form:hover, body.passwordreset form:hover {
  box-shadow: 0 0.2em 2.5em rgba(0, 0, 0, 0.1);
  transform: scale(1.025);
}
@media screen and (prefers-reduced-motion: reduce) {
  body.login form, body.passwordreset form {
    box-shadow: none;
    transform: none;
  }
}
@media only screen and (max-width: 639px) {
  body.login form, body.passwordreset form {
    padding: 2rem 1rem;
  }
}
body.login form label, body.passwordreset form label {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
body.login form button[type], body.passwordreset form button[type] {
  padding: 1rem 1.5rem;
  margin-top: 1rem;
}
body.login form button[type]:hover, body.passwordreset form button[type]:hover, body.login form button[type]:focus, body.passwordreset form button[type]:focus {
  background: #fff;
  color: #333;
}

body.placedbooking section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}
body.placedbooking section p {
  text-align: center;
}
body.placedbooking svg#svg_skip {
  width: 420px;
  height: 200px;
  margin: 0 auto;
  display: block;
  overflow: visible;
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 639px) {
  body.placedbooking svg#svg_skip {
    width: 80%;
    height: auto;
  }
}
body.placedbooking svg#svg_skip .skip,
body.placedbooking svg#svg_skip .logo,
body.placedbooking svg#svg_skip .shadow {
  opacity: 0;
}

body.formstepproductselect.domesticcustomer section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}
body.formstepproductselect.domesticcustomer form {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 100rem;
  display: flex;
  flex-flow: row wrap;
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -ms-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  margin-top: 3rem;
}
body.formstepproductselect.domesticcustomer form > .product-sm {
  width: calc(25% - 2.45rem);
  margin-right: 1.225rem;
  margin-left: 1.225rem;
}
body.formstepproductselect.domesticcustomer form > #help_text {
  width: calc(100% - 1.25rem);
  margin-right: 0.625rem;
  margin-left: 0.625rem;
}
body.formstepproductselect.domesticcustomer form > button {
  width: calc(100% - 1.25rem);
  margin-right: 0.625rem;
  margin-left: 0.625rem;
}
@media only screen and (max-width: 1199px) {
  body.formstepproductselect.domesticcustomer form > .product-sm {
    width: calc(33.3333333333% - 1rem);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}
@media only screen and (max-width: 767px) {
  body.formstepproductselect.domesticcustomer form > .product-sm {
    width: calc(50% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem;
  }
}
@media only screen and (max-width: 639px) {
  body.formstepproductselect.domesticcustomer form > .product-sm {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem;
  }
}
body.formstepproductselect.domesticcustomer form .product-sm {
  margin-bottom: 1.25rem;
  overflow: hidden;
  cursor: pointer;
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -ms-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: none;
  -moz-animation-fill-mode: none;
  -ms-animation-fill-mode: none;
  -o-animation-fill-mode: none;
  animation-fill-mode: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
body.formstepproductselect.domesticcustomer form .product-sm img {
  height: 150px;
}
body.formstepproductselect.domesticcustomer form .product-sm .product-image {
  position: relative;
  background: #fff;
  padding: 0 0.7rem;
  border-radius: 5px;
  margin: 0 auto 1rem;
  overflow: hidden;
}
@media only screen and (min-width: 1600px) {
  body.formstepproductselect.domesticcustomer form .product-sm .product-image {
    border-radius: 8px;
  }
}
body.formstepproductselect.domesticcustomer form .product-sm .product-image:after {
  transition: 0.2s all ease-out;
  background-image: url(../img/icon-tick.svg);
  background-repeat: no-repeat;
  background-position: center 4px;
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transform: scale(1.5);
}
@media only screen and (max-width: 639px) {
  body.formstepproductselect.domesticcustomer form .product-sm {
    padding: 1.5rem;
  }
}
body.formstepproductselect.domesticcustomer form .product-sm.on .product-image img {
  opacity: 0.5;
  filter: blur(12px);
}
body.formstepproductselect.domesticcustomer form .product-sm.on .product-image:after {
  opacity: 1;
  transform: scale(1.1);
}
body.formstepproductselect.domesticcustomer form button {
  max-width: 32rem;
  margin: 0 auto;
}
body.formstepproductselect.domesticcustomer .container {
  margin-top: 2rem;
}

@media only screen and (min-width: 768px) {
  body.formstepproductselect.commercialcustomer h2 {
    margin-top: 3rem;
  }
}
body.formstepproductselect.commercialcustomer h3,
body.formstepproductselect.commercialcustomer h4 {
  text-align: left;
}
body.formstepproductselect.commercialcustomer h4 {
  font-weight: 800;
  line-height: 1.2;
}
body.formstepproductselect.commercialcustomer h4 span {
  color: #f7a400;
}
body.formstepproductselect.commercialcustomer .popular-products h4 {
  margin-bottom: 1rem;
}
body.formstepproductselect.commercialcustomer .grid-half {
  display: grid;
  grid-gap: 2.5rem;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 2rem;
}
@media only screen and (max-width: 1200px) {
  body.formstepproductselect.commercialcustomer .grid-half {
    column-gap: 1.5rem;
  }
}
@media only screen and (max-width: 470px) {
  body.formstepproductselect.commercialcustomer .grid-half {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media only screen and (min-width: 1300px) {
  body.formstepproductselect.commercialcustomer .grid-half .product-sm {
    padding: 1rem;
  }
  body.formstepproductselect.commercialcustomer .grid-half .product-sm .container {
    display: flex;
  }
  body.formstepproductselect.commercialcustomer .grid-half .product-sm .container .product-size {
    padding: 0 0.5rem;
  }
  body.formstepproductselect.commercialcustomer .grid-half .product-sm .container .product-select {
    margin: auto 1rem;
  }
  body.formstepproductselect.commercialcustomer .grid-half .product-sm .container .product-image {
    margin: 0;
  }
}
body.formstepproductselect.commercialcustomer .grid {
  display: grid;
  grid-gap: 2.5rem;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 2rem;
}
@media only screen and (max-width: 1400px) {
  body.formstepproductselect.commercialcustomer .grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (max-width: 1200px) {
  body.formstepproductselect.commercialcustomer .grid {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.5rem;
  }
}
@media only screen and (max-width: 800px) {
  body.formstepproductselect.commercialcustomer .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 470px) {
  body.formstepproductselect.commercialcustomer .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
body.formstepproductselect.commercialcustomer .grid h4 {
  grid-column: span 5;
}
@media only screen and (max-width: 1400px) {
  body.formstepproductselect.commercialcustomer .grid h4 {
    grid-column: span 4;
  }
}
@media only screen and (max-width: 1200px) {
  body.formstepproductselect.commercialcustomer .grid h4 {
    grid-column: span 3;
  }
}
@media only screen and (max-width: 800px) {
  body.formstepproductselect.commercialcustomer .grid h4 {
    grid-column: span 2;
  }
}
@media only screen and (max-width: 470px) {
  body.formstepproductselect.commercialcustomer .grid h4 {
    grid-column: span 1;
  }
}
body.formstepproductselect.commercialcustomer .grid h4 span {
  color: #f7a400;
}
body.formstepproductselect.commercialcustomer .grid.popular {
  grid-gap: 1rem;
  display: block;
}
body.formstepproductselect.commercialcustomer .grid.previous {
  grid-column: span 3;
  margin-bottom: 4rem;
}
@media only screen and (max-width: 800px) {
  body.formstepproductselect.commercialcustomer .grid.previous {
    grid-column: span 2;
  }
}
@media only screen and (max-width: 470px) {
  body.formstepproductselect.commercialcustomer .grid.previous {
    grid-column: span 1;
  }
}
body.formstepproductselect.commercialcustomer .grid.previous h4 {
  margin-bottom: -1.5rem;
  margin-top: 2rem;
}
body.formstepproductselect.commercialcustomer .all .grid {
  margin-bottom: 4rem;
}
body.formstepproductselect.commercialcustomer .product-size {
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
body.formstepproductselect.commercialcustomer .product-sm {
  margin-bottom: 0;
  overflow: hidden;
  cursor: pointer;
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -ms-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: none;
  -moz-animation-fill-mode: none;
  -ms-animation-fill-mode: none;
  -o-animation-fill-mode: none;
  animation-fill-mode: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
body.formstepproductselect.commercialcustomer .product-sm img {
  height: 150px;
}
body.formstepproductselect.commercialcustomer .product-sm .product-image {
  position: relative;
  background: #fff;
  padding: 0 0.7rem;
  border-radius: 5px;
  margin: 0 auto 1rem;
  overflow: hidden;
  display: block;
}
@media only screen and (min-width: 1600px) {
  body.formstepproductselect.commercialcustomer .product-sm .product-image {
    border-radius: 8px;
  }
}
body.formstepproductselect.commercialcustomer .product-sm .product-image:after {
  transition: 0.2s all ease-out;
  background-image: url(../img/icon-tick.svg);
  background-repeat: no-repeat;
  background-position: center 4px;
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transform: scale(1.5);
}
@media only screen and (max-width: 639px) {
  body.formstepproductselect.commercialcustomer .product-sm {
    padding: 1.5rem;
  }
}
body.formstepproductselect.commercialcustomer .product-sm.on .product-image img {
  opacity: 0.5;
  filter: blur(12px);
}
body.formstepproductselect.commercialcustomer .product-sm.on .product-image:after {
  opacity: 1;
  transform: scale(1.1);
}
body.formstepproductselect.commercialcustomer button {
  max-width: 32rem;
  margin: 0 auto 2rem;
}

/*************************************************************************** 
	WASTE AMOUNT
*/
body.formstepwasteamountselect .container {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 100rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}
body.formstepwasteamountselect section {
  display: flex;
  flex-flow: row wrap;
  margin-top: 3rem;
  /* Fancy Skip */
  /* Skip amount chart */
}
body.formstepwasteamountselect section > .left, body.formstepwasteamountselect section .right {
  width: calc(25% - 2.45rem);
  margin-right: 1.225rem;
  margin-left: 1.225rem;
}
@media only screen and (max-width: 1199px) {
  body.formstepwasteamountselect section > .left, body.formstepwasteamountselect section .right {
    width: calc(100% - 1rem);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}
@media only screen and (max-width: 1023px) {
  body.formstepwasteamountselect section > .left, body.formstepwasteamountselect section .right {
    width: calc(100% - 1rem);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}
body.formstepwasteamountselect section .left {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -ms-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
@media only screen and (max-width: 1199px) {
  body.formstepwasteamountselect section .left {
    order: 2;
    margin-top: 1rem;
    width: 19rem;
  }
}
@media only screen and (max-width: 767px) {
  body.formstepwasteamountselect section .left {
    display: none;
  }
}
body.formstepwasteamountselect section .left .product-description {
  padding: 0.8rem;
}
body.formstepwasteamountselect section .left .product-price {
  font-weight: 800;
  color: #006949;
  padding: 0.5rem;
  background: #fff;
  border-radius: 5px;
}
@media only screen and (min-width: 1600px) {
  body.formstepwasteamountselect section .left .product-price {
    border-radius: 8px;
  }
}
body.formstepwasteamountselect section .left .button {
  display: block;
  margin: 1rem 0 0;
  background: #006949;
}
body.formstepwasteamountselect section .left .button:hover {
  opacity: 0.85;
}
@media only screen and (max-width: 1199px) {
  body.formstepwasteamountselect section .left .button {
    width: 19rem;
  }
}
@media only screen and (max-width: 767px) {
  body.formstepwasteamountselect section .left .button {
    padding: 1rem;
  }
}
@media only screen and (max-width: 639px) {
  body.formstepwasteamountselect section .left .button {
    width: 100%;
  }
}
body.formstepwasteamountselect section .right {
  border-radius: 5px;
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  flex-grow: 1;
  padding: 3rem 3rem 4rem;
  background: #0d8458;
  text-align: center;
}
@media only screen and (min-width: 1600px) {
  body.formstepwasteamountselect section .right {
    border-radius: 8px;
  }
}
@media only screen and (max-width: 1199px) {
  body.formstepwasteamountselect section .right {
    padding: 1.5rem 2.5rem 2.5rem;
  }
}
@media only screen and (max-width: 639px) {
  body.formstepwasteamountselect section .right {
    padding: 1.5rem;
  }
}
body.formstepwasteamountselect section .right .form-container {
  display: flex;
}
@media only screen and (max-width: 1023px) {
  body.formstepwasteamountselect section .right .form-container {
    flex-flow: wrap;
    justify-content: space-between;
  }
}
@media (max-width: 400px) {
  body.formstepwasteamountselect section .right .form-container {
    justify-content: flex-start;
  }
}
body.formstepwasteamountselect section .right h2 {
  background: transparent;
  border-radius: 0;
  color: #fff;
  float: left;
  font-size: 1.7rem;
  line-height: 1.2;
  margin-top: 0.5rem;
  text-transform: none;
  width: 100%;
  font-weight: 800;
}
@media only screen and (max-width: 639px) {
  body.formstepwasteamountselect section .right h2 {
    margin-bottom: 2rem;
  }
}
body.formstepwasteamountselect section .right form {
  padding: 0;
  width: 100%;
  margin: auto 0;
}
body.formstepwasteamountselect section .right p {
  clear: both;
  color: #fff;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 639px) {
  body.formstepwasteamountselect section .right p {
    font-size: 0.9rem;
    line-height: 1.25;
  }
}
body.formstepwasteamountselect section .right label {
  padding: 0;
  margin: 0;
  text-align: left;
  text-transform: capitalize;
  font-weight: 500;
  background: transparent;
}
body.formstepwasteamountselect section .right label .material-name {
  font-size: 14px;
  line-height: 30px;
  vertical-align: top;
  padding-left: 10px;
  display: inline-block;
}
@media only screen and (max-width: 639px) {
  body.formstepwasteamountselect section .right label .material-name {
    font-size: 0.9rem;
    padding-left: 5px;
    line-height: 21px;
    font-size: 0.7rem;
  }
}
@media (max-width: 400px) {
  body.formstepwasteamountselect section .right label .material-name {
    padding-left: 0;
  }
}
body.formstepwasteamountselect section .right label .material-colour {
  width: 22px;
  height: 22px;
  background: #fff;
  display: inline-block;
  border: 1px solid #fff;
}
@media (max-width: 400px) {
  body.formstepwasteamountselect section .right label .material-colour {
    display: none;
  }
}
body.formstepwasteamountselect section .right label:nth-child(1n) .material-colour {
  background: #4e827c;
}
body.formstepwasteamountselect section .right label:nth-child(2n) .material-colour {
  background: #89bbb5;
}
body.formstepwasteamountselect section .right label:nth-child(3n) .material-colour {
  background: #b32c21;
}
body.formstepwasteamountselect section .right label:nth-child(4n) .material-colour {
  background: #ffffff;
}
body.formstepwasteamountselect section .right label:nth-child(5n) .material-colour {
  background: #a3d2fb;
}
body.formstepwasteamountselect section .right label:nth-child(6n) .material-colour {
  background: #dec595;
}
body.formstepwasteamountselect section .right label:nth-child(7n) .material-colour {
  background: #231f20;
}
body.formstepwasteamountselect section .right label .slider {
  float: right;
  width: calc(100% - 180px);
  margin: 0.9rem 0;
}
@media only screen and (max-width: 639px) {
  body.formstepwasteamountselect section .right label .slider {
    width: calc(100% - 40%);
    margin: 0.6rem 0;
  }
}
@media (max-width: 500px) {
  body.formstepwasteamountselect section .right label .slider {
    width: calc(100% - 50%);
    margin: 0.6rem 0;
  }
}
body.formstepwasteamountselect section #skip-container {
  position: relative;
  width: 100%;
  margin: auto 3rem;
  overflow: hidden;
}
@media (max-width: 1450px) {
  body.formstepwasteamountselect section #skip-container {
    width: 70%;
  }
}
@media only screen and (max-width: 1023px) {
  body.formstepwasteamountselect section #skip-container {
    margin-left: 0;
    margin-top: 2rem;
    margin-right: 0;
    width: 60%;
  }
}
@media (max-width: 400px) {
  body.formstepwasteamountselect section #skip-container {
    display: none;
  }
}
body.formstepwasteamountselect section #skip-container img.front {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}
body.formstepwasteamountselect section #skip-container #skipContents {
  bottom: 0;
  top: 0;
  left: 20%;
  right: 10%;
  left: 25px;
  height: 100%;
  position: absolute;
  transform-origin: top;
  transform: scaleY(0.98);
  width: 90%;
  z-index: 1;
}
@media only screen and (max-width: 639px) {
  body.formstepwasteamountselect section #skip-container #skipContents {
    left: 15px;
  }
}
body.formstepwasteamountselect section #skip-container .waste {
  background: #4e827c;
  bottom: 0;
  left: 0;
  position: absolute;
  transition: 0.5s height ease-in-out;
  width: 100%;
  z-index: 0;
  max-height: 100%;
}
body.formstepwasteamountselect section #skip-container .waste:nth-child(2n) {
  background: #89bbb5;
}
body.formstepwasteamountselect section #skip-container .waste:nth-child(3n) {
  background: #b32c21;
}
body.formstepwasteamountselect section #skip-container .waste:nth-child(4n) {
  background: #ffffff;
}
body.formstepwasteamountselect section #skip-container .waste:nth-child(5n) {
  background: #a3d2fb;
}
body.formstepwasteamountselect section #skip-container .waste:nth-child(6n) {
  background: #dec595;
}
body.formstepwasteamountselect section #skip-container .waste:nth-child(7n) {
  background: #231f20;
}
body.formstepwasteamountselect section #chart {
  color: #fff;
  position: relative;
  text-align: left;
  height: 125px;
  margin-top: auto;
  margin-bottom: auto;
}
@media only screen and (max-width: 1023px) {
  body.formstepwasteamountselect section #chart {
    margin-top: 2rem;
  }
}
@media (max-width: 400px) {
  body.formstepwasteamountselect section #chart {
    margin-bottom: 1rem;
    margin-right: 0;
  }
}
body.formstepwasteamountselect section #chart #bar {
  transition: 0.5s height ease-in-out;
  background: #fff;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 20px;
  max-height: 100%;
}
body.formstepwasteamountselect section #chart ul {
  margin: 0 0 0 30px;
  border-left: 2px solid #fff;
  list-style: none;
}
body.formstepwasteamountselect section #chart ul li {
  position: relative;
  padding-left: 20px;
}
body.formstepwasteamountselect section #chart ul li span {
  display: block;
}
body.formstepwasteamountselect section #chart ul li:before {
  background: #fff;
  bottom: 52%;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  position: absolute;
  width: 10px;
}
body.formstepwasteamountselect section #chart ul li:nth-child(1) {
  transform: translateY(-10px);
}
body.formstepwasteamountselect section #chart ul li:nth-child(2) {
  transform: translateY(-4px);
}
body.formstepwasteamountselect section #chart ul li:nth-child(3) {
  transform: translateY(2px);
}
body.formstepwasteamountselect section #chart ul li:nth-child(4) {
  transform: translateY(8px);
}
body.formstepwasteamountselect section #chart ul li:nth-child(5) {
  transform: translateY(13px);
}
body.formstepwasteamountselect section #waste_amount_footer {
  margin-left: calc(25% + 1.225rem);
  flex-grow: 1;
  text-align: right;
  padding: 1.225rem 1.225rem 0 0;
  margin-top: 1rem;
}
@media only screen and (max-width: 1199px) {
  body.formstepwasteamountselect section #waste_amount_footer {
    margin-left: calc(16.6666666667% + 0.5rem);
    padding-right: 0.5rem;
  }
}
@media only screen and (max-width: 1023px) {
  body.formstepwasteamountselect section #waste_amount_footer {
    margin-left: calc(33.3333333333% + 0.5rem);
  }
}
@media only screen and (max-width: 767px) {
  body.formstepwasteamountselect section #waste_amount_footer {
    width: calc(100% - 1.875rem);
    margin-right: 0.9375rem;
    margin-left: 0.9375rem;
  }
}
body.formstepwasteamountselect section #waste_amount_footer p {
  color: #006949;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 767px) {
  body.formstepwasteamountselect section #waste_amount_footer p {
    margin: 1rem 0 3rem;
  }
}
@media only screen and (max-width: 639px) {
  body.formstepwasteamountselect section #waste_amount_footer p {
    text-align: center;
  }
}
body.formstepwasteamountselect section #waste_amount_footer button {
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 639px) {
  body.formstepwasteamountselect section #waste_amount_footer button {
    width: 100%;
  }
}
body.formstepwasteamountselect .product-sm {
  padding: 2rem 1rem;
  background: #006949;
}
body.formstepwasteamountselect .product-sm:hover {
  transform: scale(1);
  box-shadow: none;
}
body.formstepwasteamountselect .product-sm a {
  margin: 1.5rem auto 0.5rem;
}

body.formstepwasteselect section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}
body.formstepwasteselect form {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 100rem;
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -ms-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
body.formstepwasteselect form fieldset {
  margin-bottom: 2rem;
}
body.formstepwasteselect form span {
  display: flex;
  flex-flow: row wrap;
}
body.formstepwasteselect form span > legend {
  width: calc(100% - 1.25rem);
  margin-right: 0.625rem;
  margin-left: 0.625rem;
}
body.formstepwasteselect form span > label {
  width: calc(16.6666666667% - 2.45rem);
  margin-right: 1.225rem;
  margin-left: 1.225rem;
}
@media only screen and (max-width: 1023px) {
  body.formstepwasteselect form span > label {
    width: calc(33.3333333333% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem;
  }
}
@media only screen and (max-width: 639px) {
  body.formstepwasteselect form span > label {
    width: calc(50% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem;
  }
}
body.formstepwasteselect form label {
  transition: 0.2s all ease-out;
  cursor: pointer;
  text-align: left;
  padding: 0;
  font-size: 1rem;
  line-height: 1.2rem;
  background: none;
  border-radius: 0;
  color: #006949;
  text-align: center;
  position: relative;
}
@media only screen and (max-width: 1023px) {
  body.formstepwasteselect form label {
    margin-bottom: 1.8rem;
  }
}
@media only screen and (max-width: 639px) {
  body.formstepwasteselect form label {
    font-size: 0.8rem;
  }
}
body.formstepwasteselect form label:hover, body.formstepwasteselect form label:focus-within {
  color: #f7a400;
}
body.formstepwasteselect form label:hover img,
body.formstepwasteselect form label:hover svg, body.formstepwasteselect form label:focus-within img,
body.formstepwasteselect form label:focus-within svg {
  transform: scale(1.05);
}
body.formstepwasteselect form label img {
  background-color: #f1f2f2;
  overflow: hidden;
  transition: 0.2s all ease-out;
  margin-bottom: 1.5rem;
}
body.formstepwasteselect form label svg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.2s all ease-out;
}
body.formstepwasteselect form label.on svg {
  opacity: 1;
  transform: scale(1);
  background: #f7a400;
}
body.formstepwasteselect form input[type=checkbox] {
  position: absolute;
}
body.formstepwasteselect form input[type=checkbox] + span {
  display: none;
}
body.formstepwasteselect form legend {
  background: none;
  color: #006949;
  margin-bottom: 2rem;
  line-height: 1.5;
}

body.formstepwhenwhere.domesticcustomer .steps {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -ms-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -ms-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
body.formstepwhenwhere.domesticcustomer #step_1 {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
body.formstepwhenwhere.domesticcustomer #step_2 {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -ms-animation-delay: 0.1s;
  -o-animation-delay: 0.1s;
  animation-delay: 0.1s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
body.formstepwhenwhere.domesticcustomer #step_3 {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -ms-animation-delay: 0.2s;
  -o-animation-delay: 0.2s;
  animation-delay: 0.2s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
body.formstepwhenwhere.domesticcustomer #step_4 {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -ms-animation-delay: 0.3s;
  -o-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
body.formstepwhenwhere.domesticcustomer #step_5 {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  -ms-animation-delay: 0.4s;
  -o-animation-delay: 0.4s;
  animation-delay: 0.4s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
body.formstepwhenwhere.domesticcustomer section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -ms-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -ms-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
body.formstepwhenwhere.domesticcustomer section h2 {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
body.formstepwhenwhere.domesticcustomer form {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
  max-width: 1030px;
  text-align: center;
}
body.formstepwhenwhere.domesticcustomer form > * {
  width: calc(50% - 1.25rem);
  margin-right: 0.625rem;
  margin-left: 0.625rem;
}
body.formstepwhenwhere.domesticcustomer form > p#help_text, body.formstepwhenwhere.domesticcustomer form button {
  width: calc(100% - 1.25rem);
  margin-right: 0.625rem;
  margin-left: 0.625rem;
}
@media only screen and (max-width: 1023px) {
  body.formstepwhenwhere.domesticcustomer form {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 639px) {
  body.formstepwhenwhere.domesticcustomer form > * {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem;
  }
}
body.formstepwhenwhere.domesticcustomer form div {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
body.formstepwhenwhere.domesticcustomer form p#help_text {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  -ms-animation-delay: 0.4s;
  -o-animation-delay: 0.4s;
  animation-delay: 0.4s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
body.formstepwhenwhere.domesticcustomer form label {
  transition: all 0.1s ease-in-out;
  background: #0d8458;
  padding: 0.5rem 1.6rem 0.6rem 0.5rem;
}
body.formstepwhenwhere.domesticcustomer form label:focus-within, body.formstepwhenwhere.domesticcustomer form label:hover {
  box-shadow: 0 0.2em 2.5em rgba(0, 0, 0, 0.1);
  transform: scale(1.025);
}
@media screen and (prefers-reduced-motion: reduce) {
  body.formstepwhenwhere.domesticcustomer form label {
    box-shadow: none;
    transform: none;
  }
}
@media only screen and (max-width: 1023px) {
  body.formstepwhenwhere.domesticcustomer form label {
    padding: 0.2rem 1.6rem 0.9rem;
  }
}
@media only screen and (max-width: 639px) {
  body.formstepwhenwhere.domesticcustomer form label {
    font-size: 0.8rem;
    padding: 0.5rem 1rem 1.2rem;
  }
}
body.formstepwhenwhere.domesticcustomer form label.date {
  padding: 1.7rem 1.6rem;
  line-height: 1.2;
}
@media only screen and (max-width: 639px) {
  body.formstepwhenwhere.domesticcustomer form label.date {
    padding: 1.3rem 1rem 1.2rem;
  }
}
body.formstepwhenwhere.domesticcustomer form label.date input {
  margin: 1rem 0 0;
}
body.formstepwhenwhere.domesticcustomer form label input {
  display: block;
  float: right;
  margin: 0 0 0 0.5rem;
  max-width: 200px;
}
@media only screen and (max-width: 1023px) {
  body.formstepwhenwhere.domesticcustomer form label input {
    float: none;
    margin: 0;
    max-width: 100%;
  }
}
body.formstepwhenwhere.domesticcustomer form label input#date {
  float: none;
  max-width: unset;
}
body.formstepwhenwhere.domesticcustomer form label button[type=button] {
  background: #fff;
  color: #006949;
  float: right;
  height: 42px;
  margin: 0;
  padding: 0;
  width: 135px;
}
@media only screen and (max-width: 1023px) {
  body.formstepwhenwhere.domesticcustomer form label button[type=button] {
    float: none;
    width: 100%;
  }
}
@media only screen and (max-width: 639px) {
  body.formstepwhenwhere.domesticcustomer form label button[type=button] {
    font-size: 0.8rem;
  }
}
body.formstepwhenwhere.domesticcustomer form fieldset {
  border-radius: 5px;
  transition: 0.2s all ease-out;
  background: #0d8458;
  margin: 0 0 1rem;
  padding: 0.5rem 1.6rem 0.5rem 0.5rem;
}
@media only screen and (min-width: 1600px) {
  body.formstepwhenwhere.domesticcustomer form fieldset {
    border-radius: 8px;
  }
}
body.formstepwhenwhere.domesticcustomer form fieldset:hover, body.formstepwhenwhere.domesticcustomer form fieldset:focus, body.formstepwhenwhere.domesticcustomer form fieldset:focus-within {
  background: #0f9b67;
}
body.formstepwhenwhere.domesticcustomer form fieldset:hover label:nth-child(2):before,
body.formstepwhenwhere.domesticcustomer form fieldset:hover label:nth-child(2):after, body.formstepwhenwhere.domesticcustomer form fieldset:focus label:nth-child(2):before,
body.formstepwhenwhere.domesticcustomer form fieldset:focus label:nth-child(2):after, body.formstepwhenwhere.domesticcustomer form fieldset:focus-within label:nth-child(2):before,
body.formstepwhenwhere.domesticcustomer form fieldset:focus-within label:nth-child(2):after {
  opacity: 0;
}
@media only screen and (max-width: 1023px) {
  body.formstepwhenwhere.domesticcustomer form fieldset {
    padding: 0.2rem 1.6rem 0.9rem;
  }
}
@media only screen and (max-width: 639px) {
  body.formstepwhenwhere.domesticcustomer form fieldset {
    padding: 0.5rem 1rem 1.2rem;
  }
}
body.formstepwhenwhere.domesticcustomer form fieldset legend {
  box-shadow: none;
  float: left;
  margin: 0;
  padding: 0;
  transform: scale(1);
  width: 40%;
  text-align: center;
  background: none;
}
@media only screen and (max-width: 1023px) {
  body.formstepwhenwhere.domesticcustomer form fieldset legend {
    width: 100%;
  }
}
@media only screen and (max-width: 639px) {
  body.formstepwhenwhere.domesticcustomer form fieldset legend {
    font-size: 0.8rem;
  }
}
body.formstepwhenwhere.domesticcustomer form fieldset span {
  float: right;
  width: 60%;
}
@media only screen and (max-width: 1023px) {
  body.formstepwhenwhere.domesticcustomer form fieldset span {
    float: none;
    width: 100%;
  }
}
body.formstepwhenwhere.domesticcustomer form fieldset label {
  clear: none;
  float: left;
  margin: 0;
  padding: 0;
  width: 33.33333%;
  position: relative;
}
body.formstepwhenwhere.domesticcustomer form fieldset label:focus-within, body.formstepwhenwhere.domesticcustomer form fieldset label:hover {
  box-shadow: 0 0.2em 2.5em rgba(0, 0, 0, 0);
  transform: scale(1);
}
body.formstepwhenwhere.domesticcustomer form fieldset label:nth-child(1) input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
body.formstepwhenwhere.domesticcustomer form fieldset label:nth-child(2) input {
  border-radius: 0;
  margin: 0;
}
body.formstepwhenwhere.domesticcustomer form fieldset label:nth-child(3) input {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin: 0;
}
body.formstepwhenwhere.domesticcustomer form fieldset label:nth-child(2):before, body.formstepwhenwhere.domesticcustomer form fieldset label:nth-child(2):after {
  background: #0d8458;
  bottom: 2px;
  content: "";
  display: block;
  height: 33px;
  left: 0;
  margin: 0 0;
  position: absolute;
  top: 5px;
  transform: rotate(15deg);
  transition: 0.1s all ease-out;
  width: 2px;
}
@media only screen and (max-width: 639px) {
  body.formstepwhenwhere.domesticcustomer form fieldset label:nth-child(2):before, body.formstepwhenwhere.domesticcustomer form fieldset label:nth-child(2):after {
    height: 20px;
    top: 5px;
  }
}
body.formstepwhenwhere.domesticcustomer form fieldset label:nth-child(2):after {
  left: inherit;
  right: 0;
  z-index: 1;
}
body.formstepwhenwhere.domesticcustomer form input[type=number] {
  padding: 0 0.5rem 0 1.2rem;
}
body.formstepwhenwhere.domesticcustomer form button[type=submit] {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -ms-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  margin: 1.5rem auto 0;
  width: auto;
}

@media only screen and (min-width: 768px) {
  body.formstepwhenwhere.commercialcustomer h2 {
    margin-top: 3rem;
  }
}
body.formstepwhenwhere.commercialcustomer form {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
  max-width: 1030px;
  text-align: center;
}
body.formstepwhenwhere.commercialcustomer form > .section {
  width: calc(50% - 1.25rem);
  margin-right: 0.625rem;
  margin-left: 0.625rem;
}
body.formstepwhenwhere.commercialcustomer form > button, body.formstepwhenwhere.commercialcustomer form .site {
  width: calc(100% - 1.25rem);
  margin-right: 0.625rem;
  margin-left: 0.625rem;
}
body.formstepwhenwhere.commercialcustomer form #site_create {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -ms-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -ms-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
body.formstepwhenwhere.commercialcustomer form #site_create .checkbox {
  cursor: pointer;
}
@media only screen and (min-width: 768px) {
  body.formstepwhenwhere.commercialcustomer form #site_create .checkbox {
    padding-right: 68px;
    line-height: 1.1;
    display: flex;
    align-items: center;
    text-align: left;
  }
}
body.formstepwhenwhere.commercialcustomer form #site_create .checkbox:hover {
  background-color: #006949;
}
@media only screen and (min-width: 768px) {
  body.formstepwhenwhere.commercialcustomer form #site_create .checkbox input {
    position: absolute;
  }
  body.formstepwhenwhere.commercialcustomer form #site_create .checkbox span:before {
    top: 50%;
    margin-top: -12px;
  }
  body.formstepwhenwhere.commercialcustomer form #site_create .checkbox span:after {
    top: 50%;
    margin-top: -12px;
  }
}
@media only screen and (min-width: 768px) {
  body.formstepwhenwhere.commercialcustomer form #site_create .postcode-fields {
    display: grid;
    grid-template-columns: 70% 1fr;
    grid-gap: 1rem;
  }
}
@media only screen and (max-width: 1023px) {
  body.formstepwhenwhere.commercialcustomer form {
    padding-left: 0;
    padding-right: 0;
  }
  body.formstepwhenwhere.commercialcustomer form > .section {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem;
  }
}
body.formstepwhenwhere.commercialcustomer form .section p {
  font-size: 0.75rem;
  text-align: left;
  color: #fff;
}
body.formstepwhenwhere.commercialcustomer form .section legend {
  border-radius: 0;
  color: #006949;
  line-height: 2.5rem;
  background: transparent;
  padding: 0;
  margin-bottom: 0;
  text-align: left;
}
body.formstepwhenwhere.commercialcustomer form .section .switch-label {
  text-align: center;
}
body.formstepwhenwhere.commercialcustomer form .section input {
  text-align: left;
}
body.formstepwhenwhere.commercialcustomer form .section label {
  text-align: left;
}
body.formstepwhenwhere.commercialcustomer form .section label.date input {
  margin-bottom: 1rem;
}
body.formstepwhenwhere.commercialcustomer form #site_create_new_addr .postcode-search {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
}
@media only screen and (min-width: 640px) {
  body.formstepwhenwhere.commercialcustomer form #site_create_new_addr .postcode-search {
    grid-gap: 1rem;
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 640px) {
  body.formstepwhenwhere.commercialcustomer form #site_create_new_addr .postcode-search span,
body.formstepwhenwhere.commercialcustomer form #site_create_new_addr .postcode-search #site_postcodeSelect {
    grid-column: span 2;
  }
}
@media only screen and (min-width: 640px) {
  body.formstepwhenwhere.commercialcustomer form #site_create_new_addr .postcode-search #site_postcodeSearchBtn {
    padding: 0 1rem;
  }
}
body.formstepwhenwhere.commercialcustomer form #site_create_new_addr .postcode-search input {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  body.formstepwhenwhere.commercialcustomer form #site_create_new_addr .address-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
  body.formstepwhenwhere.commercialcustomer form #site_create_new_addr .address-fields label {
    margin: 0;
  }
}
body.formstepwhenwhere.commercialcustomer form h3 {
  text-align: left;
  margin-top: 3rem;
}
@media only screen and (min-width: 1024px) {
  body.formstepwhenwhere.commercialcustomer form .delivery .date {
    margin-top: 55px;
  }
}
body.formstepwhenwhere.commercialcustomer form .delivery,
body.formstepwhenwhere.commercialcustomer form .pickup {
  background: #0d8458;
  border-radius: 5px;
  padding: 1.6rem;
}
@media only screen and (min-width: 1600px) {
  body.formstepwhenwhere.commercialcustomer form .delivery,
body.formstepwhenwhere.commercialcustomer form .pickup {
    border-radius: 8px;
  }
}
body.formstepwhenwhere.commercialcustomer form .delivery label.textfield,
body.formstepwhenwhere.commercialcustomer form .pickup label.textfield {
  padding: 0;
}
body.formstepwhenwhere.commercialcustomer form .delivery label.textfield textarea,
body.formstepwhenwhere.commercialcustomer form .pickup label.textfield textarea {
  text-align: left;
}
body.formstepwhenwhere.commercialcustomer form .delivery legend,
body.formstepwhenwhere.commercialcustomer form .pickup legend {
  transform: translateY(30px);
  color: #fff;
}
body.formstepwhenwhere.commercialcustomer form .delivery .date,
body.formstepwhenwhere.commercialcustomer form .pickup .date {
  padding: 0;
  text-align: left;
  margin-bottom: 1rem;
}
body.formstepwhenwhere.commercialcustomer form .delivery .time legend,
body.formstepwhenwhere.commercialcustomer form .pickup .time legend {
  transform: unset;
}
body.formstepwhenwhere.commercialcustomer form .date {
  margin-bottom: 3rem;
}
body.formstepwhenwhere.commercialcustomer form .switch .switch-label {
  line-height: 1.6rem;
}
body.formstepwhenwhere.commercialcustomer form .time div {
  background-color: #0d8458;
  border-radius: 5px;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 1600px) {
  body.formstepwhenwhere.commercialcustomer form .time div {
    border-radius: 8px;
  }
}
body.formstepwhenwhere.commercialcustomer form .time div .switch {
  margin: 0;
}
body.formstepwhenwhere.commercialcustomer form button[type=submit] {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -ms-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  margin: 1.5rem auto 2rem;
  width: auto;
}

body.formstepdelivery section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}
body.formstepdelivery form {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 100rem;
  display: flex;
  flex-flow: row wrap;
  margin-top: 3rem;
  align-items: flex-start;
  align-content: flex-start;
}
body.formstepdelivery form > section {
  width: calc(50% - 2.45rem);
  margin-right: 1.225rem;
  margin-left: 1.225rem;
}
@media only screen and (max-width: 1199px) {
  body.formstepdelivery form > section {
    width: calc(50% - 1rem);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}
@media (max-width: 940px) {
  body.formstepdelivery form > section {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem;
  }
}
body.formstepdelivery form section {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
@media only screen and (max-width: 1023px) {
  body.formstepdelivery form section {
    margin-bottom: 2rem;
  }
}
body.formstepdelivery form div:not(.postcode-container):not(.fstElement) {
  border-radius: 5px;
  transition-property: padding;
  transition: 0.15s ease-out;
  background: #0d8458;
  padding: 2rem 4rem;
}
@media only screen and (min-width: 1600px) {
  body.formstepdelivery form div:not(.postcode-container):not(.fstElement) {
    border-radius: 8px;
  }
}
@media (min-width: 941px) and (max-width: 1024px) {
  body.formstepdelivery form div:not(.postcode-container):not(.fstElement) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media only screen and (max-width: 639px) {
  body.formstepdelivery form div:not(.postcode-container):not(.fstElement) {
    padding: 1rem;
  }
}
body.formstepdelivery form .button {
  background: #0d8458;
  display: block;
  margin: 1rem 0 0;
  opacity: 0.9;
}
body.formstepdelivery form .button:hover {
  opacity: 1;
}
@media (max-width: 940px) {
  body.formstepdelivery form .button {
    display: none;
  }
}
body.formstepdelivery form p#help_text {
  margin: 4rem 1rem 0;
}
@media (max-width: 940px) {
  body.formstepdelivery form p#help_text {
    display: none;
  }
}
body.formstepdelivery form legend {
  color: #fff;
  font-size: 2rem;
  font-weight: 200;
  line-height: 2.5rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1.6rem;
  text-transform: none;
  font-weight: 800;
}
@media only screen and (max-width: 639px) {
  body.formstepdelivery form legend {
    font-size: 1.3rem;
    line-height: 1.4rem;
  }
}
body.formstepdelivery form label {
  padding: 0;
}
body.formstepdelivery form label:not([aria-label=Postcode]) {
  transition: all 0.1s ease-in-out;
}
body.formstepdelivery form label:not([aria-label=Postcode]):focus-within, body.formstepdelivery form label:not([aria-label=Postcode]):hover {
  box-shadow: 0 0.2em 2.5em rgba(0, 0, 0, 0.1);
  transform: scale(1.025);
}
@media screen and (prefers-reduced-motion: reduce) {
  body.formstepdelivery form label:not([aria-label=Postcode]) {
    box-shadow: none;
    transform: none;
  }
}
body.formstepdelivery form button[type=submit] {
  width: 100%;
  height: 42px;
  padding: 0;
  margin-bottom: 1rem;
}
body.formstepdelivery form button[type=submit]:hover, body.formstepdelivery form button[type=submit]:focus {
  background: #fff;
  color: #006949;
}
body.formstepdelivery form .split-left,
body.formstepdelivery form .split-right {
  margin-bottom: 1rem;
}
body.formstepdelivery form #publicInfo {
  color: #fff;
  margin: 2rem 0;
  text-align: left;
  display: none;
}

#addrSelect {
  margin: 0;
}

body.formstepdetails.domesticcustomer section {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 100rem;
  display: flex;
  flex-flow: row wrap;
}
body.formstepdetails.domesticcustomer section > h2 {
  width: calc(100% - 2.45rem);
  margin-right: 1.225rem;
  margin-left: 1.225rem;
}
body.formstepdetails.domesticcustomer section > form, body.formstepdetails.domesticcustomer section .left {
  width: calc(50% - 2.45rem);
  margin-right: 1.225rem;
  margin-left: 1.225rem;
}
@media only screen and (max-width: 1199px) {
  body.formstepdetails.domesticcustomer section > form, body.formstepdetails.domesticcustomer section .left {
    width: calc(50% - 1rem);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}
@media (max-width: 940px) {
  body.formstepdetails.domesticcustomer section > form, body.formstepdetails.domesticcustomer section .left {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem;
  }
}
body.formstepdetails.domesticcustomer section h2 {
  margin-bottom: 6rem;
}
@media only screen and (max-width: 1023px) {
  body.formstepdetails.domesticcustomer section h2 {
    margin-bottom: 3rem;
  }
}
@media (max-width: 880px) {
  body.formstepdetails.domesticcustomer section h2 {
    font-size: 1.8rem;
  }
}
@media (max-width: 550px) {
  body.formstepdetails.domesticcustomer section h2 {
    font-size: 1.3rem;
  }
}
body.formstepdetails.domesticcustomer section h2 span {
  display: block;
  margin: 0 auto;
}
@media (max-width: 1445px) {
  body.formstepdetails.domesticcustomer section h2 span {
    max-width: 1000px;
  }
}
@media (max-width: 1120px) {
  body.formstepdetails.domesticcustomer section h2 span {
    max-width: 750px;
  }
}
@media only screen and (max-width: 1023px) {
  body.formstepdetails.domesticcustomer section h2 span {
    max-width: 100%;
  }
}
@media (max-width: 880px) {
  body.formstepdetails.domesticcustomer section h2 span {
    max-width: 600px;
  }
}
@media (max-width: 550px) {
  body.formstepdetails.domesticcustomer section h2 span {
    max-width: 250px;
  }
}
body.formstepdetails.domesticcustomer section form {
  padding: 0;
}
body.formstepdetails.domesticcustomer section form > fieldset {
  background: #0d8458;
  border-radius: 5px;
}
@media only screen and (min-width: 1600px) {
  body.formstepdetails.domesticcustomer section form > fieldset {
    border-radius: 8px;
  }
}
body.formstepdetails.domesticcustomer section form > fieldset span {
  display: block;
}
body.formstepdetails.domesticcustomer section .left,
body.formstepdetails.domesticcustomer section fieldset.section {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -ms-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
body.formstepdetails.domesticcustomer section fieldset.section {
  margin-bottom: 2.45rem;
  padding: 2rem 4rem 2.5rem;
}
@media (max-width: 1200px) {
  body.formstepdetails.domesticcustomer section fieldset.section {
    margin-bottom: 1rem;
  }
}
@media (max-width: 1024px) and (min-width: 941px) {
  body.formstepdetails.domesticcustomer section fieldset.section {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media only screen and (max-width: 639px) {
  body.formstepdetails.domesticcustomer section fieldset.section {
    padding: 1rem;
  }
}
body.formstepdetails.domesticcustomer section fieldset.section legend {
  padding-left: 0;
  padding-right: 0;
}
body.formstepdetails.domesticcustomer section fieldset.section legend.title {
  color: #fff;
  font-size: 2rem;
  font-weight: 200;
  line-height: 2.5rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1.6rem;
  text-transform: none;
  font-weight: 800;
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (max-width: 639px) {
  body.formstepdetails.domesticcustomer section fieldset.section legend.title {
    font-size: 1.3rem;
    line-height: 1.4rem;
  }
}
body.formstepdetails.domesticcustomer section .date {
  border-radius: 5px;
  transition: 0.2s all ease-out;
  background: #0d8458;
  margin: 0 0 1rem;
  padding: 0.5rem 1.6rem 0.5rem 0.5rem;
  padding: 0;
}
@media only screen and (min-width: 1600px) {
  body.formstepdetails.domesticcustomer section .date {
    border-radius: 8px;
  }
}
body.formstepdetails.domesticcustomer section .date:hover, body.formstepdetails.domesticcustomer section .date:focus, body.formstepdetails.domesticcustomer section .date:focus-within {
  background: #0f9b67;
}
body.formstepdetails.domesticcustomer section .date:hover label:nth-child(2):before,
body.formstepdetails.domesticcustomer section .date:hover label:nth-child(2):after, body.formstepdetails.domesticcustomer section .date:focus label:nth-child(2):before,
body.formstepdetails.domesticcustomer section .date:focus label:nth-child(2):after, body.formstepdetails.domesticcustomer section .date:focus-within label:nth-child(2):before,
body.formstepdetails.domesticcustomer section .date:focus-within label:nth-child(2):after {
  opacity: 0;
}
@media only screen and (max-width: 1023px) {
  body.formstepdetails.domesticcustomer section .date {
    padding: 0.2rem 1.6rem 0.9rem;
  }
}
@media only screen and (max-width: 639px) {
  body.formstepdetails.domesticcustomer section .date {
    padding: 0.5rem 1rem 1.2rem;
  }
}
body.formstepdetails.domesticcustomer section .date legend {
  box-shadow: none;
  float: left;
  margin: 0;
  padding: 0;
  transform: scale(1);
  width: 40%;
  text-align: center;
  background: none;
}
@media only screen and (max-width: 1023px) {
  body.formstepdetails.domesticcustomer section .date legend {
    width: 100%;
  }
}
@media only screen and (max-width: 639px) {
  body.formstepdetails.domesticcustomer section .date legend {
    font-size: 0.8rem;
  }
}
body.formstepdetails.domesticcustomer section .date span {
  float: right;
  width: 60%;
}
@media only screen and (max-width: 1023px) {
  body.formstepdetails.domesticcustomer section .date span {
    float: none;
    width: 100%;
  }
}
body.formstepdetails.domesticcustomer section .date label {
  clear: none;
  float: left;
  margin: 0;
  padding: 0;
  width: 33.33333%;
  position: relative;
}
body.formstepdetails.domesticcustomer section .date label:focus-within, body.formstepdetails.domesticcustomer section .date label:hover {
  box-shadow: 0 0.2em 2.5em rgba(0, 0, 0, 0);
  transform: scale(1);
}
body.formstepdetails.domesticcustomer section .date label:nth-child(1) input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
body.formstepdetails.domesticcustomer section .date label:nth-child(2) input {
  border-radius: 0;
  margin: 0;
}
body.formstepdetails.domesticcustomer section .date label:nth-child(3) input {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin: 0;
}
body.formstepdetails.domesticcustomer section .date label:nth-child(2):before, body.formstepdetails.domesticcustomer section .date label:nth-child(2):after {
  background: #0d8458;
  bottom: 2px;
  content: "";
  display: block;
  height: 33px;
  left: 0;
  margin: 0 0;
  position: absolute;
  top: 5px;
  transform: rotate(15deg);
  transition: 0.1s all ease-out;
  width: 2px;
}
@media only screen and (max-width: 639px) {
  body.formstepdetails.domesticcustomer section .date label:nth-child(2):before, body.formstepdetails.domesticcustomer section .date label:nth-child(2):after {
    height: 20px;
    top: 5px;
  }
}
body.formstepdetails.domesticcustomer section .date label:nth-child(2):after {
  left: inherit;
  right: 0;
  z-index: 1;
}
body.formstepdetails.domesticcustomer section .date:hover, body.formstepdetails.domesticcustomer section .date:focus, body.formstepdetails.domesticcustomer section .date:focus-within {
  background: transparent;
}
@media only screen and (max-width: 1023px) {
  body.formstepdetails.domesticcustomer section .date {
    padding: 0;
  }
}
body.formstepdetails.domesticcustomer section .date legend {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 800;
  width: 50%;
  padding: 0 0.5rem;
  line-height: 2.65rem;
}
@media (max-width: 1330px) {
  body.formstepdetails.domesticcustomer section .date legend {
    width: 100%;
  }
}
body.formstepdetails.domesticcustomer section .date span {
  width: 50%;
}
@media (max-width: 1330px) {
  body.formstepdetails.domesticcustomer section .date span {
    width: 100%;
  }
}
body.formstepdetails.domesticcustomer section .date span label {
  background: none;
}
body.formstepdetails.domesticcustomer section .date span input {
  margin: 0;
}
body.formstepdetails.domesticcustomer section .date input[type] {
  padding: 0 1rem 0 3rem;
}
body.formstepdetails.domesticcustomer section .time > legend {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 800;
  padding: 0 0.5rem;
  line-height: 2.65rem;
}
body.formstepdetails.domesticcustomer section textarea {
  transition: all 0.1s ease-in-out;
}
body.formstepdetails.domesticcustomer section textarea:focus-within, body.formstepdetails.domesticcustomer section textarea:hover {
  box-shadow: 0 0.2em 2.5em rgba(0, 0, 0, 0.1);
  transform: scale(1.025);
}
@media screen and (prefers-reduced-motion: reduce) {
  body.formstepdetails.domesticcustomer section textarea {
    box-shadow: none;
    transform: none;
  }
}
@media (min-width: 941px) and (max-width: 1350px) {
  body.formstepdetails.domesticcustomer section .split-left {
    width: 30%;
  }
  body.formstepdetails.domesticcustomer section .split-right {
    width: 70%;
  }
}
body.formstepdetails.domesticcustomer section legend {
  float: left;
  font-size: 2.2rem;
  font-weight: 200;
  text-transform: none;
  width: 100%;
}
body.formstepdetails.domesticcustomer section label {
  padding: 0;
}
@media (max-width: 420px) {
  body.formstepdetails.domesticcustomer section .split {
    width: 100%;
  }
}
body.formstepdetails.domesticcustomer section button {
  display: block;
  width: 100%;
  padding: 1.5rem;
  max-width: 480px;
  margin: 3rem auto 0;
}

/*************************************************************************** 
	PRETEND FORM
*/
.pretend-form {
  border-radius: 5px;
  background: #0d8458;
  margin-bottom: 2.45rem;
  padding: 2rem 4rem 2.5rem;
  text-align: center;
}
@media only screen and (min-width: 1600px) {
  .pretend-form {
    border-radius: 8px;
  }
}
@media (max-width: 1200px) {
  .pretend-form {
    margin-bottom: 1rem;
  }
}
@media (max-width: 1024px) and (min-width: 941px) {
  .pretend-form {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media only screen and (max-width: 639px) {
  .pretend-form {
    padding: 1rem;
  }
}
.pretend-form span.title {
  color: #fff;
  font-size: 2rem;
  font-weight: 200;
  line-height: 2.5rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1.6rem;
  text-transform: none;
  font-weight: 800;
  display: block;
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (max-width: 639px) {
  .pretend-form span.title {
    font-size: 1.3rem;
    line-height: 1.4rem;
  }
}
.pretend-form ul {
  list-style: none;
  margin: 0;
}
.pretend-form ul li {
  border-radius: 5px;
  background-color: #fff;
  color: #006949;
  font-weight: 800;
  margin-top: 1rem;
  padding: 0.5rem;
  text-transform: uppercase;
}
@media only screen and (min-width: 1600px) {
  .pretend-form ul li {
    border-radius: 8px;
  }
}

.pretend-form.disabled {
  background: #006949;
}

.split {
  width: 50%;
  float: left;
  margin: 0;
}
.split button[type] {
  width: 100%;
  height: 42px;
  padding: 0;
}
@media only screen and (max-width: 639px) {
  .split button[type] {
    height: 30px;
    font-size: 0.8rem;
  }
}

.split.split-left {
  padding-right: 0.5rem;
}
@media only screen and (max-width: 639px) {
  .split.split-left {
    padding-right: 0.2rem;
  }
}
.split.split-left input {
  margin-bottom: 0;
}

.split.split-right {
  padding-left: 0.5rem;
}
@media only screen and (max-width: 639px) {
  .split.split-right {
    padding-left: 0.2rem;
  }
}

.split.split-right + * {
  clear: both;
}

@media only screen and (min-width: 768px) {
  body.formstepdetails.commercialcustomer .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
}
body.formstepdetails.commercialcustomer label {
  text-align: left;
}
@media only screen and (min-width: 1200px) {
  body.formstepdetails.commercialcustomer label {
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-gap: 1rem;
    margin: 0;
    padding: 1rem 1.5rem;
  }
  body.formstepdetails.commercialcustomer label input, body.formstepdetails.commercialcustomer label select {
    margin: 0;
  }
  body.formstepdetails.commercialcustomer label span {
    line-height: 1.1;
    display: block;
    margin: auto 0;
  }
}
body.formstepdetails.commercialcustomer input {
  text-align: left;
}
body.formstepdetails.commercialcustomer h3 {
  color: #fff;
  font-weight: 800;
}
@media only screen and (min-width: 1200px) {
  body.formstepdetails.commercialcustomer h3 {
    text-align: right;
    padding-right: 1.5rem;
  }
}
body.formstepdetails.commercialcustomer fieldset {
  background: #0d8458;
  border-radius: 5px;
}
@media only screen and (min-width: 1600px) {
  body.formstepdetails.commercialcustomer fieldset {
    border-radius: 8px;
  }
}
@media only screen and (min-width: 1200px) {
  body.formstepdetails.commercialcustomer fieldset {
    padding-bottom: 1rem;
  }
}
body.formstepdetails.commercialcustomer fieldset legend {
  width: 100%;
  text-align: left;
  background: transparent;
  transform: translateY(36px);
}
body.formstepdetails.commercialcustomer fieldset.references {
  margin-bottom: auto;
}
body.formstepdetails.commercialcustomer p {
  margin-top: 2rem;
}
body.formstepdetails.commercialcustomer button {
  margin-bottom: 2rem;
  margin-top: 1rem;
}
body.formstepdetails.commercialcustomer #contact_create {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -ms-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
@media only screen and (min-width: 768px) {
  body.formstepdetails.commercialcustomer #contact_create {
    background: #006949;
    border-radius: 5px;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-top: 1rem;
  }
}
@media only screen and (min-width: 768px) and (min-width: 1600px) {
  body.formstepdetails.commercialcustomer #contact_create {
    border-radius: 8px;
  }
}
@media only screen and (min-width: 768px) {
  body.formstepdetails.commercialcustomer #contact_create label {
    background: transparent;
  }
}
@media only screen and (min-width: 1200px) {
  body.formstepdetails.commercialcustomer #contact_create {
    margin-top: 1rem;
  }
}

body.documentsign {
  /*
   * Adapted from code originally found at: https://css-tricks.com/drag-and-drop-file-uploading/
   */
}
body.documentsign .steps {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -ms-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -ms-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
body.documentsign #step_1 {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  width: calc(100% / 6 - 1.25rem);
}
@media only screen and (max-width: 639px) {
  body.documentsign #step_1 {
    width: calc(100% / 6);
    font-size: 0.5em;
  }
}
body.documentsign #step_2 {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -ms-animation-delay: 0.1s;
  -o-animation-delay: 0.1s;
  animation-delay: 0.1s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  width: calc(100% / 6 - 1.25rem);
}
@media only screen and (max-width: 639px) {
  body.documentsign #step_2 {
    width: calc(100% / 6);
    font-size: 0.5em;
  }
}
body.documentsign #step_3 {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -ms-animation-delay: 0.2s;
  -o-animation-delay: 0.2s;
  animation-delay: 0.2s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  width: calc(100% / 6 - 1.25rem);
}
@media only screen and (max-width: 639px) {
  body.documentsign #step_3 {
    width: calc(100% / 6);
    font-size: 0.5em;
  }
}
body.documentsign #step_4 {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -ms-animation-delay: 0.3s;
  -o-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  width: calc(100% / 6 - 1.25rem);
}
@media only screen and (max-width: 639px) {
  body.documentsign #step_4 {
    width: calc(100% / 6);
    font-size: 0.5em;
  }
}
body.documentsign #step_5 {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  -ms-animation-delay: 0.4s;
  -o-animation-delay: 0.4s;
  animation-delay: 0.4s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  width: calc(100% / 6 - 1.25rem);
}
@media only screen and (max-width: 639px) {
  body.documentsign #step_5 {
    width: calc(100% / 6);
    font-size: 0.5em;
  }
}
body.documentsign #step_6 {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -ms-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  width: calc(100% / 6 - 1.25rem);
}
@media only screen and (max-width: 639px) {
  body.documentsign #step_6 {
    width: calc(100% / 6);
    font-size: 0.5em;
  }
}
body.documentsign section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -ms-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -ms-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
body.documentsign section h2 {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
body.documentsign form {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
  max-width: 1030px;
  text-align: center;
}
body.documentsign form > * {
  width: calc(100% - 1.25rem);
  margin-right: 0.625rem;
  margin-left: 0.625rem;
}
body.documentsign form > p#help_text, body.documentsign form button {
  width: calc(100% - 1.25rem);
  margin-right: 0.625rem;
  margin-left: 0.625rem;
}
@media only screen and (max-width: 1023px) {
  body.documentsign form {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 639px) {
  body.documentsign form > * {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem;
  }
}
body.documentsign form div {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
body.documentsign form p#help_text {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  -ms-animation-delay: 0.4s;
  -o-animation-delay: 0.4s;
  animation-delay: 0.4s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
body.documentsign form label {
  transition: all 0.1s ease-in-out;
  background: #0d8458;
  padding: 0.5rem 1.6rem 0.6rem 0.5rem;
}
body.documentsign form label:focus-within, body.documentsign form label:hover {
  box-shadow: 0 0.2em 2.5em rgba(0, 0, 0, 0.1);
  transform: scale(1.025);
}
@media screen and (prefers-reduced-motion: reduce) {
  body.documentsign form label {
    box-shadow: none;
    transform: none;
  }
}
@media only screen and (max-width: 1023px) {
  body.documentsign form label {
    padding: 0.2rem 1.6rem 0.9rem;
  }
}
@media only screen and (max-width: 639px) {
  body.documentsign form label {
    font-size: 0.8rem;
    padding: 0.5rem 1rem 1.2rem;
  }
}
body.documentsign form label.date {
  padding: 1.7rem 1.6rem;
  line-height: 1.2;
}
@media only screen and (max-width: 639px) {
  body.documentsign form label.date {
    padding: 1.3rem 1rem 1.2rem;
  }
}
body.documentsign form label.date input {
  margin: 1rem 0 0;
}
body.documentsign form label input {
  display: block;
  float: right;
  margin: 0 0 0 0.5rem;
  max-width: 200px;
}
@media only screen and (max-width: 1023px) {
  body.documentsign form label input {
    float: none;
    margin: 0;
    max-width: 100%;
  }
}
body.documentsign form label input#date {
  float: none;
  max-width: unset;
}
body.documentsign form label button[type=button] {
  background: #ffffff;
  color: #006949;
  float: right;
  height: 42px;
  margin: 0;
  padding: 0;
  width: 135px;
}
@media only screen and (max-width: 1023px) {
  body.documentsign form label button[type=button] {
    float: none;
    width: 100%;
  }
}
@media only screen and (max-width: 639px) {
  body.documentsign form label button[type=button] {
    font-size: 0.8rem;
  }
}
body.documentsign form fieldset {
  border-radius: 5px;
  transition: 0.2s all ease-out;
  background: #0d8458;
  margin: 0 0 1rem;
  padding: 0.5rem 1.6rem 0.5rem 0.5rem;
}
@media only screen and (min-width: 1600px) {
  body.documentsign form fieldset {
    border-radius: 8px;
  }
}
body.documentsign form fieldset:hover, body.documentsign form fieldset:focus, body.documentsign form fieldset:focus-within {
  background: #0f9b67;
}
body.documentsign form fieldset:hover label:nth-child(2):before,
body.documentsign form fieldset:hover label:nth-child(2):after, body.documentsign form fieldset:focus label:nth-child(2):before,
body.documentsign form fieldset:focus label:nth-child(2):after, body.documentsign form fieldset:focus-within label:nth-child(2):before,
body.documentsign form fieldset:focus-within label:nth-child(2):after {
  opacity: 0;
}
@media only screen and (max-width: 1023px) {
  body.documentsign form fieldset {
    padding: 0.2rem 1.6rem 0.9rem;
  }
}
@media only screen and (max-width: 639px) {
  body.documentsign form fieldset {
    padding: 0.5rem 1rem 1.2rem;
  }
}
body.documentsign form fieldset legend {
  box-shadow: none;
  float: left;
  margin: 0;
  padding: 0;
  transform: scale(1);
  width: 40%;
  text-align: center;
  background: none;
}
@media only screen and (max-width: 1023px) {
  body.documentsign form fieldset legend {
    width: 100%;
  }
}
@media only screen and (max-width: 639px) {
  body.documentsign form fieldset legend {
    font-size: 0.8rem;
  }
}
body.documentsign form fieldset span {
  float: right;
  width: 60%;
}
@media only screen and (max-width: 1023px) {
  body.documentsign form fieldset span {
    float: none;
    width: 100%;
  }
}
body.documentsign form fieldset label {
  clear: none;
  float: left;
  margin: 0;
  padding: 0;
  width: 33.33333%;
  position: relative;
}
body.documentsign form fieldset label:focus-within, body.documentsign form fieldset label:hover {
  box-shadow: 0 0.2em 2.5em rgba(0, 0, 0, 0);
  transform: scale(1);
}
body.documentsign form fieldset label:nth-child(1) input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
body.documentsign form fieldset label:nth-child(2) input {
  border-radius: 0;
  margin: 0;
}
body.documentsign form fieldset label:nth-child(3) input {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin: 0;
}
body.documentsign form fieldset label:nth-child(2):before, body.documentsign form fieldset label:nth-child(2):after {
  background: #0d8458;
  bottom: 2px;
  content: "";
  display: block;
  height: 33px;
  left: 0;
  margin: 0 0;
  position: absolute;
  top: 5px;
  transform: rotate(15deg);
  transition: 0.1s all ease-out;
  width: 2px;
}
@media only screen and (max-width: 639px) {
  body.documentsign form fieldset label:nth-child(2):before, body.documentsign form fieldset label:nth-child(2):after {
    height: 20px;
    top: 5px;
  }
}
body.documentsign form fieldset label:nth-child(2):after {
  left: inherit;
  right: 0;
  z-index: 1;
}
body.documentsign form input[type=number] {
  padding: 0 0.5rem 0 1.2rem;
}
body.documentsign form button[type=submit] {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -ms-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  margin: 1.5rem auto 0;
  width: auto;
}
body.documentsign .box__dragndrop,
body.documentsign .box__uploading,
body.documentsign .box__success,
body.documentsign .box__error {
  display: none;
  text-align: center;
}
body.documentsign .box__button {
  display: none !important;
}
body.documentsign .no-js .box__button {
  display: block;
}
body.documentsign .box__file {
  display: none;
}
body.documentsign .box {
  color: #333;
  margin-top: 2em;
}
body.documentsign .box label {
  color: inherit;
  text-align: center;
  position: relative;
  cursor: pointer;
}
body.documentsign .box label:before {
  content: "\f574";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 0.5rem;
  font-size: 40px;
  opacity: 0.3;
  position: absolute;
  left: 50%;
  top: -65px;
  margin-left: -20px;
  transition: 0.2s all ease-out;
}
body.documentsign .box label:hover:before, body.documentsign .box label:focus:before {
  opacity: 0.5;
  color: #0d8458;
}
body.documentsign .box.has-advanced-upload {
  background-color: #f1f2f2;
  padding: 8rem 5rem 5rem 5rem;
  outline: 2px dashed #0d8458;
  outline-offset: -10px;
  transition-property: background-color, outline-offset;
  transition-duration: 0.5s;
}
body.documentsign .box.has-advanced-upload .box__dragndrop {
  display: inline;
}
body.documentsign .box.has-advanced-upload .box__file {
  display: none;
}
body.documentsign .box.is-dragover {
  background-color: white;
  outline-offset: -20px;
}
body.documentsign .box.is-uploading .box__input {
  visibility: none;
}
body.documentsign .box.is-uploading .box__uploading {
  display: block;
}
body.documentsign .box.is-success .box__success {
  display: block;
}
body.documentsign .box.is-error .box__error {
  display: block;
}
body.documentsign #waste_photo_form.is-mobile label::before {
  content: "\f030";
}
body.documentsign #waste_photo_form.is-mobile .box__dragndrop {
  display: none;
}

@media only screen and (min-width: 768px) {
  body.index.documentsign h2 {
    margin-top: 3rem;
  }
}
body.index.documentsign form {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
  max-width: 1030px;
  text-align: center;
}
body.index.documentsign form > .section {
  width: calc(50% - 1.25rem);
  margin-right: 0.625rem;
  margin-left: 0.625rem;
}
body.index.documentsign form > button, body.index.documentsign form .site {
  width: calc(100% - 1.25rem);
  margin-right: 0.625rem;
  margin-left: 0.625rem;
}
body.index.documentsign form #site_create {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -ms-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -ms-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
body.index.documentsign form #site_create .checkbox {
  cursor: pointer;
}
@media only screen and (min-width: 768px) {
  body.index.documentsign form #site_create .checkbox {
    padding-right: 68px;
    line-height: 1.1;
    display: flex;
    align-items: center;
    text-align: left;
  }
}
body.index.documentsign form #site_create .checkbox:hover {
  background-color: #006949;
}
@media only screen and (min-width: 768px) {
  body.index.documentsign form #site_create .checkbox input {
    position: absolute;
  }
  body.index.documentsign form #site_create .checkbox span:before {
    top: 50%;
    margin-top: -12px;
  }
  body.index.documentsign form #site_create .checkbox span:after {
    top: 50%;
    margin-top: -12px;
  }
}
@media only screen and (min-width: 768px) {
  body.index.documentsign form #site_create .postcode-fields {
    display: grid;
    grid-template-columns: 70% 1fr;
    grid-gap: 1rem;
  }
}
@media only screen and (max-width: 1023px) {
  body.index.documentsign form {
    padding-left: 0;
    padding-right: 0;
  }
  body.index.documentsign form > .section {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem;
  }
}
body.index.documentsign form .section p {
  font-size: 0.75rem;
  text-align: left;
  color: #ffffff;
}
body.index.documentsign form .section legend {
  border-radius: 0;
  color: #006949;
  line-height: 2.5rem;
  background: transparent;
  padding: 0;
  margin-bottom: 0;
  text-align: left;
}
body.index.documentsign form .section .switch-label {
  text-align: center;
}
body.index.documentsign form .section input {
  text-align: left;
}
body.index.documentsign form .section label {
  text-align: left;
}
body.index.documentsign form .section label.date input {
  margin-bottom: 1rem;
}
body.index.documentsign form #site_create_new_addr .postcode-search {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
}
@media only screen and (min-width: 640px) {
  body.index.documentsign form #site_create_new_addr .postcode-search {
    grid-gap: 1rem;
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 640px) {
  body.index.documentsign form #site_create_new_addr .postcode-search span,
body.index.documentsign form #site_create_new_addr .postcode-search #site_postcodeSelect {
    grid-column: span 2;
  }
}
@media only screen and (min-width: 640px) {
  body.index.documentsign form #site_create_new_addr .postcode-search #site_postcodeSearchBtn {
    padding: 0 1rem;
  }
}
body.index.documentsign form #site_create_new_addr .postcode-search input {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  body.index.documentsign form #site_create_new_addr .address-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
  body.index.documentsign form #site_create_new_addr .address-fields label {
    margin: 0;
  }
}
body.index.documentsign form h3 {
  text-align: left;
  margin-top: 3rem;
}
@media only screen and (min-width: 1024px) {
  body.index.documentsign form .delivery .date {
    margin-top: 55px;
  }
}
body.index.documentsign form .delivery,
body.index.documentsign form .pickup {
  background: #0d8458;
  border-radius: 5px;
  padding: 1.6rem;
}
@media only screen and (min-width: 1600px) {
  body.index.documentsign form .delivery,
body.index.documentsign form .pickup {
    border-radius: 8px;
  }
}
body.index.documentsign form .delivery label.textfield,
body.index.documentsign form .pickup label.textfield {
  padding: 0;
}
body.index.documentsign form .delivery label.textfield textarea,
body.index.documentsign form .pickup label.textfield textarea {
  text-align: left;
}
body.index.documentsign form .delivery legend,
body.index.documentsign form .pickup legend {
  transform: translateY(30px);
  color: #ffffff;
}
body.index.documentsign form .delivery .date,
body.index.documentsign form .pickup .date {
  padding: 0;
  text-align: left;
  margin-bottom: 1rem;
}
body.index.documentsign form .delivery .time legend,
body.index.documentsign form .pickup .time legend {
  transform: unset;
}
body.index.documentsign form .date {
  margin-bottom: 3rem;
}
body.index.documentsign form .switch .switch-label {
  line-height: 1.6rem;
}
body.index.documentsign form .time div {
  background-color: #0d8458;
  border-radius: 5px;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 1600px) {
  body.index.documentsign form .time div {
    border-radius: 8px;
  }
}
body.index.documentsign form .time div .switch {
  margin: 0;
}
body.index.documentsign form button[type=submit] {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -ms-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  margin: 1.5rem auto 2rem;
  width: auto;
}

#signature-canvas {
  background-color: #f1f2f2;
  width: 100%;
  min-height: 300px;
}