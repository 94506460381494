body.formstepwhenwhere.domesticcustomer {

	.steps {
		@include fadeIn ($duration: 2s);
	}

	#step_1 {
		@include fadeInUp ($duration: .5s);
	}

	#step_2 {
		@include fadeInUp ($duration: .5s, $delay: .1s);
	}

	#step_3 {
		@include fadeInUp ($duration: .5s, $delay: .2s);
	}

	#step_4 {
		@include fadeInUp ($duration: .5s, $delay: .3s);
	}

	#step_5 {
		@include fadeInUp ($duration: .5s, $delay: .4s);
	}

	section {
		@include center-vertical;
		@include fadeIn ($duration: 2s);

		h2 {
			@include fadeInUp ($duration: 1s);
		}
	}

	form {
		@include xy-grid;
		@include xy-grid-layout(2, '*');
		@include xy-grid-layout(1, 'p#help_text, button');

		margin: 0 auto;
		max-width: 1030px;
		text-align: center;

		@include maxM {
			padding-left: 0;
			padding-right: 0;
		}

		@include maxXS {
			@include xy-grid-layout(1, '*');
		}

		div {
			@include fadeInUp ($duration: .5s);
		}

		p#help_text {
			@include fadeInUp ($duration: .5s, $delay: .4s);
		}

		label {
			@include focus;

			background: $colour-form-background;
			padding: 0.5rem 1.6rem 0.6rem 0.5rem;

			@include maxM {
				padding: 0.2rem 1.6rem 0.9rem;
			}

			@include maxXS {
				font-size: $input-font-small;
				padding: 0.5rem 1rem 1.2rem;
			}

			&.date {
				padding: 1.7rem 1.6rem;
				line-height: 1.2;

				@include maxXS {
					padding: 1.3rem 1rem 1.2rem;
				}

				input {
					margin: 1rem 0 0;
				}
			}

			input {
				display: block;
				float: right;
				margin: 0 0 0 0.5rem;
				max-width: 200px;

				@include maxM {
					float: none;
					margin: 0;
					max-width: 100%;
				}

				&#date {
					float: none;
					max-width: unset;
				}
			}

			button[type="button"] {
				background: #fff;
				color: $colour-text-medium;
				float: right;
				height: $input-height;
				margin: 0;
				padding: 0;
				width: 135px;

				@include maxM {
					float: none;
					width: 100%;
				}

				@include maxXS {
					font-size: $input-font-small;
				}
			}
		}

		fieldset {
			@include date-fields;
		}

		input[type="number"] {
			padding: 0 0.5rem 0 1.2rem;
		}

		button[type="submit"] {
			@include fadeInUp ($duration: .5s, $delay: .5s);
			margin: 1.5rem auto 0;
			width: auto;
		}
	}
}

body.formstepwhenwhere.commercialcustomer {
	h2 {
		@include minS {
			margin-top: 3rem;
		}
	}

	form {
		@include xy-grid;
		@include xy-grid-layout(2, '.section');
		@include xy-grid-layout(1, 'button, .site');

		#site_create {
			@include fadeIn ($duration: .5s, $delay: 0);

			.checkbox {
				cursor: pointer;

				@include minS {
					padding-right: 68px;
					line-height: 1.1;
					display: flex;
					align-items: center;
					text-align: left;
				}

				&:hover {
					background-color: $colour-brand;
				}

				@include minS {
					input {
						position: absolute;
					}

					span:before {
						top: 50%;
						margin-top: -12px;
					}

					span:after {
						top: 50%;
						margin-top: -12px;
					}
				}
			}

			.postcode-fields {
				@include minS {
					display: grid;
					grid-template-columns: 70% 1fr;
					grid-gap: 1rem;
				}
			}
		}

		margin: 0 auto;
		max-width: 1030px;
		text-align: center;

		@include maxM {
			padding-left: 0;
			padding-right: 0;
			@include xy-grid-layout(1, '.section');
		}

		.section {
			p {
				font-size: 0.75rem;
				text-align: left;
				color: #fff;
			}

			legend {
				border-radius: 0;
				color: $colour-brand;
				line-height: 2.5rem;
				background: transparent;
				padding: 0;
				margin-bottom: 0;
				text-align: left;
			}

			.switch-label {
				text-align: center;
			}

			input {
				text-align: left;
			}

			label {
				text-align: left;

				&.date {
					input {
						margin-bottom: 1rem;
					}
				}
			}
		}

		#site_create_new_addr {
			.postcode-search {
				display: grid;
				grid-gap: 1rem;
				grid-template-columns: 1fr;

				@include minXS {
					grid-gap: 1rem;
					grid-template-columns: 1fr;
				}

				span,
				#site_postcodeSelect {
					@include minXS {
						grid-column: span 2;
					}
				}

				#site_postcodeSearchBtn {
					@include minXS {
						padding: 0 1rem;
					}
				}

				input {
					margin: 0;
				}

			}

			.address-fields {
				@include minS {
					display: grid;
					grid-template-columns: 1fr 1fr;
					grid-gap: 1rem;

					label {
						margin: 0;
					}
				}
			}
		}

		h3 {
			text-align: left;
			margin-top: 3rem;
		}

		.delivery .date {
			@include minM {
				margin-top: 55px;
			}
		}

		.delivery,
		.pickup {
			background: $colour-form-background;
			@include border-radius;
			padding: 1.6rem;

			label.textfield {
				padding: 0;

				textarea {
					text-align: left;
				}
			}

			legend {
				transform: translateY(30px);
				color: #fff;
			}

			.date {
				padding: 0;
				text-align: left;
				margin-bottom: 1rem;
			}

			.time legend {
				transform: unset;
			}
		}

		.date {
			margin-bottom: 3rem;
		}

		.switch {
			.switch-label {
				line-height: 1.6rem;
			}
		}

		.time {
			div {
				background-color: $colour-form-background;
				@include border-radius;
				margin-bottom: 1rem;

				.switch {
					margin: 0;
				}
			}
		}

		button[type="submit"] {
			@include fadeInUp ($duration: .5s, $delay: .5s);
			margin: 1.5rem auto 2rem;
			width: auto;
		}
	}
}
