// Login screen

body.login {
    main {
        @include flex;
        flex-direction:  row;
        justify-content: center;
        align-items:     center;

    }

    #content {
        @include flex-align-self(middle);
        width:     100%;
        max-width: 500px;

        .password-reset {
            padding-top: 2rem;

            a {
                color:          #f6f7f8;
                text-transform: capitalize;
            }
        }
    }
}

body.passwordreset {
    @extend body, .login;
}

// Login form can be found at front/forms/_login.scss
