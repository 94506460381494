body.formstepdelivery {
	section {
		@include center-vertical;
	}

	form {
		@include form-container;
		@include xy-grid;
		@include xy-grid-layout(2, 'section', true, 2.45rem);

		@include maxL {
			@include xy-grid-layout(2, 'section', true, 1rem);
		}

		@media (max-width: 940px) {
			@include xy-grid-layout(1, 'section');
		}

		margin-top: 3rem;
		align-items: flex-start;
		align-content: flex-start;

		section {
			@include fadeInUp ($duration: 1s);

			@include maxM {
				margin-bottom: 2rem;
			}
		}

		div:not(.postcode-container):not(.fstElement) {
			@include border-radius;
			transition-property: padding;
			transition: 0.15s ease-out;

			background: $colour-background-medium;
			padding: 2rem 4rem;

			@media (min-width: 941px) and (max-width: $screen-m) {
				padding-left: 2rem;
				padding-right: 2rem;
			}

			@include maxXS {
				padding: 1rem;
			}
		}

		.button {
			background: $colour-background-medium;
			display: block;
			margin: 1rem 0 0;
			opacity: 0.9;

			&:hover {
				opacity: 1;
			}

			@media (max-width: 940px) {
				display: none;
			}
		}

		p#help_text {
			margin: 4rem 1rem 0;

			@media (max-width: 940px) {
				display: none;
			}
		}

		legend {
			@include form-title;
		}

		label {
			padding: 0;
		}

		label:not([aria-label="Postcode"]) {
			@include focus;
		}

		button[type="submit"] {
			width: 100%;
			height: $input-height;
			padding: 0;
			margin-bottom: 1rem;

			&:hover,
			&:focus {
				background: #fff;
				color: $colour-brand;
			}
		}

		.split-left,
		.split-right {
			margin-bottom: 1rem;
		}

		#publicInfo {
			color: #fff;
			margin: 2rem 0;
			text-align: left;
			display: none;
		}
	}
}

#addrSelect {
	margin: 0;
}