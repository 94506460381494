// Contains everything to do with the header and footer, ie not content

// All header styles

header {
	@include flex;
	flex-direction: column;
}


#header_main {
	background-color: $colour-header-primary;
	text-transform: uppercase;
	padding: 1.8rem 2.1rem;

	display: grid;
	grid-template-columns: 220px auto auto;
	grid-gap: 2rem;

	@include maxS {
		grid-template-columns: 150px auto auto;
	}

	@media (max-width: 550px) {
		grid-template-columns: 100%;
		grid-gap: 1rem;
		text-align: center;
		padding: 1rem;
	}

	#brand {
		@include flex-align-self(middle);

		@include maxXS {
			text-align: center;
		}

		a {
			display: block;
			transform: translateY(8px);
			max-width: 220px;
			margin: auto;

			@media (max-width: 550px) {
				max-width: 130px;
			}
		}
	}

	p#slogan {
		color: #fff;
		font-weight: $font-weight-black;
		line-height: 1.3;
		margin-bottom: 0;
		align-self: center;

		@include maxS {
			font-size: 0.84rem;
			line-height: 1.2
		}

		@media (max-width: 550px) {
			font-size: 2.9vw;
			line-height: 1.2;
			letter-spacing: 0.4px;
		}
	}

	nav {
		align-self: center;

		@media (max-width: 550px) {
			position: absolute;
			top: 12px;
			right: 5px;
		}

		ul {
			@include menu-base;
			@include flex-align(right);

			display: grid;
			grid-auto-flow: column;
			grid-template-columns: auto;
			grid-gap: 0.5rem;

			@include maxXS {
				@include flex-align(center);
			}

			li {
				&.header-button-primary {
					@include maxXS {
						display: none;
					}
				}

				&.header-button-quote {
					@include maxS {
						display: none;
					}
				}

				&.header-button-login {
					@include maxXS {
						display: none;
					}
				}

				&.hamburger {
					display: none;

					@include maxXS {
						display: block;
					}
				}

				a {
					@include buttonStyle;
					color: $colour-brand;
					background: #fff;

					&:hover,
					&:focus {
						filter: brightness(0.9);
						background: #fff;
					}
				}
			}

			.button-highlight {
				@include buttonStyle;
				background: $colour-brand-highlight;

				&:hover,
				&:focus {
					background: $colour-brand-highlight !important;
				}

				@include maxXS {
					display: none;
				}
			}

			#hamburger_button {
				padding: 0.7rem 0.6rem;

				span {
					@include hamburger;
				}

				@include maxXS {
					background: transparent;

					span:after,
					&:hover span:after {
						background: #fff;
						box-shadow: 0 7px 0 #fff, 0 14px 0 #fff;
					}
				}
			}
		}
	}

    #login_details {
        position: absolute;
        top: 0.3rem;
        right: 2.1rem;
        color: #fff;
        font-size: 0.6rem;
        text-transform: initial;
    }
}

body.portal {
	#header_main {
		#hamburger_button {
			display: none;
		}
	}
}

body.portal.admin {
	#header_main nav ul li a {
		@media (min-width: $screen-s) and (max-width: 1030px) {
			@include text-overflow-hidden;
			max-width: 115px;
		}
	}
}

#header_bottom {
	display: grid;
	grid-template-columns: repeat(2, auto);

	background-color: $colour-header-secondary;
	font-size: 1rem;
	padding: 0.45rem 2.1rem;

	@include maxM {
		font-size: 0.8rem;
	}

	@include maxXS {
		display: none;
	}

	p {
		color: $colour-dark-text;
		margin: 0;
		flex-grow: 1;

		@include maxXS {
			display: none;
		}

		a,
		span {
			font-weight: $font-weight-black;
		}

		a {
			color: inherit;
			text-decoration: none;
		}

		span {
			color: #fff;
			text-transform: uppercase;
		}
	}
}

#review_summary {
	text-align: right;

	img {
		transform: translateY(-1px);

		@include maxM {
			transform: translateY(-2px);
		}
	}

	@include maxS {
		display: none;
	}
}

// Off canvas menu

.off-canvas {
	background-color: $colour-brand-highlight;

	nav {
		ul {
			list-style: none;
			margin: 0;

			li {
				a {
					@include transition;

					color: #fff;
					display: block;
					font-weight: $font-weight-black;
					outline: none;
					text-align: center;
					text-transform: uppercase;
					padding: 0.8rem 1rem;

					&:hover {
						background: rgba(255, 255, 255, 0.1);
					}
				}
			}
		}
	}
}

.js-off-canvas-overlay {
	background: rgba(0, 0, 0, 0.65);
}

// All footer styles

footer {
	background-color: $colour-header-primary;
	bottom: 0;
	font-size: 0.8rem;
	height: $footer-height;
	left: 0;
	padding: 1.8rem;
	right: 0;
	text-align: center;
	z-index: -1;

	p,
	a {
		@include border-radius;
		@include transition;

		color: #fff;
		margin: 0 0.5rem;
	}

	a {

		&:hover,
		&:focus {
			color: #fff;
			background: rgba(250, 250, 250, 0.15);
		}
	}

	ul {
		@include menu-base;
		@include flex-align(center);

		li:not(:first-child) {
			border-left: 1px solid rgba(250, 250, 250, 0.5);
		}
	}
}

#copyright,
#credit {
	font-size: 0.6rem;
	margin: 0 0 0.2rem;

	a {
		margin: 0;
	}
}

#copyright {
	margin-top: 1rem;
}

.powered-by {
	display: none;
}

.container {
	width: 100%;
}

body.domesticcustomer {
	#header_main,
	footer nav {
		display: none;
	}

	footer {
		height: auto;

		#copyright {
			margin-top: 0;
		}
	}

	#header_bottom {
		display: grid;

		p {
			display: block;
		}
	}
}

body.documentsign {
	#header_main,
	footer nav {
		display: none;
	}

	footer {
		height: auto;

		#copyright {
			margin-top: 0;
		}
	}

	#header_bottom {
		display: grid;

		p {
			display: block;
		}
	}
}
