body.formstepproductselect.domesticcustomer {
	section {
		@include center-vertical;
	}

	form {
		@include form-container;
		@include xy-grid;
		@include xy-grid-layout(4, '.product-sm', true, 2.45rem);
		@include xy-grid-layout(1, '#help_text');
		@include xy-grid-layout(1, 'button');
		@include fadeIn ($duration: 1s);


		margin-top: 3rem;

		@include maxL {
			@include xy-grid-layout(3, '.product-sm', true, 1rem);
		}

		@include maxS {
			@include xy-grid-layout(2, '.product-sm');
		}

		@include maxXS {
			@include xy-grid-layout(1, '.product-sm');
		}

		.product-sm {
			margin-bottom: 1.25rem;
			overflow: hidden;
			cursor: pointer;

			@include fadeIn ($duration: 1s, $fill: none);


			img {
				height: 150px;
			}

			.product-image {
				position: relative;
				background: #fff;
				padding: 0 0.7rem;
				@include border-radius;
				margin: 0 auto 1rem;
				overflow: hidden;

				&:after {
					@include transition;
					background-image: url(../img/icon-tick.svg);
					background-repeat: no-repeat;
					background-position: center 4px;
					content: '';
					display: block;
					position: absolute;
					right: 0;
					top: 0;
					bottom: 0;
					left: 0;
					opacity: 0;
					transform: scale(1.5);

				}
			}

			@include maxXS {
				padding: 1.5rem;
			}

			&.on {
				.product-image {
					img {
						opacity: 0.5;
						filter: blur(12px);
					}

					&:after {
						opacity: 1;
						transform: scale(1.1);
					}
				}
			}
		}

		button {
			max-width: 32rem;
			margin: 0 auto;
		}

	}

	.container {
		margin-top: 2rem;
	}
}

body.formstepproductselect.commercialcustomer {
	h2 {
		@include minS {
			margin-top: 3rem;
		}
	}

	h3,
	h4 {
		text-align: left;
	}

	h4 {
		font-weight: $font-weight-black;
		line-height: 1.2;

		span {
			color: $colour-brand-highlight;
		}
	}

	.popular-products {
		h4 {
			margin-bottom: 1rem;
		}
	}

	.grid-half {
		display: grid;
		grid-gap: 2.5rem;
		grid-template-columns: repeat(2, 1fr);
		margin-bottom: 2rem;

		@include max(1200px) {
			column-gap: 1.5rem;
		}

		@include max(470px) {
			grid-template-columns: repeat(1, 1fr);
		}

		@include min(1300px) {
			.product-sm {
				padding: 1rem;

				.container {
					display: flex;

					.product-size {
						padding: 0 0.5rem;
					}

					.product-select {
						margin: auto 1rem;
					}

					.product-image {
						margin: 0;
					}
				}
			}
		}
	}

	.grid {
		display: grid;
		grid-gap: 2.5rem;
		grid-template-columns: repeat(5, 1fr);
		margin-bottom: 2rem;

		@include max(1400px) {
			grid-template-columns: repeat(4, 1fr);
		}

		@include max(1200px) {
			grid-template-columns: repeat(3, 1fr);
			column-gap: 1.5rem;
		}

		@include max(800px) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include max(470px) {
			grid-template-columns: repeat(1, 1fr);
		}

		h4 {
			grid-column: span 5;

			@include max(1400px) {
				grid-column: span 4;
			}

			@include max(1200px) {
				grid-column: span 3;
			}

			@include max(800px) {
				grid-column: span 2;
			}

			@include max(470px) {
				grid-column: span 1;
			}

			span {
				color: $colour-brand-highlight;
			}
		}

		&.popular {
			grid-gap: 1rem;
			display: block;
		}

		&.previous {
			grid-column: span 3;
			margin-bottom: 4rem;

			@include max(800px) {
				grid-column: span 2;
			}

			@include max(470px) {
				grid-column: span 1;
			}

			h4 {
				margin-bottom: -1.5rem;
				margin-top: 2rem;
			}
		}
	}

	.all .grid {
		margin-bottom: 4rem;
	}

	.product-size {
		font-size: 1rem;
		white-space: nowrap;
		overflow: hidden;
		max-width: 100%;
		text-overflow: ellipsis;
	}

	.product-sm {
		margin-bottom: 0;
		overflow: hidden;
		cursor: pointer;

		@include fadeIn ($duration: 1s, $fill: none);


		img {
			height: 150px;
		}

		.product-image {
			position: relative;
			background: #fff;
			padding: 0 0.7rem;
			@include border-radius;
			margin: 0 auto 1rem;
			overflow: hidden;
			display: block;


			&:after {
				@include transition;
				background-image: url(../img/icon-tick.svg);
				background-repeat: no-repeat;
				background-position: center 4px;
				content: '';
				display: block;
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				left: 0;
				opacity: 0;
				transform: scale(1.5);

			}
		}

		@include maxXS {
			padding: 1.5rem;
		}

		&.on {
			.product-image {
				img {
					opacity: 0.5;
					filter: blur(12px);
				}

				&:after {
					opacity: 1;
					transform: scale(1.1);
				}
			}
		}
	}

	button {
		max-width: 32rem;
		margin: 0 auto 2rem;
	}
}