.product-sm {
	@include border-radius;
	@include transition;
	-webkit-backface-visibility: hidden;
	background: $colour-background-medium;
	box-shadow: 0 1.1em 1.5em -1em rgba(0, 0, 0, 0);
	color: #fff;
	display: flex;
	filter: blur(0);
	flex-flow: column;
	overflow: hidden;
	padding: 2rem 1rem;
	position: relative;
	text-align: center;

	&:hover {
		box-shadow: 0 1.1em 1.5em -1em rgba(0, 0, 0, 0.5);
	}

	span {
		margin: auto;
		width: 100%;
	}

	.product-size {
		display: block;
		font-size: 1.8rem;
		font-weight: $font-weight-black;
		text-transform: none;

		@include maxM {
			font-size: 2rem;
		}
	}

	.product-amount {
		display: block;
		font-size: 1.65rem;
		font-weight: $font-weight-thin;
		line-height: 2rem;
		margin: 0.5rem 0 1.5rem;
		text-transform: capitalize;

		@include maxM {
			font-size: 1.3rem;
			line-height: 1.7rem;
		}
	}

	.product-description {
		font-size: 0.75rem;
		font-weight: $font-weight-medium;
		line-height: 1.2rem;
		text-transform: none;
		display: block;
	}

	.product-price {
		display: block;
		margin: 0.3rem 0 0.4rem;
	}

	.accordion {
		background: none;
		margin: 0;

		.accordion-title {
			border: none;
			max-width: 180px;
			background: rgba(0,0,0,0.15);
			@include border-radius;
			text-decoration: none;
			padding: 16px;

			&:hover,
			&:focus {
				background: rgba(0,0,0,0.25);
			}
		}

		.accordion-content {
			background: none;
			border: none;
			color: #fff;
			padding-bottom: 0;
		}
	}

	.product-select {
		@include border-radius;
		@include transition;

		background: #fff;
		color: $colour-brand;
		display: block;
		font-weight: $font-weight-black;
		padding: 0.2rem 1rem;
		text-transform: uppercase;
		box-shadow: 0 1.1em 1.5em -1em rgba(0, 0, 0, 0);
		font-size: 0.98rem;
		letter-spacing: 0.03rem;
		line-height: 2.3rem;
		margin: 0 auto;
		max-width: 10rem;
		cursor: pointer;
	}

	a,
	a:hover,
	a:focus {
		color: #fff;
		display: block;
		font-weight: $font-weight-black;
		margin-top: 1.5rem;
		margin: 1rem auto 0;
		max-width: 10rem;
		text-decoration: underline;

		@include maxXS {
			font-size: $input-font-small;
		}
	}

	input {
		position: absolute;
		bottom: 0;
		opacity: 0;
	}
}

.product-sm.on {
	background: lighten($colour-brand, 8%);
	transform: translateY(-10px);
	box-shadow: 0 1.1em 1.5em -1em rgba(0, 0, 0, 0.5);

	.product-select {
		&:after {
			opacity: 1;
		}
	}
}