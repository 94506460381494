#splash {
	background-color: $colour-brand;
	bottom: 0;
	display: flex;
	left: 0;
	position: fixed;
	right: 0;
	top: 0; 
	z-index: 99999;
	overflow: hidden;
	svg {
		height: auto;
		margin: auto;
		width: 30%;
		overflow: visible;
		@media (max-width: 1300px) {
			width: 40%;
		}
		@media (max-width: 1000px) {
			width: 60%;
		}
		@include maxXS {
			width: 83%;
		}
		polygon#w,
		g#waste,
		g#sure,
		g#slogan {
			opacity: 0;
		}
	}
}
