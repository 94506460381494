.tabs {
	clear: both;

	&.dashboard {
		padding-top: 3rem;
	}

	ul {
		margin: 0;
		display: grid;
		grid-auto-flow: column;
		grid-template-columns: auto;
		grid-gap: 0.5rem;

		@include maxS {
			grid-auto-flow: unset;
			grid-template-columns: repeat(4, 1fr);
		}

		@include maxXS {
			grid-auto-flow: unset;
			grid-template-columns: repeat(2, 1fr);
		}

		@include tinyPhoneOnly {
			grid-auto-flow: unset;
			grid-template-columns: repeat(1, 1fr);
		}

		li {
			list-style: none;
			display: flex;

			@include minL {
				a {
					box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0);
				}

				&:hover {
					a {
						transform: translateY(-4px);
						box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
					}
				}
			}

			.button {
				display: flex;
				span {
					margin: auto;
				}
			}

			&.red {

				a,
				a:hover,
				a:focus {
					background: $colour-red;
				}
			}

			&.is-active {

				a,
				a:hover,
				a:focus {
					background: $colour-brand;
				}
			}

			&.disabled {
				pointer-events: none;

				a {
					background: $colour-disabled;
				}
			}

			a {
				@include buttonStyle;
				margin: 0;
				display: flex;
				flex: 1;
				background: $colour-brand-highlight;
				text-align: center;

				&:hover,
				&:focus {
					filter: brightness(1.06);
				}

				span {
					margin: auto;
				}
			}
		}
	}

	&#ordersOverviewTabs {
		margin-top: 2rem;

		@include maxM {
			margin-top: 1rem;
		}
	}
}

#customerViewTabs {
	margin-top: 2.5rem;
}

.tabs-panel {
	display: none;
	margin: 2.5rem 0;

	@include minXL {
		margin: 3rem 0;
	}

	&.is-active {
		display: block;
	}

	.orders-grid {
		margin-bottom: 3rem;
	}
}

#portal_content {
	.tabs-panel {
		h2 {

			@include maxL {
				font-size: 1.5rem !important;
			}

			font-weight: $font-weight-black !important;
			margin-bottom: 0.5rem !important;

			@include minL {
				font-size: 2rem !important;
				line-height: 2.5rem !important;
			}

			color: $colour-brand-highlight;

			&.green {
				color: $colour-brand;
			}
		}

		.documents {
			margin-bottom: 3rem;
		}
	}

	#tabCustomerDetail,
	#tabProviderDetail,
	#tabDetails {
		address {
			margin-top: 1.5rem;
			margin-bottom: 1.5rem;
		}

		table {
			margin-bottom: 2rem;
			margin-top: 1.5rem;

			tr:first-child {
				margin-top: 0;
			}

			&:before,
			tbody:before {
				display: none;
			}
		}
	}

	#tabCustomerDetail {
		#sites-dashboard {
			.tiles {
				@include minXL {
					grid-template-columns: repeat(3, auto);
				}
			}
		}
	}

	#tabOrders,
	#tabSites,
	#tabAccounts,
	#tabZones,
	#tabProducts,
	#tabDelivery,
	#tabPickup,
	#tabHistory,
	#tabItemisedOrder {

		table {
			thead {
				@include maxM {
					display: none;
				}
			}

			tr:first-child {
				margin-top: 0;
			}

			&:before,
			tbody:before {
				display: none;
			}
		}
	}

	#tabDocuments {
		h3 {
			margin: 0;
		}
	}
}
