.pagination {
	margin-top: 1rem;
	li,
	a {
		border-radius: 50px;
		font-weight: $font-weight-black;
		color: $colour-brand;
		height: 28px;
		line-height: 20px;
		@include transition;
	}
	li.current {
		padding: 0;
		background: $colour-brand-highlight;
		a {
			color: $colour-brand;
			&:hover, &:focus {
				background: $colour-brand-highlight;
			}
		}
	}
	li.arrow{
		&.unavailable{
			a{
				cursor: default;

				&:hover{
					background-color: #fff;
				}
			}
		}
	}
}
