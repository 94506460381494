// Global (shared) UI components

/*************************************************************************** 
	RANGE SLIDER
*/

.slider,
.slider-fill {
	background: #fff;
	height: 2px;
}

.slider {
	display: block;

	&:before,
	&:after {
		background-color: #fff;
		content: '';
		display: block;
		height: 6px;
		left: 0;
		position: absolute;
		top: -2px;
		width: 6px;
		border-radius: 100%;
	}

	&:after {
		left: inherit;
		right: 0;
	}
}

span.slider-handle {
	background: #fff;
	border-radius: 100%;
	border: 2px solid $colour-background-medium;
	outline: none;
	height: 20px;
	width: 20px;
	transition: 0.3s all ease-out;

	&:hover,
	&:active {
		background-color: #fff;
		width: 25px;
		height: 25px;
		margin-left: -2.5px;
	}
}

/*************************************************************************** 
	RADIO SWITCH
*/

.switch {
	@include border-radius;

	position: relative;
	margin: 0 auto 1rem;
	background: $colour-brand;
	overflow: hidden;

	.switch-label {
		background: transparent;
		cursor: pointer;
		float: left;
		font-weight: $font-weight-light;
		height: $input-height;
		line-height: 2.6rem;
		margin: 0;
		position: relative;
		text-align: center;
		transition-property: color;
		transition: 0.15s ease-out;
		width: 50%;
		z-index: 2;

		&:hover {
			transform: scale(1);
		}

		@include maxL {
			font-size: $input-font-small;
		}

		@include maxXS {
			line-height: 2.6rem;

			span {
				display: none
			}
		}
	}

	[type='radio']+label {
		margin: 0;
	}

	.switch-input {
		opacity: 0;
		position: absolute;
	}

	.switch-input:checked+.switch-label {
		color: $colour-brand;
		transition-property: color;
		transition: 0.15s ease-out;
	}

	.switch-input:checked+.switch-label-on~.switch-selection {
		left: 50%;
	}

	span.switch-selection {
		@include border-radius;

		background-color: #fff;
		display: block;
		height: 100%;
		left: 0;
		padding: 0;
		position: absolute;
		top: 0;
		transition: left 0.15s ease-out;
		width: 50%;
		z-index: 1;
	}
}

span.switch-hint {
	font-size: 0.7rem;
	margin: 2rem 0 1rem;
	color: #fff;
	position: relative;
	display: none;

	i {
		position: absolute;
		top: -25px;
		left: 50%;
		margin-left: -12px;
	}

	@include maxXS {
		display: block;
	}
}

/*************************************************************************** 
	Loading icon
*/

.loading {
	text-align: center;
	img {
		margin-bottom: 5px;
    	width: 30px;
	}
}

/*************************************************************************** 
	Make the entire date field trigger the datepicker on Chrome
*/

input[type="date"] {
    position: relative;
}

/* create a new arrow, because we are going to mess up the native one
see "List of symbols" below if you want another, you could also try to add a font-awesome icon.. */
input[type="date"]:after {
    content: "\f073";
	font-family: 'Font Awesome 5 Free';
    color: $colour-brand;
    padding: 0 5px;
	position: absolute;
	top: 8px;
	right: 5px;

	@include maxXS {
		top: 2px;
	}
}

/* change color of symbol on hover */
input[type="date"]:hover:after {
    color: $colour-brand-highlight;
}

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}

input[type="date"]::-webkit-clear-button {
    z-index: 1;
}

input[type="date"]::-webkit-inner-spin-button {
	display: none;
}

// Chrome autofill
input:-webkit-autofill,
input:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0 30px #fff inset;
	-webkit-text-fill-color: $colour-text-medium;
}

// Input placeholders
input::-webkit-input-placeholder {
	color: rgba($colour-text-medium, 0.5);
}

input::-moz-placeholder {
	color: rgba($colour-text-medium, 0.5);
}

input::-ms-placeholder {
	color: rgba($colour-text-medium, 0.5);
}

input::placeholder {
	color: rgba($colour-text-medium, 0.5);
}

textarea::-webkit-input-placeholder {
	color: rgba($colour-text-medium, 0.5);
}

textarea::-moz-placeholder {
	color: rgba($colour-text-medium, 0.5);
}

textarea::-ms-placeholder {
	color: rgba($colour-text-medium, 0.5);
}

textarea::placeholder {
	color: rgba($colour-text-medium, 0.5);
}
