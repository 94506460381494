// Breadcrumbs

.steps {
	border-top: 1px solid $colour-background-light;
	margin: 5rem -2rem 3rem;

	@include maxXS {
		margin: 1rem -1rem 2rem;
	}

	#wrap {
		@include xy-grid;
		@include xy-grid-layout(4, '*');

		max-width: 1020px;
		margin: 0 auto;
		padding: 0 1rem;

		* {
			color: #e0e0e0;
			font-weight: $font-weight-bold;
			height: 45px;
			position: relative;
			text-align: center;

			@include maxM {
				font-size: 0.8rem;
			}

			@include maxXS {
				font-size: 0.7rem;
				font-weight: $font-weight-medium;
				line-height: 1;
				width: calc(25%);
				margin: 0;
				padding: 0 0.3rem;
			}

			&:before {
				background: $colour-background-light;
				content: '';
				display: block;
				width: 11px;
				border-radius: 10px;
				height: 11px;
				margin: -6px auto 15px;
			}

			&:after {
				background: url(../img/icon-tick-ring.svg);
				content: '';
				display: block;
				height: 36px;
				left: 50%;
				margin: 0 0 0 -18px;
				opacity: 0.5;
				position: absolute;
				top: -62px;
				width: 36px;

				@include maxXS {
					display: none;
				}
			}
		}

		.active {
			color: $colour-brand;

			&:before {
				background: $colour-background-medium;
			}

			&:after {
				opacity: 1;
			}
		}
	}
}
