body.documentsign {

    .steps {
        @include fadeIn($duration: 2s);
    }

    #step_1 {
        @include fadeInUp($duration: .5s);
        width: calc(100% / 6 - 1.25rem);

        @include maxXS() {
            width:     calc(100% / 6);
            font-size: 0.5em;
        }
    }

    #step_2 {
        @include fadeInUp($duration: .5s, $delay: .1s);
        width: calc(100% / 6 - 1.25rem);

        @include maxXS() {
            width:     calc(100% / 6);
            font-size: 0.5em;
        }
    }

    #step_3 {
        @include fadeInUp($duration: .5s, $delay: .2s);
        width: calc(100% / 6 - 1.25rem);

        @include maxXS() {
            width:     calc(100% / 6);
            font-size: 0.5em;
        }
    }

    #step_4 {
        @include fadeInUp($duration: .5s, $delay: .3s);
        width: calc(100% / 6 - 1.25rem);

        @include maxXS() {
            width:     calc(100% / 6);
            font-size: 0.5em;
        }
    }

    #step_5 {
        @include fadeInUp($duration: .5s, $delay: .4s);
        width: calc(100% / 6 - 1.25rem);

        @include maxXS() {
            width:     calc(100% / 6);
            font-size: 0.5em;
        }
    }

    #step_6 {
        @include fadeInUp($duration: .5s, $delay: .5s);
        width: calc(100% / 6 - 1.25rem);

        @include maxXS() {
            width:     calc(100% / 6);
            font-size: 0.5em;
        }
    }

    section {
        @include center-vertical;
        @include fadeIn($duration: 2s);

        h2 {
            @include fadeInUp($duration: 1s);
        }
    }

    form {
        @include xy-grid;
        @include xy-grid-layout(1, '*');
        @include xy-grid-layout(1, 'p#help_text, button');

        margin:     0 auto;
        max-width:  1030px;
        text-align: center;

        @include maxM {
            padding-left:  0;
            padding-right: 0;
        }

        @include maxXS {
            @include xy-grid-layout(1, '*');
        }

        div {
            @include fadeInUp($duration: .5s);
        }

        p#help_text {
            @include fadeInUp($duration: .5s, $delay: .4s);
        }

        label {
            @include focus;

            background: $colour-form-background;
            padding:    0.5rem 1.6rem 0.6rem 0.5rem;

            @include maxM {
                padding: 0.2rem 1.6rem 0.9rem;
            }

            @include maxXS {
                font-size: $input-font-small;
                padding:   0.5rem 1rem 1.2rem;
            }

            &.date {
                padding:     1.7rem 1.6rem;
                line-height: 1.2;

                @include maxXS {
                    padding: 1.3rem 1rem 1.2rem;
                }

                input {
                    margin: 1rem 0 0;
                }
            }

            input {
                display:   block;
                float:     right;
                margin:    0 0 0 0.5rem;
                max-width: 200px;

                @include maxM {
                    float:     none;
                    margin:    0;
                    max-width: 100%;
                }

                &#date {
                    float:     none;
                    max-width: unset;
                }
            }

            button[type="button"] {
                background: #ffffff;
                color:      $colour-text-medium;
                float:      right;
                height:     $input-height;
                margin:     0;
                padding:    0;
                width:      135px;

                @include maxM {
                    float: none;
                    width: 100%;
                }

                @include maxXS {
                    font-size: $input-font-small;
                }
            }
        }

        fieldset {
            @include date-fields;
        }

        input[type="number"] {
            padding: 0 0.5rem 0 1.2rem;
        }

        button[type="submit"] {
            @include fadeInUp($duration: .5s, $delay: .5s);
            margin: 1.5rem auto 0;
            width:  auto;
        }
    }

    /*
     * Adapted from code originally found at: https://css-tricks.com/drag-and-drop-file-uploading/
     */

    .box__dragndrop,
    .box__uploading,
    .box__success,
    .box__error {
        display:    none;
        text-align: center;
    }

    .box__button {
        display: none !important;
    }

    .no-js .box__button {
        display: block;
    }

    .box__file {
        display: none;
    }


    .box {
        color:      $colour-text-dark;
        margin-top: 2em;

        label {
            color:      inherit;
            text-align: center;
            position:   relative;
            cursor:     pointer;

            &:before {
                content:      '\f574';
                font-family:  'Font Awesome 5 Free';
                font-weight:  900;
                margin-right: .5rem;
                font-size:    40px;
                opacity:      0.3;
                position:     absolute;
                left:         50%;
                top:          -65px;
                margin-left:  -20px;
                @include transition;
            }

            &:hover,
            &:focus {
                &:before {
                    opacity: .5;
                    color:   $colour-form-background;
                }
            }
        }

        &.has-advanced-upload {
            background-color:    $colour-background-light;
            padding:             8rem 5rem 5rem 5rem;
            outline:             2px dashed $colour-form-background;
            outline-offset:      -10px;
            transition-property: background-color, outline-offset;
            transition-duration: 0.5s;

            .box__dragndrop {
                display: inline;
            }

            .box__file {
                display: none;
            }
        }

        &.is-dragover {
            background-color: white;
            outline-offset:   -20px;
        }

        &.is-uploading {
            .box__input {
                visibility: none;
            }

            .box__uploading {
                display: block;
            }
        }

        &.is-success {
            .box__success {
                display: block;
            }
        }

        &.is-error {
            .box__error {
                display: block;
            }
        }
    }

    #waste_photo_form {
        &.is-mobile {
            label {
                &::before {
                    content: '\f030';
                }
            }

            .box__dragndrop {
                display: none;
            }
        }
    }

}

body.index.documentsign {
    h2 {
        @include minS {
            margin-top: 3rem;
        }
    }

    form {
        @include xy-grid;
        @include xy-grid-layout(2, '.section');
        @include xy-grid-layout(1, 'button, .site');

        #site_create {
            @include fadeIn($duration: .5s, $delay: 0);

            .checkbox {
                cursor: pointer;

                @include minS {
                    padding-right: 68px;
                    line-height:   1.1;
                    display:       flex;
                    align-items:   center;
                    text-align:    left;
                }

                &:hover {
                    background-color: $colour-brand;
                }

                @include minS {
                    input {
                        position: absolute;
                    }

                    span:before {
                        top:        50%;
                        margin-top: -12px;
                    }

                    span:after {
                        top:        50%;
                        margin-top: -12px;
                    }
                }
            }

            .postcode-fields {
                @include minS {
                    display:               grid;
                    grid-template-columns: 70% 1fr;
                    grid-gap:              1rem;
                }
            }
        }

        margin:     0 auto;
        max-width:  1030px;
        text-align: center;

        @include maxM {
            padding-left:  0;
            padding-right: 0;
            @include xy-grid-layout(1, '.section');
        }

        .section {
            p {
                font-size:  0.75rem;
                text-align: left;
                color:      #ffffff;
            }

            legend {
                border-radius: 0;
                color:         $colour-brand;
                line-height:   2.5rem;
                background:    transparent;
                padding:       0;
                margin-bottom: 0;
                text-align:    left;
            }

            .switch-label {
                text-align: center;
            }

            input {
                text-align: left;
            }

            label {
                text-align: left;

                &.date {
                    input {
                        margin-bottom: 1rem;
                    }
                }
            }
        }

        #site_create_new_addr {
            .postcode-search {
                display:               grid;
                grid-gap:              1rem;
                grid-template-columns: 1fr;

                @include minXS {
                    grid-gap:              1rem;
                    grid-template-columns: 1fr;
                }

                span,
                #site_postcodeSelect {
                    @include minXS {
                        grid-column: span 2;
                    }
                }

                #site_postcodeSearchBtn {
                    @include minXS {
                        padding: 0 1rem;
                    }
                }

                input {
                    margin: 0;
                }

            }

            .address-fields {
                @include minS {
                    display:               grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap:              1rem;

                    label {
                        margin: 0;
                    }
                }
            }
        }

        h3 {
            text-align: left;
            margin-top: 3rem;
        }

        .delivery .date {
            @include minM {
                margin-top: 55px;
            }
        }

        .delivery,
        .pickup {
            background: $colour-form-background;
            @include border-radius;
            padding:    1.6rem;

            label.textfield {
                padding: 0;

                textarea {
                    text-align: left;
                }
            }

            legend {
                transform: translateY(30px);
                color:     #ffffff;
            }

            .date {
                padding:       0;
                text-align:    left;
                margin-bottom: 1rem;
            }

            .time legend {
                transform: unset;
            }
        }

        .date {
            margin-bottom: 3rem;
        }

        .switch {
            .switch-label {
                line-height: 1.6rem;
            }
        }

        .time {
            div {
                background-color: $colour-form-background;
                @include border-radius;
                margin-bottom:    1rem;

                .switch {
                    margin: 0;
                }
            }
        }

        button[type="submit"] {
            @include fadeInUp($duration: .5s, $delay: .5s);
            margin: 1.5rem auto 2rem;
            width:  auto;
        }
    }
}

#signature-canvas {
    background-color: $colour-background;
    width:            100%;
    min-height:       300px;
}
