/*************************************************************************** 
	GLOBAL FORM STYLES
*/

form {
	@include border-radius;

	box-shadow: 0 0 0 rgba(0, 0, 0, 0);
	padding: 2rem;
	text-align: center;

	@include maxM {
		padding: 2rem 0;
	}
}

.help {
	margin: 4rem auto 5.5rem auto;
	max-width: 470px;

	p {
		color: $colour-brand;
	}

	strong {
		font-weight: $font-weight-black;
		text-transform: uppercase;
	}
}

p#help_text {
	text-align: center;
	margin: 1.6rem 0 0;
	font-size: 0.75rem;
}

input[type],
textarea {
	border-radius: 0.2rem;
	border: 1px solid #fff;
	box-shadow: none;
	color: $colour-text-medium;
	font-weight: $font-weight-black;
	height: $input-height;
	text-align: center;
	text-transform: uppercase;
	transition: none;

	@include maxXS {
		height: $input-height-small;
		font-size: $input-font-small;
	}
}

input[type]:focus {
	outline: none;
	box-shadow: none;
	border-color: transparent;
}

input[type="checkbox"] {
	margin: 0;
	float: right;
	opacity: 0;

	+span {

		&:before,
		&:after {
			background: #fff;
			content: '';
			display: block;
			height: 1.4rem;
			width: 1.4rem;
			opacity: 1;
			position: absolute;
			top: 18px;
			right: 1.4rem;
			border-radius: 1rem;
			-webkit-backface-visibility: hidden;
			-webkit-transform: translateZ(0) scale(1.0, 1.0);
			transition: 0.2s all ease-in-out;
		}

		&:before {
			transform: scale(1);
		}

		&:after {
			background: url(../img/icon-tick.svg);
			opacity: 0;
			transform: translateY(30px);
		}
	}
}

input[type="checkbox"]:checked {
	+span {

		&:before {
			transform: scale(1.2);
		}

		&:after {
			transform: translateY(0);
			opacity: 1;
		}
	}
}

input[type="date"] {
	margin: 0;

	@include maxXS {
		padding: 0 1rem;
		line-height: 1.5rem;
	}
}

input[type="time"] {
	margin: 0;
	padding-left: 2rem;
}

input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

label,
legend {
	@include label-block;

	button[type="button"] {
		border-radius: 0.2rem;
	}
}

label {
	position: relative;
}

label.on {
	background: $colour-brand-highlight;
}

select {
	@include border-radius;
	border-color: #fff;

	&:focus,
	&:hover {
		outline: none;
		box-shadow: none;
		border-color: transparent;
	}
}

textarea {
	resize: vertical;
	min-height: 8rem;
}

.textfield {
	position: relative;

	span {
		position: absolute;
		top: 0;
		z-index: -1;
	}
}

input[readonly] {

	&:hover,
	&:focus {
		transform: none;
	}
}

/*************************************************************************** 
	BUTTONS
*/

button[type] {
	@include button(false, $colour-brand-highlight, lighten($colour-brand-highlight, 8%), #fff);
	@include border-radius;
	@include transition;

	font-weight: $font-weight-black;
	margin: 0;
	padding: 1.35rem 1.7rem;
	text-transform: uppercase;
	font-size: 1rem;
	border: none;

	@include maxXS {
		font-size: $input-font-small;
		padding: 1rem;
		line-height: 1rem;
	}
}

.button {
	@include border-radius;
	@include transition;

	font-weight: $font-weight-black;
	padding: 1.35rem 1.7rem;
	text-transform: uppercase;
	font-size: 1rem;
	line-height: 1.2;

	&:hover {
		i.fa-chevron-left {
			transform: translateX(-5px);
		}
	}

	i {
		@include transition;

		vertical-align: middle;
		font-size: 1.5rem;
		line-height: .8rem;
		padding: 0 .25rem 0 0;
		vertical-align: text-top;
	}

	@include maxXS {
		font-size: $input-font-small;
		padding: 1rem;
		line-height: 1rem;
	}
}

.button.primary {
	background: $colour-background-medium;

	&:hover,
	&:focus {
		background: $colour-brand-highlight;
	}
}

.button.secondary {
	background: $colour-background-dark;

	&:hover,
	&:focus {
		background: $colour-brand-highlight;
	}
}

.button.highlight {
	background: $colour-brand-highlight;

	&:hover,
	&:focus {
		background: $colour-brand;
	}
}

/*************************************************************************** 
	POSTCODE FINDER
*/

.postcode-container {
	display: flex;
	padding: 0;
	background: transparent;

	button,
	label {
		width: calc(50% - 0.5rem);
	}

	label {
		margin-right: 0.5rem;
		margin-bottom: 0;
	}

	button {
		margin-left: 0.5rem;
		height: 42px;
		padding: 0.15rem 0 0;
		background: #006949;
		color: #fff;

		@include maxXS {
			height: 30px;
		}

		&:hover,
		&:focus {
			background: $colour-brand-highlight;
		}
	}
}